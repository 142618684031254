<template>
  <div class="tabs">
    <router-link :to="`/clubs/${$route.params.id}/info`"
      :class="{ active: this.$route.path.endsWith('/info')}">Информация</router-link>
    <router-link :to="`/clubs/${$route.params.id}/tables`"
      :class="{ active: this.$route.path.endsWith('/tables')}">Столы</router-link>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SpecificClubsPage',
}
</script>

<style scoped>

</style>
