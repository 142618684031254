<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <h2 v-if="player_name">{{player_name}}</h2> 
  <div id="content" v-if="fetchedTournaments.length" v-show="!loading" :class="{'mobile': isMobile}">
    <div class="tournaments-container">
      <div class="tournament" v-for="(tournament, index) in fetchedTournaments" :key="tournament.id" :class="{'last': index == fetchedTournaments.length - 1}">
        <div class="tournament-header">
          <div class="tournament-place" :class="{'first': tournament.place == '1', 'second': tournament.place == '2', 'third': tournament.place == '3' || tournament.place == '3-4'}">
            {{ `${tournament.place} место` }}
          </div>
          <div class="tournament-info">
            <label class="tournament-name">
              <router-link :to="`/tournaments/${tournament.id}/chart`">
                {{tournament.name}}
              </router-link>
            </label>
            <div class="tournament-details">
              <label class="game-scores">
                <span>Встречи (выигр/проигр): </span>
                <span class="game-win">{{tournament.games_win }}</span>
                <span>/</span>
                <span class="game-lost">{{tournament.games_lost }}</span>
              </label>
              <label class="points" v-if="tournament.points">
                <span>Очки: </span>
                <span class="points">{{ tournament.points }}</span>
              </label>
              <label class="frame-scores">
                <span v-if="tournament.game_type != 2">Партии (выигр/проигр): </span>
                <span v-else>Шары (забил/проп.): </span>
                <span class="frame-win">{{tournament.frames_win }}</span>
                <span>/</span>
                <span class="frame-lost">{{tournament.frames_lost }}</span>
              </label>
            </div>
          </div>
          <div class="tournament-expander" @click="tournament.expanded = !tournament.expanded">
            <i class="fa-solid fa-circle-chevron-left" v-if="tournament.expanded"></i>
            <i class="fa-solid fa-circle-chevron-down" v-else></i>
          </div>
        </div>
        <div class="tournament-games" v-show="tournament.expanded">
          <div class="tournament-game" v-for="(game, game_index) in tournament.games" :key="game_index" :class="{'last': game_index == tournament.games.length - 1}">
            <label class="stage">{{ game.stage }}</label>
            <div class="game-details">
              <label class="player">{{ formatName(player_name) }}</label>
              <label class="score">{{game.score}}</label>
              <label class="player right">{{ formatName(game.opponent) }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button :disabled="more_loading" @click="getMoreTournaments">
      <i class="fas fa-spinner fa-pulse" v-if="more_loading"></i>
      <span v-else>Еще 20</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PlayerTournaments',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      error: '',
      loading: true,
      player_name: null,
      player_id: null,
      owner: null,
      fetchedTournaments: [],
      more_loading: false
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    isMobile() {
      return screen.width <= 1000;
    }
  },
  methods: {
    getPlayerTournaments() {
      this.loading = true;
      this.error = '';
      this.fetchedTournaments = [];
      axios
      .get('/api/tournament/get/player/tournaments', { params: { player_id: this.$route.params.id } })
      .then(response => {
        this.player_name = response.data.full_name;
        this.player_id = response.data.id;
        this.owner = response.data.owner;
        this.fetchedTournaments = response.data.tournaments;
        this.fetchedTournaments.forEach(tournament => tournament.expanded = false);
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    getMoreTournaments() {
      this.more_loading = true;
      this.error = '';
      axios
      .get('/api/tournament/get/player/tournaments', { params: { player_id: this.$route.params.id, max_id: this.fetchedTournaments[this.fetchedTournaments.length - 1].id} })
      .then(response => {
        response.data.tournaments.forEach(tournament => tournament.expanded = false);
        this.fetchedTournaments = this.fetchedTournaments.concat(response.data.tournaments);
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.more_loading = false)
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    }
  },
  created() {
    this.getPlayerTournaments();
  }
}
</script>

<style scoped>
h2 {
  align-self: flex-start;
  margin: 20px;
}
#content {
  justify-content: stretch;
  align-items: center;
  flex-direction: column;
  overflow-y: unset;
  overflow-x: unset;
}
.tournaments-container {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  overflow: hidden;
}
.tournament {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
}
.tournament.last {
  border-bottom: none;
}
.tournament-header {
  display: flex;
  background: #f7f6f6;
}
.tournament-place {
  display: flex;
  padding: 10px;
  background: #122d12;
  color: white;
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tournament-place.first {
  background: #c38908;
}
.tournament-place.second {
  background: #8d8d8d;
}
.tournament-place.third {
  background: #cb5800;
}
.tournament-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tournament-name {
  text-align: center;
  padding: 5px;
  font-size: 15px;
  font-weight: bold;
  color: #10132f;
}
.tournament-name a {
  color: inherit;
  text-decoration: none;
}
.tournament-name:hover {
  color: #434b91;
}
.tournament-details {
  display: flex;
  justify-content: space-between;
  padding: 6px 10px;
}
.tournament-details label {
  margin: 0px 10px;
  color: rgb(143, 143, 143)
}
.frame-win, .game-win {
  color: #068d06;
}
.frame-lost, .game-lost {
  color: #be0909;
}
.points {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1a1f4e;
}
.tournament-expander {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #068d06;
  padding: 10px;
  font-size: 20px;
}
.tournament-expander:hover {
  color: #5de65d;
}
.tournament-games {
  display: flex;
  flex-direction: column;
}
.tournament-game {
  display: flex;
}
.stage {
  display: flex;
  justify-content: center;
  color: #0a7c00;
  width: 100px;
  padding: 10px;
  align-items: center;
}
.game-details {
  display: flex;
  flex: 1;
}
.player {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.player.right {
  text-align: end;
}
.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: blue;
  flex: 0.3;
  justify-content: center;
}

.mobile .tournament-place, .mobile .stage {
  width: 50px;
}
.mobile .tournament-details label {
  font-size: 10px;
}
.mobile .tournament-name {
  font-size: 12px;
}
.mobile .tournament-games {
  font-size: 10px;
}
</style>