<template>
  <div class="tabs" v-if="!isMobile">
    <router-link :to="`/admin/info`"
      :class="{ active: this.$route.path.endsWith('/info')}">Инфо</router-link>
    <router-link :to="`/admin/accounts`"
      v-if="role == 'administrator'"
      :class="{ active: this.$route.path.endsWith('/accounts')}">Пользователи</router-link>
    <router-link :to="`/admin/tournaments`"
      :class="{ active: this.$route.path.endsWith('/tournaments')}">Мои Турниры</router-link>
    <router-link :to="`/admin/ratings`"
      :class="{ active: this.$route.path.endsWith('/ratings')}">Мои Рейтинги</router-link>
    <router-link :to="`/admin/handicaps`"
      :class="{ active: this.$route.path.endsWith('/handicaps')}">Мои Гандикапы</router-link>
    <router-link :to="`/admin/regulations`"
      :class="{ active: this.$route.path.endsWith('/regulations')}">Мои Регламенты</router-link>
    <router-link :to="`/admin/notifications`"
      :class="{ active: this.$route.path.endsWith('/notifications')}">Уведомления</router-link>
  </div>
  <router-view @notifications-update="(e) => $emit('notifications-update', e)" @notification-update="(e) => $emit('notification-update', e)"></router-view>
</template>

<script>
export default {
  name: 'AdminPage',
  emits: ['notifications-update', 'notification-update'],
  data: function() {
    return {
      role: 'guest',
      loading: false,
    }
  },
  computed: {
    isMobile() {
      return screen.width <= 1000;
    }
  },
  mounted() {
    this.role = localStorage.role;
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.replace({ path: '/login' })
    }
    if (this.$route.path == '/admin') {
      this.$router.replace({ path: '/admin/info' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
