<template>
  <div class='actions-container'>
    <div class='action-tabs' v-show="!loading">
      <div class='action-tabs-header' :class="{'collapsed': !actionPanel}">
        <button class='action' @click="actionPanelToggle('createHandicap')" :class="{'active': (actionPanel == 'createHandicap')}">
          Создать гандикап
        </button>
        <div class="vl" v-show="selectedHandicap"></div>
        <button class='action' @click="actionPanelToggle('deleteHandicap')" v-show="selectedHandicap" :class="{'active': (actionPanel == 'deleteHandicap')}">
          Удалить гандикап
        </button>
      </div>
      <div class='action-panels' v-show="actionPanel">
        <div v-show="actionPanel == 'createHandicap'" class='action-panel'>
          <input type="text" placeholder="Название гандикапа" v-model="createHandicapName" v-on:keyup.enter="createHandicap">
          <input type="number" min="2" max="16" placeholder="Количество групп" v-model="createHandicapGroups" v-on:keyup.enter="createHandicap">
          <button @click="createHandicap" :disabled="creatingHandicap">
            <span v-show="!creatingHandicap">Создать</span>
            <i class="fas fa-spinner fa-pulse" v-show="creatingHandicap"></i>
          </button>
          <span v-if="createHandicapError != ''" class="error">{{createHandicapError}}</span>
        </div>
        <div v-show="actionPanel == 'deleteHandicap' && selectedHandicap" class='action-panel'>
          <input type="text" placeholder="Название гандикапа" v-model="deleteHandicapName" v-on:keyup.enter="deleteHandicap">
          <button @click="deleteHandicap" :disabled="deletingHandicap" class="warning">
            <span v-show="!deletingHandicap">Удалить</span>
            <i class="fas fa-spinner fa-pulse" v-show="deletingHandicap"></i>
          </button>
          <span v-if="deleteHandicapError != ''" class="error">{{deleteHandicapError}}</span>
        </div>
      </div>
    </div>
  </div>
  <div id="content">
    <ul>
      <li v-for="handicap in handicaps" :key="handicap.id" :class="{'selected': (handicap == selectedHandicap)}" @click="selectHandicap(handicap)">
        <span>{{handicap.name}}</span>
        <div>
          <router-link :to="`/handicaps/${handicap.id}/info`">
            <i class="fa-solid fa-up-right-from-square"></i>
          </router-link>
          <a @click="cloneHandicap(handicap)" title="Клонировать">
            <i class="fa-solid fa-copy"></i>
          </a>
        </div>
      </li>
    </ul>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <span class="error" v-if="error!=''">{{error}}</span>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdminHandicapsPage',
  data: function() {
    return {
      username: '',
      role: '',
      handicaps: [],
      error: '',
      loading: false,
      actionPanel: '',
      creatingHandicap: false,
      createHandicapName: '',
      createHandicapGroups: null,
      createHandicapError: '',
      deletingHandicap: false,
      deleteHandicapName: '',
      deleteHandicapError: '',
      selectedHandicap: null,
    }
  },
  methods: {
    getHandicaps() {
      this.loading = true;
      this.error = '';
      this.handicaps = [];
      axios
      .get('/api/handicap/list/mine', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.handicaps = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить гандикапы'
        }
      })
      .finally(() => this.loading = false)
    },
    createHandicap() {
      if (!this.createHandicapName || !this.createHandicapGroups) return
      this.creatingHandicap = true;
      this.createHandicapError = '';
      axios
      .post('/api/handicap/create', {name: this.createHandicapName, num_groups: this.createHandicapGroups}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.getHandicaps();
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.createHandicapError = error.response.data;
        } else {
          this.createHandicapError = 'Не получилось создать гандикап'
        }
      })
      .finally(() => this.creatingHandicap = false)
    },
    deleteHandicap() {
      if (this.deleteHandicapName != this.selectedHandicap.name) {
        this.deleteHandicapError = "Название гандикапа не совпадает";
        return
      }
      this.deletingHandicap = true;
      this.deleteHandicapError = '';
      axios
      .post('/api/handicap/delete', {id: this.selectedHandicap.id}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.getHandicaps();
        this.selectedHandicap = null;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.deleteHandicapError = error.response.data;
        } else {
          this.deleteHandicapError = 'Не получилось удалить гандикап'
        }
      })
      .finally(() => this.deletingHandicap = false)
    },
    actionPanelToggle(panel) {
      if (this.actionPanel == panel) {
        this.actionPanel = null;
      } else {
        this.actionPanel = panel;
      }
    },
    selectHandicap(Handicap) {
      if (this.selectedHandicap == Handicap) {
        this.selectedHandicap = null
      } else {
        this.selectedHandicap = Handicap;
      }
    },
    cloneHandicap(handicap) {
      this.loading = true;
      this.error = '';
      axios
      .post('/api/handicap/clone', {id: handicap.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.handicaps.push(response.data);
        this.$router.push({ path: `/handicaps/${response.data.id}/info` });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось скопировать гандикап'
        }
      })
      .finally(() => this.loading = false)
    },
  },
  mounted() {
    this.role = localStorage.role;
    this.username = localStorage.username;
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.replace({ path: '/login' })
    }
    this.getHandicaps();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
li.selected {
  background-color: rgb(93 113 172);
  color: #ffffff;
}
li a {
  text-decoration: none;
  color: #445142;
  margin: 3px;
}
li a:hover {
  color: #248d24
}
</style>
