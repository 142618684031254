<template>
  <div class='actions' v-show="!create_regulation_dialog">
    <a class='add-button' title="Добавить рейтинг" @click="create_regulation_dialog = true"><i class="fa-solid fa-circle-plus"></i></a>
  </div>
  <div id="content" v-show="!create_regulation_dialog">
    <div id="regulations-list" v-if="regulations" v-show="regulations.length">
      <div class="info-container" v-for="regulation in regulations" :key="regulation.id">
        <div class="info-container-header">
          <span  v-if="!regulation.rename">{{ regulation.name }}</span>
          <input class="regulation-name-input" v-else v-model="regulation.name" @focusout="regulation.rename = false; saveRegulation(regulation)" :id="regulation.id"/>
          <a class="regulation-rename" @click="renameRegulation(regulation)" v-if="!regulation.rename"><i class="fa-solid fa-pencil"></i></a>
          <a title="Удалить" @click="deleteRegulation(regulation)" class="delete-button">
            <i v-if="deleting_regulation" class="fas fa-spinner fa-pulse"></i>
            <i v-else class="fa-solid fa-trash-can"></i>
          </a>
        </div>
        <div class="info-container-content">
          <vue-editor v-if="regulation.edit" v-model="regulation.message" :editorToolbar="customToolbar" v-click-outside="() => {regulation.edit = false; saveRegulation(regulation)}"></vue-editor>
          <div v-else class="regulation-text" @click="regulation.edit = true" v-html="regulation.message"></div>
        </div>
      </div>
    </div>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <span class="error" v-if="error!=''">{{error}}</span>
  </div>
  <div class="modal" v-if="create_regulation_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="create_regulation_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Название регламента` }}</label>
        <input v-model="new_regulation_name"/>
        <div class="modal-buttons">
          <button @click="createRegulation">
            <i class="fas fa-spinner fa-pulse" v-if="creating_regulation"></i>
            <span v-else>Создать</span>
          </button>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from "vue3-editor";

export default {
  name: 'AdminRegulationsPage',
  components: {
    'vue-editor': VueEditor,
  },
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      error: '',
      loading: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" }
        ],
        [{ color: [] }, { background: [] }],
        ["link"]
      ],
      regulations: null,
      create_regulation_dialog: false,
      new_regulation_name: null,
      creating_regulation: false,
      deleting_regulation: false,
    }
  },
  methods: {
    getRegulations() {
      this.loading = true;
      this.error = '';
      this.ratings = [];
      axios
      .get('/api/tournament/regulation/list', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.regulations = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить регламенты'
        }
      })
      .finally(() => this.loading = false)
    },
    renameRegulation(regulation) {
      regulation.rename = true;
      setTimeout(() => document.getElementById(regulation.id).focus(), 100);
    },
    createRegulation() {
      this.creating_regulation = true;
      this.error = '';
      let body = {
        message: "",
        name: this.new_regulation_name,
      }
      axios
      .post('/api/tournament/regulation/add', body, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.regulations.push(response.data)
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать регламент'
        }
      })
      .finally(() => {
        this.creating_regulation = false;
        this.create_regulation_dialog = false;
        this.new_regulation_name = '';
      })
    },
    deleteRegulation(regulation) {
      this.deleting_regulation = true;
      this.error = '';
      let body = {
        id: regulation.id
      }
      axios
      .post('/api/tournament/regulation/delete', body, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.regulations = this.regulations.filter( el => el.id !== regulation.id ); 
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось удалить уведомление'
        }
      })
      .finally(() => {
        this.deleting_regulation = false;
      })
    },
    saveRegulation(regulation) {
      this.error = '';
      let body = {
        id: regulation.id,
        name: regulation.name,
        message: regulation.message
      }
      axios
      .post('/api/tournament/regulation/edit', body, {headers: {'x-access-token': localStorage.token}})
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось удалить уведомление'
        }
      })
    },
  },
  mounted() {
    this.getRegulations();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info-container-header .delete-button {
  margin-left: auto;
  color: #e10808;
}
.info-container-header .delete-button:hover {
  color: #e77a7a;
}
.regulation-rename {
  color: black;
  margin-left: 10px;
}
.regulation-rename:hover {
  color: #0e7c0d
}
.regulation-name-input {
  width: 90%;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 7px;
}
.regulation-text {
  min-height: 100px;
  border-radius: 10px;
  padding: 10px;
}
.regulation-text:hover {
  background-color: rgb(239, 239, 239);
}
button:hover > span {
  background-color: rgb(47 129 48);
  color: #ffffff;
}
</style>
