<template>
  <div v-if="isEditor" id="name-div" v-show="!loading">
    <h3 v-show="!changingName">{{ handicap_name }}</h3>
    <input v-show="changingName" v-model="handicap_name" v-on:keyup.enter="changingName = false" @focusout="changingName=false" id='name-input'>
    <a @click="changeName()"><i class="fa-solid fa-pencil"></i></a>
  </div> 
  <h3 v-else>{{ handicap_name }}</h3>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div id="content" v-show="!loading">
    <div class="actions" v-if="isEditor">
      <a :disabled="saving" @click="saveHandicap" title="Сохранить">
        <i class="fa-solid fa-floppy-disk" v-if="!saving"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
      <a class='add-button' title="Добавить игру" @click="create_relation_dialog = true" v-if="isEditor">
        <i class="fa-solid fa-circle-plus" v-if="!creating_relation"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
      <a :disabled="deleting" @click="deleteHandicap" title="Удалить" class="delete-button">
        <i class="fa-solid fa-trash-can" v-if="!deleting"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
    </div>
    <div class="till-distribution" v-show="handicap_num_groups && !loading" v-for="till in Object.keys(relations)" :key="till">
      <div class="distribution-header">
        <label>{{`Игра до ${till} шаров`}}</label>
        <a @click="change_till_dialog = true; change_relation_till_old = till" class="update-relation" v-if="isEditor">
          <i class="fa-solid fa-pencil" v-if="!updating_till"></i>
          <i class="fas fa-spinner fa-pulse" v-else></i>
        </a>
        <a @click="deleteRelations(till)" class="delete-relation" v-if="isEditor">
          <i class="fa-solid fa-trash-can" v-if="!deleting_relation"></i>
          <i class="fas fa-spinner fa-pulse" v-else></i>
        </a>
      </div>
      <div class="handicap-settings">
        <table class="custom handicap-relations">
          <tr>
            <th></th>
            <th v-for="h in handicap_num_groups" :key="h">{{h}}</th>
          </tr>
          <tr v-for="n in handicap_num_groups" :key="n">
            <th>{{n}}</th>
            <td v-for="m in handicap_num_groups" :key="m" :class="`diff${m-n}`" :style="{ background: gradientColors[Math.abs(m-n)]}">
              <input type="number" v-if="n != m && isEditor" :title="`Сколько шаров ${n} группа забивает ${m} группе`" v-model="relations[till][n][m]"
              @mouseover="relation_hover_n = n; relation_hover_m = m" @mouseout="relation_hover_n = null; relation_hover_m = null"
              :class="{'mouse-over': (n == relation_hover_n && m == relation_hover_m) || (m == relation_hover_n && n == relation_hover_m) || (n-m) == relation_hover_diff}">
              <span v-if="n != m && !isEditor" :class="{'mouse-over': (n == relation_hover_n && m == relation_hover_m) || (m == relation_hover_n && n == relation_hover_m) || (n-m) == relation_hover_diff}"
              @mouseover="relation_hover_n = n; relation_hover_m = m" @mouseout="relation_hover_n = null; relation_hover_m = null">{{relations[till][n][m]}}</span>
              <span v-if="n == m">{{ till }}</span>
            </td>
          </tr>
        </table>
        <table class="custom handicap-differences">
          <tr>
            <th>разница</th>
            <th>сильный</th>
            <th>слабый</th>
          </tr>
          <tr v-for="diff in handicap_num_groups-1" :key="diff">
            <th>{{diff}}</th>
            <td>
              <input type="number" v-model="differences[till][diff].strong"
              @mouseover="relation_hover_diff = diff" @mouseout="relation_hover_diff = null" @change="diffChange(till,diff,true)" v-if="isEditor">
              <span @mouseover="relation_hover_diff = diff" @mouseout="relation_hover_diff = null" v-if="!isEditor">{{differences[till][diff].strong}}</span>
            </td>
            <td>
              <input type="number" v-model="differences[till][diff].weak"
                @mouseover="relation_hover_diff = diff * -1" @mouseout="relation_hover_diff = null" @change="diffChange(till,diff,false)" v-if="isEditor">
              <span @mouseover="relation_hover_diff = diff * -1" @mouseout="relation_hover_diff = null" v-if="!isEditor">{{differences[till][diff].weak}}</span>
            </td>
          </tr>
        </table>
        <div class="group-actions">
          <button :disabled="changing_num_groups" @click="addGroup()" v-if="isEditor">
            <span v-if="!changing_num_groups">{{`Добавить ${handicap_num_groups+1} группу`}}</span>
            <i v-else class="fas fa-spinner fa-pulse"></i>
          </button>
          <button :disabled="changing_num_groups" @click="delete_group_dialog=true" v-if="isEditor && handicap_num_groups > 2">
            <span v-if="!changing_num_groups">{{`Удалить ${handicap_num_groups} группу`}}</span>
            <i v-else class="fas fa-spinner fa-pulse"></i>
          </button>
          <div class="modal" v-show="delete_group_dialog">
            <div class="modal-content">
              <div class="modal-header">
                <a @click="delete_group_dialog=false"><i class="fa-solid fa-xmark"></i></a>
              </div>
              <label>{{`Что делаем с игроками в ${handicap_num_groups} группе?`}}</label>
              <div class="modal-buttons" v-if="!changing_num_groups">
                <button @click="deleteGroup(true)">Удалить из гандикапа</button>
                <button @click="deleteGroup(false)">{{`Перевести в ${handicap_num_groups-1} группу`}}</button>
              </div>
              <i v-else class="fas fa-spinner fa-pulse"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-show="create_relation_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="create_relation_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{`До скольки шаров игра?`}}</label>
        <input type="number" v-model="create_relation_till">
        <div class="modal-buttons">
          <button @click="addRelations()" :disabled="!create_relation_till">Добавить</button>
        </div>
      </div>
    </div>
    <div class="modal" v-show="change_till_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="change_till_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{`До скольки шаров игра?`}}</label>
        <input type="number" v-model="change_relation_till">
        <div class="modal-buttons">
          <button @click="changeTill()" :disabled="!change_relation_till">Сохранить</button>
        </div>
        <span class="error" v-show="change_till_error">{{change_till_error}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HandicapInfo',
  data: function() {
    return {
      handicap_id: null,
      handicap_name: '',
      handicap_num_groups: null,
      relations: null,
      error: '',
      loading: true,
      saving: false,
      deleting: false,
      relation_hover_n: null,
      relation_hover_m: null,
      relation_hover_diff: null,
      username: null,
      owner: null,
      role: null,
      changingName: false,
      changing_num_groups: false,
      delete_group_dialog: false,
      creating_relation: false,
      create_relation_dialog: false,
      create_relation_till: null,
      deleting_relation: false,
      change_till_dialog: false,
      updating_till: false,
      change_relation_till: null,
      change_relation_till_old: null,
      change_till_error: null,
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    differences: {
      get: function() {
        let result = {};
        Object.keys(this.relations).forEach(till => {
          result[till] = {};
          for (let i = 1; i < this.handicap_num_groups; i++) {
            result[till][i] = {};
            result[till][i].strong = this.relations[till][i+1][1];
            result[till][i].weak = this.relations[till][1][i+1];
          }
        })
        return result;
      }
    },
    gradientColors() {
      let result = [];
      for (let i=1; i<this.handicap_num_groups+1; i++) {
        result.push(this.lerp((1/(this.handicap_num_groups))*i));
      }
      return result;
    }
  },
  methods: {
    getHandicap() {
      this.loading = true;
      this.error = '';
      this.handicap_id = null
      this.handicap_name = null;
      this.relations = [];
      this.handicap_num_groups = null;
      this.owner = null;
      axios
      .get('/api/handicap/get', { params: { id: this.$route.params.id} })
      .then(response => {
        this.handicap_id = response.data[0].id;
        this.handicap_name = response.data[0].name;
        this.relations = response.data[0].relations;
        this.handicap_num_groups = response.data[0].num_groups;
        this.owner = response.data[0].owner;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    diffChange(till,diff,strong) {
      for (let i = 1; i < Object.keys(this.relations[till]).length + 1; i++) {
        let group = this.relations[till][i];
        if (strong) {
          if (group[i-diff]) group[i-diff] = this.differences[till][diff].strong;
        } else {
          if (group[i+diff]) group[i+diff] = this.differences[till][diff].weak;
        }
      }
    },
    saveHandicap() {
      this.saving = true;
      this.error = '';
      this.handicap = null;
      axios
      .post('/api/handicap/edit', {id: this.handicap_id, name: this.handicap_name, relations: this.relations}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.handicap_name = response.data[0].name;
        this.relations = response.data[0].relations;
        this.handicap_num_groups = response.data[0].num_groups;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.saving = false)
    },
    deleteHandicap() {
      if (confirm('Вы уверены, что хотите удалить гандикап?')) {
        this.deleting = true;
        this.error = '';
        axios
        .post('/api/handicap/delete', {id: this.$route.params.id}, {headers: {'x-access-token': localStorage.token}})
        .then(response => {
          if (response) {
            this.$router.push({ path: '/handicaps' })
          } else {
            this.error = 'failed to delete handicap';
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          } else {
            this.error = 'Не получилось удалить гандикап'
          }
        })
        .finally(() => this.deleting = false)
      }
    },
    changeName() {
      this.changingName=!this.changingName;
      if (this.changingName) {
        let input = document.getElementById('name-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    addGroup() {
      this.changing_num_groups = true;
      this.error = '';
      axios
      .post('/api/handicap/group/add', {id: this.handicap_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.handicap_name = response.data.name;
        this.relations = response.data.relations;
        this.handicap_num_groups = response.data.num_groups;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.changing_num_groups = false)
    },
    deleteGroup(delete_participants) {
      this.changing_num_groups = true;
      this.error = '';
      axios
      .post('/api/handicap/group/delete', {id: this.handicap_id, delete_participants: delete_participants}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.handicap_name = response.data.name;
        this.relations = response.data.relations;
        this.handicap_num_groups = response.data.num_groups;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => {
        this.changing_num_groups = false
        this.delete_group_dialog = false
      })
    },
    addRelations() {
      this.creating_relation = true;
      this.create_relation_dialog = false;
      this.error = '';
      axios
      .post('/api/handicap/relations/add', {id: this.handicap_id, till: this.create_relation_till}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.relations = response.data.relations;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => {
        this.creating_relation = false;
      })
    },
    deleteRelations(till) {
      this.deleting_relation = true;
      this.error = '';
      axios
      .post('/api/handicap/relations/delete', {id: this.handicap_id, till: till}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.relations = response.data.relations;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => {
        this.deleting_relation = false;
      })
    },
    changeTill() {
      if (this.relations[this.change_relation_till]) {
        this.change_till_error = `Распределение для ${this.change_relation_till} уже существует.`
        return;
      }
      this.updating_till = true;
      this.change_till_error = '';
      this.error = '';
      axios
      .post('/api/handicap/till/edit', {id: this.handicap_id, old_till: this.change_relation_till_old, new_till: this.change_relation_till}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.relations = response.data.relations;
        this.change_till_dialog = false;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.change_till_error = error.response.data;
        }
      })
      .finally(() => {
        this.updating_till = false;
      })
    },
    lerp(value) {
      let colors = [this.colorToFloatArray('6eff00'),this.colorToFloatArray('ff6000')];
      let float_color = [
          colors[0][0] + (colors[1][0] - colors[0][0]) * value,
          colors[0][1] + (colors[1][1] - colors[0][1]) * value,
          colors[0][2] + (colors[1][2] - colors[0][2]) * value
      ];
      let hex_color = `#${this.floatToHex(float_color[0])}${this.floatToHex(float_color[1])}${this.floatToHex(float_color[2])}`;
      return hex_color;
    },
    colorToFloatArray(hex) {
      return [
        parseInt(hex.substring(0,2), 16) / 255,
        parseInt(hex.substring(2,4), 16) / 255,
        parseInt(hex.substring(4,6), 16) / 255
      ];
    },
    floatToHex(float) {
      let hex = Math.round(float*255).toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }
  },
  created() {
    this.getHandicap();
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input#name {
  padding: 3px;
}
.till-distribution {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  margin:10px;
}
.distribution-header {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.distribution-header > label {
  font-size: 16px;
  color: rgb(2, 39, 2);
}
.handicap-settings {
  display: flex;
  flex-wrap: wrap;
}
.handicap-settings button {
  align-self: center;
}
.handicap-settings table {
  width: auto;
  border-collapse: collapse;
  margin: 20px;
}
.handicap-settings th,td {
  border: 1px solid #ddd;
  padding: 0;
  height: 30px;
}
.handicap-relations th,td {
  width: 30px;
}
.handicap-differences th {
  padding-left: 5px;
  padding-right: 5px;
}
.handicap-settings th {
  background-color: rgb(52, 51, 58);
  color: aliceblue;
}
.handicap-relations td {
  padding: 1px;
}
.handicap-settings input {
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  padding: 0;
  background: inherit;
}
.handicap-settings input::-webkit-outer-spin-button,
.handicap-settings input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.handicap-settings input[type=number] {
  -moz-appearance: textfield;
}
.handicap-relations .mouse-over {
  border: 1px solid rgb(90, 90, 255)
}
.handicap-settings span {
  display: inline-flex;
  width: 100%;
  padding: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.handicap-relations td.absdiff0 {
  background: #ddd;
}
.handicap-relations td.absdiff1 {
  background: rgb(205, 255, 201);
}
.handicap-relations td.absdiff2 {
  background: rgb(213, 255, 194);
}
.handicap-relations td.absdiff3 {
  background: rgb(221, 252, 190);
}
.handicap-relations td.absdiff4 {
  background: rgb(212, 255, 167);
}
.handicap-relations td.absdiff5 {
  background: rgb(238, 255, 160);
}
.handicap-relations td.absdiff6 {
  background: rgb(255, 236, 160);
}
.handicap-relations td.absdiff7 {
  background: rgb(255, 217, 160);
}
.handicap-relations td.absdiff8 {
  background: rgb(255, 196, 160);
}
.handicap-relations td.absdiff9 {
  background: rgb(255, 188, 160);
}
.handicap-relations td.absdiff10 {
  background: rgb(255, 160, 160);
}
.group-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.group-actions button {
  width: 100%;
}
.delete-relation {
  color: #e10808;
  margin-left: auto;
}
.update-relation {
  font-size: 12px;
  margin: 2px;
  margin-left: 10px;
}
</style>
