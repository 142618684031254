<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div v-if="club" id="name-div" v-show="!loading">
    <h1 v-show="!changingName">{{club.name}}</h1> 
    <input v-show="changingName" v-model="club.name" v-on:keyup.enter="changingName = false" @focusout="changingName=false" id='name-input'>
    <a @click="changeName()" v-if="isEditor"><i class="fa-solid fa-pencil"></i></a>
  </div>
  <div v-if="isEditor" v-show="club && !loading" class="actions">
    <a title="Сохранить" @click="saveClub()"><i class="fa-solid fa-floppy-disk"></i></a>
    <a title="Удалить" @click="deleteClub()" class="delete-button"><i class="fa-solid fa-trash-can"></i></a>
  </div>
  <div id="content" v-if="club" v-show="!loading">
    <div class="club-info">
      <div class='label-with-edit'>
        <label>Адрес:</label>
        <a @click="changeAddress()" v-if="isEditor"><i class="fa-solid fa-pencil"></i></a>
      </div>
      <span v-show="!changingAddress">{{club.address}}</span>
      <input v-show="changingAddress" v-model="club.address" v-on:keyup.enter="changingAddress = false" @focusout="changingAddress=false" id='address-input'>
      <div class='label-with-edit'>
        <label>Телефон:</label>
        <a @click="changePhone()" v-if="isEditor"><i class="fa-solid fa-pencil"></i></a>
      </div>
      <span v-show="!changingPhone">{{club.phone}}</span>
      <input v-show="changingPhone" v-model="club.phone" v-on:keyup.enter="changingPhone = false" @focusout="changingPhone=false" id='phone-input'>
      <div v-if="club.website || isEditor" class='label-with-edit'>
        <label>Сайт:</label>
        <a @click="changeWebsite()" v-if="isEditor"><i class="fa-solid fa-pencil"></i></a>
      </div>
      <a v-show="!changingWebsite" :href="club.website" target="_blank">{{club.website}}</a>
      <input v-show="changingWebsite" v-model="club.website" v-on:keyup.enter="changingWebsite = false" @focusout="changingWebsite=false" id='website-input'>
      <label v-if="this.role == 'administrator'">Зарегистрировал:</label>
      <span v-if="this.role == 'administrator'">{{this.owner}}</span>
    </div>
    <div class="club-info" v-show="club.pyramid_tables || club.pool_tables || club.snooker_tables">
      <label v-if="club.pyramid_tables">Столов для игры в пирамиду</label>
      <span v-if="club.pyramid_tables">{{`${formatTablesLabel(club.pyramid_tables)}`}}</span>
      <label v-if="club.pool_tables">Столов для игры в пул</label>
      <span v-if="club.pool_tables">{{`${formatTablesLabel(club.pool_tables)}`}}</span>
      <label v-if="club.snooker_tables">Столов для игры в снукер</label>
      <span v-if="club.snooker_tables">{{`${formatTablesLabel(club.snooker_tables)}`}}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ClubInfo',
  data: function() {
    return {
      username: null,
      role: null,
      club: null,
      error: '',
      loading: true,
      owner: null,
      changingName: false,
      changingAddress: false,
      changingPhone: false,
      changingWebsite: false,
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    }
  },
  methods: {
    getClub() {
      this.loading = true;
      this.error = '';
      this.club = null
      this.owner = null;
      axios
      .get('/api/club/get', { params: { id: this.$route.params.id } })
      .then(response => {
        this.club = response.data
        this.owner = response.data.owner
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    saveClub() {
      this.loading = true;
      this.error = '';
      this.handicap = null;
      axios
      .post('/api/club/edit', {id: this.club.id, name: this.club.name, address: this.club.address, phone: this.club.phone, website: this.club.website}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.club = response.data
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    deleteClub() {
      if (confirm('Вы уверены что хотите удалить клуб?')) {
        this.error = '';
        this.loading = true;
        axios
        .post('/api/club/delete', { id: this.$route.params.id }, {headers: {'x-access-token': localStorage.token}})
        .then(response => {
          if (response) {
            this.$router.push({ path: '/clubs' })
          } else {
            this.error = 'failed to delete club';
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          } else {
            this.error = 'failed to delete club';
          }
        })
        .finally(() => {
          this.loading = false; 
        })
      }
    },
    formatTablesLabel(count) {
      let result = count.toString();
      if (count == 1) {
        result += ' стол'
      } else if (count < 5) {
        result += ' стола'
      } else {
        result += ' столов'
      }
      return result
    },
    changeName() {
      this.changingName=!this.changingName;
      if (this.changingName) {
        let input = document.getElementById('name-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    changeAddress() {
      this.changingAddress=!this.changingAddress;
      if (this.changingAddress) {
        let input = document.getElementById('address-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    changePhone() {
      this.changingPhone=!this.changingPhone;
      if (this.changingPhone) {
        let input = document.getElementById('phone-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    changeWebsite() {
      this.changingWebsite=!this.changingWebsite;
      if (this.changingWebsite) {
        let input = document.getElementById('website-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
  },
  created() {
    this.getClub();
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<style scoped>
h1 {
  align-self: flex-start;
  margin: 20px;
}
#content {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
.club-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
}
.club-info > * {
  margin-bottom: 20px;
}
.club-info > label {
  margin-bottom: 5px;
}
.club-info label {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  color: rgb(109, 109, 109);
}
.club-info input {
  width: 350px;
}
</style>
