<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div v-if="player" id="name-div" v-show="!loading">
    <h2 v-show="!changingName">{{player.full_name}}</h2> 
    <input v-show="changingName" v-model="player.full_name" v-on:keyup.enter="changingName = false" @focusout="changingName=false" id='name-input'>
    <a @click="changeName()" v-if="isEditor"><i class="fa-solid fa-pencil"></i></a>
  </div>
  <div v-if="isEditor" v-show="player && !loading" class="actions">
    <a title="Сохранить" @click="savePlayer()"><i class="fa-solid fa-floppy-disk"></i></a>
    <a title="Удалить" @click="deletePlayer()" class="delete-button"><i class="fa-solid fa-trash-can"></i></a>
  </div>
  <div id="content" v-if="player" v-show="!loading">
    <div class="player-info">
      <div class="avatar">
        <div class="avatar-icon">
          <img v-if="player.photo_url" :src="player.photo_url"/>
          <i class="fa-solid fa-user" v-else></i>
        </div>
        <div class="avatar-actions" v-if="isEditor">
          <i class="fas fa-spinner fa-pulse" v-if="uploading_image"></i>
          <a class="upload-button" @click="$refs.file.click()" v-else><i class="fa-solid fa-arrow-up-from-bracket"></i></a>
          <input type="file" id="uploadImage" ref="file" @change="uploadImage" accept="image/png, image/jpeg"/>
          <i class="fas fa-spinner fa-pulse" v-if="deleting_image"></i>
          <a class="delete-button" v-if="player.photo_url && !deleting_image" @click="deleteImage"><i class="fa-solid fa-trash-can"></i></a>
        </div>
      </div>
      <div class="player-details">
        <label>Дата рождения</label>
        <span v-if="!isEditor">{{formatBirthday()}}</span>
        <Datepicker :value="birthDate" v-else class='datepicker' @input="birthDateChange"></Datepicker>
        <label v-show="player.country_id || isEditor">Страна</label>
        <span v-if="!isEditor" v-show="player.country_id">{{player.country.name}}</span>
        <div v-else>
          <div v-if="!changing_country">
            <span>{{player.country.name}}</span>
            <a @click="changeCountry()"><i class="fa-solid fa-pencil"></i></a>
          </div>
          <div v-else>
            <v-select v-show="!countries_loading" v-model="player.country" :options="countries" label="name" @option:selected="countryChanged()" class="country-select" v-click-outside="() => changing_country = false"></v-select>
            <div v-if="isEditor" v-show="countries_loading" class="loader"><i class="fas fa-spinner fa-pulse"></i></div>
          </div>
        </div>
        <label>Регион</label>
        <span v-if="!isEditor">{{player.region ? player.region.name : ""}}</span>
        <v-select v-if="isEditor" v-show="!regions_loading" v-model="player.region" :options="regions" label="name" @option:selected="regionChanged()" class="region-select"></v-select>
        <div v-if="isEditor" class="loader" v-show="regions_loading"><i class="fas fa-spinner fa-pulse"></i></div>
        <label>Город</label>
        <span v-if="!isEditor">{{ player.city != 'null' ? player.city : "" }}</span>
        <input v-else v-model="player.city">
        <label>Разряд</label>
        <span v-if="!isEditor">{{player.rank}}</span>
        <input v-else v-model="player.rank">
      </div>
    </div>
    <div class="handicaps-info" v-if="handicaps.length">
      <div class="handicaps-header">
        Гандикапы
      </div>
      <div class="handicaps-content">
        <table>
          <thead>
            <th>Название</th>
            <th>Группа</th>
            <th>Дата</th>
          </thead>
          <tbody>
            <tr v-for="handicap in handicaps" :key="handicap.name">
              <td>
                <router-link :to="`/handicaps/${handicap.id}/participants`" class="link">
                  {{handicap.name}}
                </router-link>
              </td>
              <td>{{handicap.group}}</td>
              <td>{{dateFormat(handicap.timestamp)}}</td>
            </tr>
          </tbody>
        </table>
      </div>  
    </div>
    <div class="ratings-info" v-if="ratings.length">
      <div class="ratings-header">
        Рейтинги
      </div>
      <div class="ratings-content">
        <table>
          <thead>
            <th>Название</th>
            <th>Место</th>
            <th>Очки</th>
          </thead>
          <tbody>
            <tr v-for="rating in ratings" :key="rating.name">
              <td>
                <router-link :to="`/ratings/${rating.id}/participants`" class="link">
                  {{rating.name}}
                </router-link>
              </td>
              <td>{{rating.place}}</td>
              <td>{{rating.points}}</td>
            </tr>
          </tbody>
        </table>
      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs3-datepicker';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: 'PlayerInfo',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      error: '',
      loading: true,
      player: null,
      owner: null,
      changingName: false,
      birthDate: null,
      regions_loading: false,
      regions: [],
      handicaps: [],
      ratings: [],
      changing_country: false,
      countries: [],
      countries_loading: false,
      uploading_image: false,
      deleting_image: false,
    }
  },
  components: {
    'Datepicker': Datepicker,
    'v-select': vSelect

  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    }
  },
  methods: {
    getPlayer() {
      this.loading = true;
      this.error = '';
      this.club = null
      this.owner = null;
      axios
      .get('/api/player/get', { params: { id: this.$route.params.id } })
      .then(response => {
        this.player = response.data[0];
        this.owner = this.player.owner;
        this.getRegions();
        this.birthDate = null;
        let birth_str = '';
        if (this.player.birth_year) birth_str += this.player.birth_year;
        if (this.player.birth_month) birth_str += `-${this.player.birth_month}`;
        if (this.player.birth_day) birth_str += `-${this.player.birth_day}`;
        if (this.player.country_id) {
          this.player.country = {
            name: this.player.country_name
          }
        } else {
          this.player.country = {};
        }
        if (birth_str) this.birthDate = Date.parse(birth_str);
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    getRegions() {
      this.regions_loading = true;
      this.regions = [];
      axios
      .get('/api/player/get/regions')
      .then(response => {
        this.regions = response.data;
        for (let i=0; i<this.regions.length; i++) {
          if (this.regions[i].id == this.player.region_id) {
            this.player.region = this.regions[i];
            break;
          }
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.regions_loading = false)
    },
    getCountries() {
      this.countries_loading = true;
      this.countries = [];
      axios
      .get('/api/player/get/countries')
      .then(response => {
        this.countries = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.countries_loading = false)
    },
    savePlayer() {
      this.loading = true;
      this.error = '';
      this.handicap = null;
      axios
      .post('/api/player/edit', {player: this.player}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.player = response.data;
        if (this.player.country_id) {
          this.player.country = {
            name: this.player.country_name
          }
        } else {
          this.player.country = {};
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'не получилось сохранить игрока';
        }
      })
      .finally(() => this.loading = false)
    },
    deletePlayer() {
      if (confirm('Вы уверены что хотите удалить игрока?')) {
        this.error = '';
        this.loading = true;
        axios
        .post('/api/player/delete', { id: this.$route.params.id }, {headers: {'x-access-token': localStorage.token}})
        .then(response => {
          if (response) {
            this.$router.push({ path: '/players' })
          } else {
            this.error = 'failed to delete player';
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          } else {
            this.error = 'failed to delete player';
          }
        })
        .finally(() => {
          this.loading = false; 
        })
      }
    },
    changeName() {
      this.changingName=!this.changingName;
      if (this.changingName) {
        let input = document.getElementById('name-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    formatBirthday() {
      let birth_str = "";
      if (this.player.birth_day != null) {
        let birth_day_str = (this.player.birth_day.toString().length == 1) ? '0' + this.player.birth_day : this.player.birth_day;
        birth_str+=birth_day_str + '.'
      }
      if (this.player.birth_month != null) {
        let birth_month_str = (this.player.birth_month.toString().length == 1) ? '0' + this.player.birth_month : this.player.birth_month;
        birth_str+=birth_month_str + '.';
      }
      if (this.player.birth_year != null) birth_str+=this.player.birth_year;
      return birth_str;
    },
    regionChanged() {
      if (this.player.region) {
        this.player.region_id = this.player.region.id;
        this.player.region = this.player.region.name;
      } else {
        this.player.region_id = null;
        this.player.region = null;
      }
    },
    countryChanged() {
      if (this.player.country.id) {
        this.player.country_id = this.player.country.id;
      } else {
        this.player.country_id = null;
      }
    },
    birthDateChange(date) {
      this.player.birth_year = date.getFullYear();
      this.player.birth_month = date.getMonth() + 1;
      this.player.birth_day = date.getDate();
    },
    getHandicaps() {
      this.error = '';
      this.handicaps = [];
      axios
      .get('/api/handicap/get/player/handicaps', { params: { player_id: this.$route.params.id } })
      .then(response => {
        this.handicaps = response.data;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
    },
    getRatings() {
      this.error = '';
      this.ratings = [];
      axios
      .get('/api/rating/get/player/ratings', { params: { player_id: this.$route.params.id } })
      .then(response => {
        this.ratings = response.data;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
    },
    dateFormat(timestamp) {
      let date = new Date(parseInt(timestamp,10));
      let day_str = (date.getDate().toString().length == 1) ? '0' + date.getDate() : date.getDate();
      let month_str = ((date.getMonth() + 1).toString().length == 1) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
      let result = `${day_str}.${month_str}.${date.getFullYear()}`;
      return result
    },
    changeCountry() {
      this.changing_country = true;
      if (!this.countries.length) {
        this.getCountries();
      }
    },
    uploadImage(e) {
      if (!e.srcElement.files.length) {
        console.log('no files to upload')
        return;
      }
      let file = e.srcElement.files[0];
      let extension = file.name.split('.').pop();
      this.uploading_image = true;
      this.error = '';
      axios
      .get('/api/player/photo/upload', {params: { player_id: this.player.id, extension: extension }, headers: {'x-access-token': localStorage.token}})
      .then(response => {
        let signed_url = response.data;
        axios.put(signed_url, file)
        .then(() => {
          axios.post('/api/player/photo/upload', {url: signed_url}, {headers: {'x-access-token': localStorage.token}})
          .then(() => {
            this.player.photo_url = `/photos/${this.player.id}.${extension}?rnd=${Math.floor(Math.random() * 100)}`;
          })
        })
        .catch(err => console.log(err))
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'не получилось загрузить фото';
        }
      })
      .finally(() => {
        this.uploading_image = false;
        e.srcElement.value = '';
      })
    },
    deleteImage() {
      this.deleting_image = true;
      this.error = '';
      axios.post('/api/player/photo/delete', {player_id: this.player.id}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.player.photo_url = null
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'не получилось загрузить фото';
        }
      })
      .finally(() => {
        this.deleting_image = false;
      })
    }
  },
  created() {
    this.getPlayer();
    this.getHandicaps();
    this.getRatings();
  }
}
</script>

<style scoped>
h2 {
  align-self: flex-start;
  margin: 20px;
}
#content {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: visible;
}
.player-info {
  display: flex;
  align-items: flex-start;
  margin: 5px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  flex-wrap: wrap;
  justify-content: center;
}
.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.avatar-actions {
  align-self: stretch;
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  align-items: center;
}
.avatar-actions a {
  margin: 5px;
  font-size: 20px;
}
.avatar-actions input {
  display: none;
}
a.upload-button {
  color: #248d24 !important;
}
a.upload-button:hover {
  color: #84dc84 !important;
}
a.delete-button {
  color: #e10808 !important;
}
a.delete-button:hover {
  color: #e77a7a !important;
}
.avatar-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: rgb(109, 109, 109);
  flex: 1;
}
.avatar-icon img, .avatar-icon i {
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 10px;
}
.player-details {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  margin-left: 20px;
}
.player-details > * {
  margin-bottom: 10px;
}
.player-details label {
  color: rgb(109, 109, 109);
}
.player-details span,
.player-details .datepicker
{
  text-align: end;
  margin-left: 10px;
}
.player-details input {
  width: 192px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  justify-self: end;
}
.player-details a {
  font-size: 15px;
  text-decoration: none;
  color: #445142;
  padding: 3px;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 0;
  background: inherit;
}
.region-select, .country-select {
  font-size: 12px;
  width: 200px;
  margin-left: 10px;
  justify-self: end;
}
.player-details .loader {
  display: flex;
  justify-content: center;
}

.handicaps-info, .ratings-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  overflow: hidden;
}
.handicaps-header, .ratings-header {
  border-bottom: 1px solid #ccc;
  align-self: stretch;
  padding: 10px;
  background: #f2f2f2;
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.handicaps-content, .ratings-content {
  padding: 5px;
}
.link {
  text-decoration: none;
  color: inherit;
}
</style>