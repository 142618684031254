<template>
  <div id="register" :class="{'mobile': isMobile}">
    <div class="notice">
      <i class="fa-solid fa-triangle-exclamation"></i>
      <label>Учетная запись дает возможность создавать и проводить турниры.<br/>Для участников турниров регистрация не открывает никаких новых возможностей.</label>
    </div>
    <input :class="{'invalid': !nameValid}" type="text" placeholder="ФИО" v-model="name">
    <label class="warning" v-show="!nameValid">ФИО может содержать только буквы, пробелы и "-"</label>
    <div>
      <input autocomplete="false" :class="{'invalid': !usernameValid && username_exists}" type="text" placeholder="Имя пользователя" v-model="username" @focusout="checkUsername">
      <i class="fas fa-spinner fa-pulse" v-show="username_checking"></i>
    </div>
    <label class="warning" v-show="!usernameValid">Имя пользователя может содержать только латинские буквы и точку</label>
    <label class="warning" v-show="username_exists">Такой пользователь уже существует</label>
    <label class="warning" v-show="username_check_error">Не удалось проверить имя пользователя</label>
    <input  autocomplete="off" :class="{'invalid': !passwordValid}" type="password" placeholder="Пароль" v-model="password">
    <label class="warning" v-show="!passwordValid">Пароль должен состоять из латинских букв, цифр и любого из следующих символов: !@$%^&amp;:;&lt;&gt;,.?~_+-=&quot;</label>
    <input :class="{'invalid': !passwordRepeatValid}" type="password" placeholder="Повторите пароль" v-model="password_repeat">
    <label class="warning" v-show="!passwordRepeatValid">{{"Пароли не совпадают"}}</label>
    <div>
      <input :class="{'invalid': !phoneValid && phone_exists}" type="text" placeholder="Номер телефона" v-model="phone_number">
      <i class="fas fa-spinner fa-pulse" v-show="phone_checking"></i>
    </div>
    <label class="warning" v-show="!phoneValid">{{"Неверный формат телефона"}}</label>
    <label class="warning" v-show="phone_exists">Пользователь с таким телефоном уже зарегистрирован</label>
    <label class="warning" v-show="phone_check_error">Не удалось проверить номер телефона</label>
    <label class="warning" v-if="phone_number" v-show="phone_number.startsWith('+996') || phone_number.startsWith('996')">fairplay.host - сайт для проведения бильярдных турниров, а не сайт для ставок.</label>
    <label class="warning" v-if="phone_number" v-show="phone_number.startsWith('+996') || phone_number.startsWith('996')">fairplayhost - букмекердик сайт эмес, бильярд турнирлери үчүн сайт.</label>
    <label class="warning" v-show="!nameValid">ФИО может содержать только буквы, пробелы и "-"</label>
    <button :disabled="!formValid" @click="sendCode()">
      <span v-if="!sending_code && !registering">Регистрация</span>
      <i v-else class="fas fa-spinner fa-pulse"></i>       
    </button>
    <span class="error" v-if="error!=''">{{error}}</span>
  </div>
  <div class="modal" v-show="code_verify_step">
    <div id="code-form" class="modal-content">
      <div class="modal-header">
        <a @click="code_verify_step=false"><i class="fa-solid fa-xmark"></i></a>
      </div>
      <label>Вам позвонит неизвестный номер. Последние 4 цифры номера - код подтверждения.</label>
      <div id="code-verify">
        <input type="number" pattern="[0-9]" ref="first" class="code-digit" @input="firstCodeDigitVerify()" v-model="first_code_digit">
        <input type="number" pattern="[0-9]" ref="second" class="code-digit" @input="secondCodeDigitVerify()" v-model="second_code_digit">
        <input type="number" pattern="[0-9]" ref="third" class="code-digit" @input="thirdCodeDigitVerify()" v-model="third_code_digit">
        <input type="number" pattern="[0-9]" ref="fourth" class="code-digit" @input="fourthCodeDigitVerify()" v-model="fourth_code_digit">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'RegisterPage',
  emits: ['login'],
  data: function() {
    return {
      name: '',
      username: null,
      username_exists: null,
      username_checking: false,
      username_check_error: false,
      password: null,
      password_repeat: null,
      phone_number: null,
      phone_checking: null,
      phone_check_error: false,
      phone_exists: null,
      error: null,
      code_verify_step: false,
      first_code_digit: "",
      second_code_digit: "",
      third_code_digit: "",
      fourth_code_digit: "",
      registering: false,
      sending_code: false
    }
  },
  watch: {
    phone_number() {
      if (this.phone_number.match(/\+?996[0-9]{9}/)) {
        localStorage.region = "KG";
        location.reload();
      }
    }
  },
  methods: {
    register() {
      if (!(this.formValid && this.verificationCode)) return;
      this.registering = true;
      this.error = '';
      this.code_verify_step = false;
      axios
      .post('/api/account/register', { name: this.name, username: this.username, password: this.password, phone: this.phone_number, code: this.verificationCode })
      .then(response => {
        localStorage.name = response.data.name;
        localStorage.username = response.data.username;
        localStorage.token = response.data.token;
        localStorage.role = response.data.role;
        this.$emit('login');
        this.$router.push({ path: '/admin/info' });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          this.error = error.response.data;
        }
      })
      .finally(() => {
        this.registering = false
      });
    },
    checkUsername() {
      if (!this.username) return
      this.username_checking = true;
      this.username_exists = false;
      this.username_check_error = false;
      axios
      .post('/api/account/username/check', { username: this.username })
      .then(response => {
        if (response.data) {
          this.username_exists = true;
        } else {
          this.username_exists = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.username_check_error = true;
      })
      .finally(() => this.username_checking = false)
    },
    checkPhone() {
      if (!this.phone_number) return
      this.phone_checking = true;
      this.phone_exists = null;
      this.phone_check_error = false;
      axios
      .post('/api/account/phone/check', { phone: this.phone_number })
      .then(response => {
        if (response.data) {
          this.phone_exists = true;
        } else {
          this.phone_exists = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.phone_check_error = true;
      })
      .finally(() => this.phone_checking = false)
    },
    firstCodeDigitVerify() {
      if (this.first_code_digit.toString().match(/^[0-9]+$/)) this.first_code_digit = this.first_code_digit.toString().substring(this.first_code_digit.length - 1);
      if (!this.first_code_digit.toString().match(/^[0-9]$/)) this.first_code_digit = "";
      else {
        this.$refs.second.focus()
      }
      if (this.verificationCode) this.register();
    },
    secondCodeDigitVerify() {
      if (!this.second_code_digit) this.$refs.first.focus()
      if (this.second_code_digit.toString().match(/^[0-9]+$/)) this.second_code_digit = this.second_code_digit.toString().substring(this.second_code_digit.length - 1);
      if (!this.second_code_digit.toString().match(/^[0-9]$/)) this.second_code_digit = "";
      else {
        this.$refs.third.focus()
      }
      if (this.verificationCode) this.register();
    },
    thirdCodeDigitVerify() {
      if (!this.third_code_digit) this.$refs.second.focus()
      if (this.third_code_digit.toString().match(/^[0-9]+$/)) this.third_code_digit = this.third_code_digit.toString().substring(this.third_code_digit.length - 1);
      if (!this.third_code_digit.toString().match(/^[0-9]$/)) this.third_code_digit = "";
      else {
        this.$refs.fourth.focus()
      }
      if (this.verificationCode) this.register();
    },
    fourthCodeDigitVerify() {
      if (!this.fourth_code_digit) this.$refs.third.focus()
      if (this.fourth_code_digit.toString().match(/^[0-9]+$/)) this.fourth_code_digit = this.fourth_code_digit.toString().substring(this.fourth_code_digit.length - 1);
      if (!this.fourth_code_digit.toString().match(/^[0-9]$/)) this.fourth_code_digit = "";
      if (this.verificationCode) this.register();
    },
    sendCode() {
      this.error = '';
      this.sending_code = true;
      this.first_code_digit = '';
      this.second_code_digit = '';
      this.third_code_digit = '';
      this.fourth_code_digit = '';
      axios
      .post('/api/account/code/send', { phone: this.phone_number })
      .then(response => {
        if (response.data) {
          this.code_verify_step=true;
        } else {
          this.error = 'Failed to send verification code'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          this.error = error.response.data;
        }
      })
      .finally(() => this.sending_code = false)
    }
  },
  computed: {
    isMobile() {
      return screen.width <= 1000;
    },
    nameValid() {
      if (!this.name) return true;
      return this.name.match(/^\p{L}+([ -]\p{L}+)* *$/u);
    },
    usernameValid() {
      if (!this.username) return true;
      return this.username.match(/^[a-zA-Z.]+$/);
    },
    passwordValid() {
      if (!this.password) return true;
      return this.password.match(/^[A-Za-z0-9*.!@$%^&:;<>,.?~_+-=]+$/);
    },
    passwordRepeatValid() {
      if (!this.password_repeat) return true;
      return this.password == this.password_repeat;
    },
    phoneValid() {
      if (!this.phone_number) return true;
      let valid = this.phone_number.match(/^(\+7|8|7)?9[0-9]{9}$/);
      if (valid) this.checkPhone()
      return valid
    },
    formValid() {
      return this.name && this.username && this.password && this.password_repeat && this.phone_number &&
        this.nameValid && this.usernameValid && this.passwordValid && this.passwordRepeatValid && this.phoneValid &&
        !this.username_checking && !this.username_exists && !this.username_check_error &&
        !this.phone_checking && this.phone_exists==false && !this.phone_check_error;
    },
    verificationCode() {
      if (!(this.first_code_digit && this.second_code_digit && this.third_code_digit && this.fourth_code_digit)) return null;
      let code = parseInt(`${this.first_code_digit}${this.second_code_digit}${this.third_code_digit}${this.fourth_code_digit}`, 10);
      if (isNaN(code)) return null;
      return code;
    }
  },
  created() {
    this.checkUsername();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #register {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  #register button {
    padding: 10px;
  }
  input {
    margin: 10px;
    align-content: center;
    text-align: center;
    padding: 5px;
    outline: none;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 300px;
  }
  input.invalid {
    border: 1px solid red;
  }
  button {
    background-color: rgb(47 129 48);
    color: #ffffff;
    border: none;
    margin: 10px;
    padding: 5px;
  }
  button:disabled {
    background-color: #ddd;
    color: black;
  }
  label.warning {
    color:red;
    text-align: center;
  }


  #register.mobile {
    align-self: stretch;
  }
  #code-form label {
    text-align: center;
    font-size: 18px;
    font-family: sans-serif;
    color: #0b0b39;
    padding: 20px;
    line-height: 30px;
  }
  #code-verify {
    display: flex;
    justify-content: stretch;
  }
  .code-digit {
    max-width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 10px;
    font-size: 40px;
    border: 1px solid #2121a5;
  }
  /* Chrome, Safari, Edge, Opera */
  input.code-digit::-webkit-outer-spin-button,
  input.code-digit::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input.code-digit[type=number] {
    -moz-appearance: textfield;
  }
  .notice {
    border: 1px solid #864715;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    margin: 20px;
    display: flex;
    align-items: center;
  }
  .notice > i {
    font-size: 30px;
    color: #eaa100;
    margin-right: 10px;
    margin-left: -10px;
  }
  .notice > label {
    text-align: center;
    font-size: 15px;
    font-family: sans-serif;
    color: #583604;
  }


</style>
