import { createRouter, createWebHistory } from 'vue-router'
import PlayersPage from '../components/players/PlayersPage.vue'
import SpecificPlayersPage from '../components/players/SpecificPlayersPage.vue'
import PlayerInfo from '../components/players/PlayerInfo.vue'
import PlayerTournaments from '../components/players/PlayerTournaments.vue'
import TournamentsPage from '../components/tournaments/TournamentsPage.vue'
import RatingsPage from '../components/ratings/RatingsPage.vue'
import SpecificRatingsPage from '../components/ratings/SpecificRatingsPage.vue'
import RatingParticipants from '../components/ratings/RatingParticipants.vue'
import RatingTournaments from '../components/ratings/RatingTournaments.vue'
import SpecificTournamentsPage from '../components/tournaments/SpecificTournamentsPage.vue'
import TournamentInfo from '../components/tournaments/TournamentInfo.vue'
import TournamentParticipants from '../components/tournaments/TournamentParticipants.vue'
import TournamentChart from '../components/tournaments/TournamentChart.vue'
import TournamentStatement from '../components/tournaments/TournamentStatement.vue'
import TournamentVideos from '../components/tournaments/TournamentVideos.vue'
import GamePage from '../components/tournaments/GamePage.vue'
import PlashkaPage from '../components/tournaments/PlashkaPage.vue'
import PlashkaAllPage from '../components/tournaments/PlashkaAllPage.vue'
import AdminPage from '../components/admin/AdminPage.vue'
import AdminInfoPage from '../components/admin/AdminInfoPage.vue'
import AdminAccountsPage from '../components/admin/AdminAccountsPage.vue'
import AdminHandicapsPage from '../components/admin/AdminHandicapsPage.vue'
import AdminTournamentsPage from '../components/admin/AdminTournamentsPage.vue'
import AdminRatingsPage from '../components/admin/AdminRatingsPage.vue'
import AdminNotificationsPage from '../components/admin/AdminNotificationsPage.vue'
import AdminRegulationsPage from '../components/admin/AdminRegulationsPage.vue'
import LoginPage from '../components/LoginPage.vue'
import RegisterPage from '../components/RegisterPage.vue'
import HandicapsPage from '../components/handicaps/HandicapsPage.vue'
import SpecificHandicapsPage from '../components/handicaps/SpecificHandicapsPage.vue'
import HandicapInfo from '../components/handicaps/HandicapInfo.vue'
import HandicapParticipants from '../components/handicaps/HandicapParticipants.vue'
import ClubsPage from '../components/clubs/ClubsPage.vue'
import SpecificClubsPage from '../components/clubs/SpecificClubsPage.vue'
import ClubInfo from '../components/clubs/ClubInfo.vue'
import ClubTables from '../components/clubs/ClubTables.vue'

const routes = [
  {
    path: '/',
    redirect: '/tournaments'
  },
  {
    path: '/players',
    component: PlayersPage
  },
  {
    path: '/players/:id',
    component: SpecificPlayersPage,
    children: [
      {
        path: 'info',
        component: PlayerInfo,
      },
      {
        path: 'tournaments',
        component: PlayerTournaments,
      },
    ]
  },
  {
    path: '/tournaments',
    component: TournamentsPage
  },
  {
    path: '/ratings',
    component: RatingsPage
  },
  {
    path: '/ratings/:id',
    component: SpecificRatingsPage,
    children: [
      {
        path: 'participants',
        component: RatingParticipants,
      },
      {
        path: 'tournaments',
        component: RatingTournaments,
      }
    ]
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/register',
    component: RegisterPage
  },
  {
    path: '/tournaments/:id',
    component: SpecificTournamentsPage,
    children: [
      {
        path: 'info',
        component: TournamentInfo,
      },
      {
        path: 'participants',
        component: TournamentParticipants,
      },
      {
        path: 'chart',
        component: TournamentChart,
      },
      {
        path: 'statement',
        component: TournamentStatement,
      },
      {
        path: 'videos',
        component: TournamentVideos,
      },
      {
        path: 'plashka',
        component: PlashkaAllPage,
      }
    ],
  },
  {
    path: '/admin',
    component: AdminPage,
    children: [
      {
        path: 'info',
        component: AdminInfoPage
      },
      {
        path: 'accounts',
        component: AdminAccountsPage
      },
      {
        path: 'tournaments',
        component: AdminTournamentsPage
      },
      {
        path: 'ratings',
        component: AdminRatingsPage
      },
      {
        path: 'handicaps',
        component: AdminHandicapsPage
      },
      {
        path: 'notifications',
        component: AdminNotificationsPage
      },
      {
        path: 'regulations',
        component: AdminRegulationsPage
      }
    ]
  },
  {
    path: '/handicaps',
    component: HandicapsPage,
  },
  {
    path: '/handicaps/:id',
    component: SpecificHandicapsPage,
    children: [
      {
        path: 'info',
        component: HandicapInfo
      },
      {
        path: 'participants',
        component: HandicapParticipants
      }
    ]
  },
  {
    path: '/game/:tournament_id/:table_number',
    component: GamePage,
  },
  {
    path: '/plashka/:tournament_id/:table_number',
    component: PlashkaPage,
  },
  {
    path: '/clubs',
    component: ClubsPage,
  },
  {
    path: '/clubs/:id',
    component: SpecificClubsPage,
    children: [
      {
        path: 'info',
        component: ClubInfo
      },
      {
        path: 'tables',
        component: ClubTables
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(() => {
  document.title = 'Fair Play';
});

export default router
