<template>
  <div id="side-bar">
    <router-link to='/players' class="left-menu-item" @mouseover="players_hover = true" @mouseout="players_hover = false"
      :class="{ active: this.$route.path.startsWith('/players') }">
      <div><i class="fa-solid fa-people-group"></i></div>
      <transition name="slide">
        <label class="menu-description" :class="{'visible': players_hover}">Игроки</label>
      </transition>
    </router-link>
    <router-link to='/tournaments' class="left-menu-item" @mouseover="tournaments_hover = true" @mouseout="tournaments_hover = false"
      :class="{ active: this.$route.path.startsWith('/tournaments') }">
      <div><i class="fa-solid fa-trophy"></i></div>
      <transition name="slide">
        <label class="menu-description" :class="{'visible': tournaments_hover}">Турниры</label>
      </transition>
    </router-link>
    <router-link to='/ratings' class="left-menu-item" @mouseover="ratings_hover = true" @mouseout="ratings_hover = false"
      :class="{ active: this.$route.path.startsWith('/ratings') }">
      <div><i class="fa-solid fa-chart-simple"></i></div>
      <transition name="slide">
        <label class="menu-description" :class="{'visible': ratings_hover}">Рейтинги</label>
      </transition>
    </router-link>
    <router-link to='/handicaps' class="left-menu-item" @mouseover="handicaps_hover = true" @mouseout="handicaps_hover = false"
      :class="{ active: this.$route.path.startsWith('/handicaps') }">
      <div><i class="fa-solid fa-handshake-simple"></i></div>
      <transition name="slide">
        <label class="menu-description" :class="{'visible': handicaps_hover}">Гандикапы</label>
      </transition>
    </router-link>
    <router-link to='/clubs' class="left-menu-item" @mouseover="clubs_hover = true" @mouseout="clubs_hover = false"
      :class="{ active: this.$route.path.startsWith('/clubs') }">
      <div><i class="fa-solid fa-building"></i></div>
      <transition name="slide">
        <label class="menu-description" :class="{'visible': clubs_hover}">Клубы</label>
      </transition>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  components: {
  },
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      get user_icon() {
        let result = 'fa-user';
        if (localStorage.role) {
          if (localStorage.role == 'administrator'){
            result = 'fa-user-astronaut';
          } else if (localStorage.role == 'operator') {
            result = 'fa-user-tie';
          }
        }
        return result;
      },
      players_hover: false,
      tournaments_hover: false,
      ratings_hover: false,
      handicaps_hover: false,
      clubs_hover: false,
    }
  },
  methods: {
  },
  computed: {
    isMobile() {
      return screen.width <= 1000;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div#side-bar {
  display: flex;
  flex-direction: column;
  background-color: #021a06;
  color: aliceblue;
  align-self: stretch;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  z-index: 2;
}
div#logo {
  margin: 20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: xx-large;
}
div#logo span.green {
  color: rgb(77, 187, 77);
}
div#menu {
  display: flex;
  flex-direction: column;
}
.left-menu-item {
  width: 60px;
  height: 50px;
  text-decoration: none;
  background-color: inherit;
  color: inherit;
  font-size: large;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.left-menu-item:hover {
  color: rgb(77, 187, 77);
}
.left-menu-item.active {
  background-color: rgb(47 129 48);
  color: #ffffff;
}
.left-menu-item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: inherit;
  color: inherit;
  z-index: 3;
}
.left-menu-item label {
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0px;
  background-color: inherit;
  color: inherit;
  height: 100%;
  z-index: 1;
  transition: right 0.5s;
}
.left-menu-item label.visible {
  right: -100px;
}
</style>
