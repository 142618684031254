<template>
  <div class='actions'>
    <a class='add-button' v-if="isOperator" title="Добавить игрока" @click="createDialogOpen()" v-show="!create_player_dialog"><i class="fa-solid fa-circle-plus"></i></a>
    <div class="search-div">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input type="text" placeholder="Поиск игроков..." v-model="searchString" v-on:keyup.enter="searchPlayer">
      <button class="search-button" :disabled="loading" v-on:click="searchPlayer">
        <span>Поиск</span>
      </button>
    </div>
  </div>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div id="content" v-if="!create_player_dialog" v-show="!loading">
    <table v-show="players.length">
      <thead>
        <tr>
          <th>Имя</th>
          <th>Дата Рождения</th>
          <th>Город</th>
          <th>Разряд</th>
        </tr>
      </thead>  
      <tbody>
        <tr v-for="player in players" :key="player.id" @click="PlayerClicked(player)">
          <td>{{ player.full_name }}</td>
          <td>{{ player.birth }}</td>
          <td>{{ player.city }}</td>
          <td>{{ player.rank }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="create-dialog" v-else v-show="!loading">
    <label>Имя</label>
    <input :disabled="creating_player" type="text" v-model="newPlayer.full_name">
    <label>День рождения</label>
    <Datepicker :disabled="creating_player" @input="newPlayerBirthDayChange" class='new-player-datepicker'></Datepicker>
    <label>Страна</label>
    <v-select v-show="!countries_loading" v-model="newPlayer.country" :options="countries" label="name" @option:selected="countryChanged()" class="country-select" v-click-outside="() => changing_country = false"></v-select>
    <div v-show="countries_loading" class="loader"><i class="fas fa-spinner fa-pulse"></i></div>
    <label>Город</label>
    <input :disabled="creating_player" type="text" v-model="newPlayer.city">
    <label>Разряд</label>
    <input :disabled="creating_player" type="text" v-model="newPlayer.rank">
    <div v-if="!creating_player">
      <button :disabled="!newPlayer.full_name" @click="createPlayer">Добавить</button>
      <button class="warning" @click="create_player_dialog = false">Отменить</button>
    </div>
    <div id="loader" v-else>
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs3-datepicker';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: 'PlayersPage',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      error: null,
      searchString: '',
      fetchedPlayers: [],
      loading: false,
      create_player_dialog: false,
      creating_player: false,
      newPlayer: {
        full_name: null,
        birth_year: null,
        birth_month: null,
        birth_day: null,
        country: null,
        city: null,
        rank: null
      },
      countries_loading: false,
      countries: [],
    }
  },
  components: {
    'Datepicker': Datepicker,
    'v-select': vSelect
  },
  computed: {
    players () { 
      return this.fetchedPlayers.map((player) => {
        let birth_str = "";
        if (player.birth_day != null) {
          let birth_day_str = (player.birth_day.toString().length == 1) ? '0' + player.birth_day : player.birth_day;
          birth_str+=birth_day_str + '.'
        }
        if (player.birth_month != null) {
          let birth_month_str = (player.birth_month.toString().length == 1) ? '0' + player.birth_month : player.birth_month;
          birth_str+=birth_month_str + '.';
        }
        if (player.birth_year != null) birth_str+=player.birth_year;
        player.birth = birth_str;
        return player;
      });
    },
    isOperator() {
      return this.role == 'operator' || this.role == 'administrator'
    }
  },
  methods: {
    searchPlayer() {
      this.loading = true;
      this.fetchedPlayers = [];
      axios
      .get('/api/player/search', { params: { search: this.searchString } })
      .then(response => (this.fetchedPlayers = response.data))
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
      .finally(() => this.loading = false)
    },
    getCountries() {
      this.countries_loading = true;
      this.countries = [];
      axios
      .get('/api/player/get/countries')
      .then(response => {
        this.countries = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.countries_loading = false)
    },
    PlayerClicked(player) {
      this.$router.push({ path: `/players/${player.id}/info` })
    },
    createPlayer() {
      this.error = '';
      this.creating_player = true;
      axios
      .post('/api/player/create', this.newPlayer, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.$router.push({ path: `/players/${response.data[0].id}/info` })
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.creating_player = false)
    },
    newPlayerBirthDayChange(date) {
      this.newPlayer.birth_year = date.getFullYear();
      this.newPlayer.birth_month = date.getMonth() + 1;
      this.newPlayer.birth_day = date.getDate();
    },
    createDialogOpen() {
      this.create_player_dialog = true;
      if (!this.countries.length) {
        this.getCountries();
      }
    },
    countryChanged() {
      if (this.newPlayer.country.id) {
        this.newPlayer.country_id = this.newPlayer.country.id;
      } else {
        this.newPlayer.country_id = null;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .search-div {
    margin-left: auto;
  }
  .new-player-datepicker {
    align-self: flex-start;
  }
  .v-select {
    align-self: stretch;
  }
</style>
