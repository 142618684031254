<template>
  <div id="content">
    <table class="custom">
      <tr>
        <td class='key'>Имя пользователя:</td>
        <td class='value'>{{ name }}</td>
      </tr>
      <tr>
        <td class='key'>Username:</td>
        <td class='value'>{{ username }}</td>
      </tr>
      <tr>
        <td class='key'>Роль:</td>
        <td class='value'>{{ role }}</td>
      </tr>
    </table>
    <div class='actions'>
      <button class='action' @click="exitAccount">
        Выйти
      </button>
      <button class='action' @click="resetPasswordToggle">
        Сменить пароль
      </button>
    </div>
    <div id='changePassword' v-show="changePassword">
      <input type="password" placeholder="Старый пароль" v-model="oldPassword" v-on:keyup.enter="resetPassword">
      <input type="password" placeholder="Новый пароль" v-model="newPassword" v-on:keyup.enter="resetPassword">
      <input type="password" placeholder="Новый пароль" v-model="newPasswordRepeat" v-on:keyup.enter="resetPassword">
      <button @click="resetPassword" :disabled="changingPassword">
        <span v-show="!changingPassword">Сменить</span>
        <i class="fas fa-spinner fa-pulse" v-show="changingPassword"></i>
      </button>
      <span class="error" v-if="error!=''">{{error}}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdminInfoPage',
  data: function() {
    return {
      get name() {
        let result =  'guest';
        if (localStorage.name) result = localStorage.name;
        return result;
      },
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      changePassword: false,
      error: '',
      changingPassword: false
    }
  },
  methods: {
    exitAccount: function() {
      localStorage.removeItem('username');
      localStorage.removeItem('name');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      this.$router.replace({ path: '/login' })
    },
    resetPasswordToggle: function() {
      this.changePassword = !this.changePassword;
    },
    resetPassword: function() {
      if (this.oldPassword == '' || this.newPassword == '' || this.newPasswordRepeat == '') return
      if (this.newPassword != this.newPasswordRepeat) {
        this.error = 'Новые пароли не совпадают';
        return
      }
      this.changingPassword = true;
      this.error = '';
      axios
      .post('/api/account/reset', { username: this.username, old_password: this.oldPassword, new_password: this.newPassword }, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.resetPasswordToggle();
      })
      .catch(error => {
        this.changingPassword = false;
        console.log(error.response);
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось поменять пароль'
        }
      })
      .finally(() => this.changingPassword = false)
    }
  },
  mounted() {
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  table td {
    border: none;
    padding: 10px;
  }
  td.key {
    text-align: right;
  }
  td.value {
    text-align: left;
  }
  #changePassword {
    display: flex;
    flex-direction: column;
  }
  #changePassword input {
    padding: 2px;
    margin: 10px;
  }
  .actions {
    justify-content: center;
  }
</style>
