<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div class='actions' v-if="isOperator" v-show="!loading">
    <a class='add-button' title="Добавить гандикап" @click="create_handicap_dialog = true" v-show="!create_handicap_dialog"><i class="fa-solid fa-circle-plus"></i></a>
  </div>
  <div id="content" v-if="!create_handicap_dialog" v-show="!loading">
    <div class="info-container" v-show="handicaps.length">
      <div class="info-container-header">
        <span>Гандикапы</span> 
      </div>
      <div class="info-container-content">
        <router-link v-for="(handicap, index) in handicaps" :key="handicap.id" :to="`/handicaps/${handicap.id}/participants`" :class="{'last': index == handicaps.length - 1}">
          <div class="handicap-info">
            <div class="name">
              {{handicap.name}}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="create-dialog" v-else v-show="!loading">
    <label>Название</label>
    <input :disabled="creating_handicap" v-model="new_handicap_name">
    <label>Количество групп</label>
    <input type="number" min="2" v-model="new_handicap_groups">
    <div v-if="!creating_handicap">
      <button @click="createHandicap()">Добавить</button>
      <button class="warning" @click="create_handicap_dialog = false">Отменить</button>
    </div>
    <div id="loader" v-else>
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HandicapsPage',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      handicaps: [],
      error: '',
      loading: false,
      create_handicap_dialog: false,
      creating_handicap: false,
      new_handicap_name: null,
      new_handicap_groups: null,
    }
  },
  computed: {
    isOperator() {
      return this.role == 'operator' || this.role == 'administrator'
    }
  },
  methods: {
    listHandicaps() {
      this.loading = true;
      this.error = '';
      this.handicaps = [];
      axios
      .get('/api/handicap/list')
      .then(response => (this.handicaps = response.data))
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
      .finally(() => this.loading = false)
    },
    createHandicap() {
      if (!this.new_handicap_name || !this.new_handicap_groups) return
      this.creating_handicap = true;
      this.error = '';
      axios
      .post('/api/handicap/create', {name: this.new_handicap_name, num_groups: this.new_handicap_groups}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.$router.push({ path: `/handicaps/${response.data[0].id}/info` })
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать гандикап'
        }
      })
      .finally(() => this.creating_handicap = false)
    },
  },
  created() {
    this.listHandicaps();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#content {
  align-self: center;
}
.info-container-content .name {
  margin: 10px;
}
</style>
