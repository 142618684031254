<template>
  <div class="tabs">
    <router-link :to="`/handicaps/${$route.params.id}/info`"
      :class="{ active: this.$route.path.endsWith('/info')}">Информация</router-link>
    <router-link :to="`/handicaps/${$route.params.id}/participants`"
      :class="{ active: this.$route.path.endsWith('/participants')}">Участники</router-link>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SpecificHandicapsPage',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
