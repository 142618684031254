<template>
  <div id="content">
    <div class='actions' v-if="isOperator && !loading">
      <a class='add-button' title="Добавить клуб" @click="create_club_dialog = true" v-show="!create_club_dialog"><i class="fa-solid fa-circle-plus"></i></a>
    </div>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <span class="error" v-show="error">{{error}}</span>
    <div class="clubs-list" v-show="clubs.length" v-if="!create_club_dialog">
      <router-link v-for="(club, index) in clubs" :key="club.id" :to="`/clubs/${club.id}/info`">
        <div class="club-info" :class="{'last': index == clubs.length - 1}">
          <div class="club-name">
            {{club.name}}
          </div>
          <div class="club-contact">
            <label>{{club.address}}</label>
            <label>{{club.phone}}</label>
          </div>
          <div class="club-tables">
            <label v-if="club.pyramid_tables">{{`Пирамида: ${formatTablesLabel(club.pyramid_tables)}`}}</label>
            <label v-if="club.pool_tables">{{`Пул: ${formatTablesLabel(club.pool_tables)}`}}</label>
            <label v-if="club.snooker_tables">{{`Снукер: ${formatTablesLabel(club.snooker_tables)}`}}</label>
          </div>
        </div>
      </router-link>
    </div>
    <div class="create-dialog" v-else v-show="!loading">
      <label>Название</label>
      <input :disabled="creating_club" v-model="new_club.name">
      <label>Адрес</label>
      <input :disabled="creating_club" v-model="new_club.address">
      <label>Телефон</label>
      <input :disabled="creating_club" v-model="new_club.phone">
      <label>Сайт</label>
      <input :disabled="creating_club" v-model="new_club.website">
      <label>Столы для пирамиды</label>
      <input :disabled="creating_club" v-model="new_club.pyramid_tables" type="number">
      <label>Столы для пула</label>
      <input :disabled="creating_club" v-model="new_club.pool_tables" type="number">
      <label>Столы для снукера</label>
      <input :disabled="creating_club" v-model="new_club.snooker_tables" type="number">
      <span class="error" v-show="new_club.error">{{new_club.error}}</span> 
      <div v-if="!creating_club">
        <button @click="addClub()">Добавить</button>
        <button class="warning" @click="create_club_dialog = false">Отменить</button>
      </div>
      <div id="loader" v-else>
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ClubsPage',
  data: function() {
    return {
      clubs: [],
      error: '',
      loading: false,
      create_club_dialog: false,
      new_club: {
        name: null,
        address: null,
        phone: null,
        website: null,
        pyramid_tables: 0,
        pool_tables: 0,
        snooker_tables: 0
      },
      creating_club: false
    }
  },
  computed: {
    isOperator() {
      return this.role == 'operator' || this.role == 'administrator'
    }
  },
  methods: {
    listClubs() {
      this.loading = true;
      this.error = '';
      this.handicaps = [];
      axios
      .get('/api/club/list')
      .then(response => (this.clubs = response.data))
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    formatTablesLabel(count) {
      let result = count.toString();
      if (count == 1) {
        result += ' стол'
      } else if (count < 5) {
        result += ' стола'
      } else {
        result += ' столов'
      }
      return result
    },
    addClub() {
      this.new_club.error = null;
      if (!this.new_club.name) {
        this.new_club.error = 'Не указано название!';
        return
      }
      if (!this.new_club.address) {
        this.new_club.error = 'Не указан адрес!';
        return
      }
      if (!this.new_club.phone) {
        this.new_club.error = 'Не указан телефон!';
        return
      }
      if (!(this.new_club.pyramid_tables || this.new_club.pool_tables || this.new_club.snooker_tables)) {
        this.new_club.error = 'Клуб не может быть без столов!';
        return
      }
      if (!this.new_club.website) {
        this.new_club.website = null;
      }
      this.creating_club = true;
      axios
      .post('/api/club/create', {club: this.new_club}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        let club = response.data;
        this.clubs.push(club);
        this.create_club_dialog = false;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.new_club.error = error.response.data;
        }
      })
      .finally(() => this.creating_club = false)
    }
  },
  created() {
    this.listClubs();
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<style scoped>
.clubs-list {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  font-size: 13px;
  margin: 10px;
  max-width: 540px;
}
.clubs-list a {
  text-decoration: none;
}
.club-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin: 5px;
}
.club-info.last {
  border-bottom: none;
}
.club-name {
  font-weight: bold;
  margin: 5px;
  color: rgb(10, 26, 10);
}
.club-contact {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  color: rgb(109, 109, 109);
  margin: 5px;
}
.club-tables {
  display: flex;
  justify-content: space-between;
  margin: 5px;
  align-self: stretch;
  color: rgb(0, 0, 137);
}
</style>
