<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span v-if="error" class="error">{{error}}</span>
  <h1>{{ name }}</h1> 
  <div id="content" v-show="name != ''">
    <div class="info-container" v-if="!loading && tournaments.length">
      <div class="info-container-header">
        <span>Турниры</span> 
      </div>
      <div class="info-container-content">
        <router-link v-for="(tournament, index) in tournaments" :key="tournament.id" :to="`/tournaments/${tournament.id}/info`" :class="{'last': index == tournaments.length - 1}">
          <div class='tournament-row'>
            <div class='tournament-name'>
              {{tournament.name}}
            </div>
            <div class='tournament-details'>
              <label class="game-type">{{game_types[tournament.game_type][0].name}}</label>
              <label class="date">{{formatFullDateTime(tournament.start_time)}}</label>
            </div>
          </div>
          <div class="winners">
            <div class="winner" v-for="winner in tournament.winners" :key="winner.place">
              <i class="fa-solid fa-medal" :class="'place-' + winner.place"></i>
              <label>{{formatName(winner.name)}}</label>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'RatingTournaments',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      id: null,
      name: '',
      owner: null,
      tournaments: [],
      loading: false,
      error: '',
      game_types: null,
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    isMobile() {
      return screen.width <= 1000;
    }
  },
  methods: {
    loadRating() {
      this.error = '';
      this.loading = true;
      this.name = '';
      this.tournaments = [];
      axios
      .get('/api/rating/tournaments', { params: { id: this.$route.params.id } })
      .then(response => {
        this.id = response.data.id;
        this.name = response.data.name;
        this.owner = response.data.owner;
        this.tournaments = response.data.tournaments;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'failed to rebuild rating';
        }
      })
      .finally(() => this.loading = false)
    },
    loadGameTypes() {
      this.game_types = null;
      axios
      .get('/api/tournament/get/gametypes')
      .then(response => {
        this.game_types = this.groupArrayBy(response.data, 'id');
      })
      .catch(error => {
        console.log(error);
      })
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    formatFullDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month}.${year} ${hour}:${min}`;
      return result
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    }
  },
  mounted() {
    this.loadRating();
    this.loadGameTypes();
  }
}
</script>

<style scoped>
  #content {
    align-self: auto;
  }
  #name-div {
    display: flex;
    align-items: center;
  }
  h1 {
    font-size: 15px;
  }
  #name-div a {
    font-size: 15px;
    text-decoration: none;
    color: #445142;
    padding: 3px;
    margin: 5px;
    margin-top: 0;
    margin-bottom: 0;
    background: inherit;
    border-radius: 5px;
  }
  #name-div a:hover {
    color: #248d24;
  }
  #name-div i {
    margin: 5px;
  }
  .info-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    flex-direction: column;
    margin: 5px;
    overflow: visible;
    align-self: stretch;
  }
  .info-container-header {
    border-bottom: 1px solid #ccc;
    align-self: stretch;
    padding: 10px;
    background: #f2f2f2;
    color: #0e7c0d;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  .info-container-content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 10px;
  }
  .info-container-content > a {
    text-decoration: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
  }
  .info-container-content > a:hover {
    background: rgb(226, 226, 226);
  }
  .info-container-content > a.last {
    border: none;
  }
  .info-container-content .tournament-name {
    color: rgb(5, 10, 6);
    font-size: 16px;
    margin: 3px;
    font-weight: bold;
    text-align: center;
  }
  .info-container-content .tournament-details {
    display: flex;
    justify-content: space-between;
    color: rgb(103, 103, 103);
    font-size: 14px;
  }
  .winners {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    flex-wrap: wrap;
  }
  .winner {
    margin: 0 10px;
  }
  .winner label {
    color: #0c1b6a;
  }
  .place-1 {
    color: #c38908;
  }
  .place-2 {
    color: #8d8d8d;
  }
  .place-3, .place-3-4 {
    color: #cb5800;
  }
  .place-4 {
    color: #122d12;
  }
</style>
