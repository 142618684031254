<template>
  <div class='actions-container'>
    <div class='action-tabs' v-show="!loading">
      <div class='action-tabs-header' :class="{'collapsed': !actionPanel}">
        <button class='action' @click="actionPanelToggle('createRating')" :class="{'active': (actionPanel == 'createRating')}">
          Создать рейтинг
        </button>
        <div class="vl" v-show="selectedRating"></div>
        <button class='action' @click="actionPanelToggle('deleteRating')" v-show="selectedRating" :class="{'active': (actionPanel == 'deleteRating')}">
          Удалить рейтинг
        </button>
      </div>
      <div class='action-panels' v-show="actionPanel">
        <div v-show="actionPanel == 'createRating'" class='action-panel'>
          <input type="text" placeholder="Название рейтинга" v-model="createRatingName" v-on:keyup.enter="createRating">
          <button @click="createRating" :disabled="creatingRating">
            <span v-show="!creatingRating">Создать</span>
            <i class="fas fa-spinner fa-pulse" v-show="creatingRating"></i>
          </button>
          <span v-if="createRatingError != ''" class="error">{{createRatingError}}</span>
        </div>
        <div v-show="actionPanel == 'deleteRating' && selectedRating" class='action-panel'>
          <input type="text" placeholder="Название рейтинга" v-model="deleteRatingName" v-on:keyup.enter="deleteRating">
          <button @click="deleteRating" :disabled="deletingRating" class="warning">
            <span v-show="!deletingRating">Удалить</span>
            <i class="fas fa-spinner fa-pulse" v-show="deletingRating"></i>
          </button>
          <span v-if="deleteRatingError != ''" class="error">{{deleteRatingError}}</span>
        </div>
      </div>
    </div>
  </div>
  <div id="content">
    <ul>
      <li v-for="rating in ratings" :key="rating.id" :class="{'selected': (rating == selectedRating)}" @click="selectRating(rating)">
        <span>{{rating.name}}</span>
        <router-link :to="`/ratings/${rating.id}/participants`">
          <i class="fa-solid fa-up-right-from-square"></i>
        </router-link>
      </li>
    </ul>
    <button v-show="ratings.length" class="action more-button" @click="getNextRatings" :disabled="loading" v-if="ratings.length > 19">
      <span v-show="!loading">Ещё 20</span>
      <i class="fas fa-spinner fa-pulse" v-show="loading"></i>
    </button>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <span class="error" v-if="error!=''">{{error}}</span>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdminRatingsPage',
  data: function() {
    return {
      username: '',
      role: '',
      ratings: [],
      error: '',
      loading: false,
      actionPanel: null,
      createRatingName: null,
      creatingRating: false,
      createRatingError: '',
      selectedRating: null,
      deleteRatingName: null,
      deletingRating: false,
      deleteRatingError: ''
    }
  },
  methods: {
    getRatings() {
      this.loading = true;
      this.error = '';
      this.ratings = [];
      axios
      .get('/api/rating/list/mine', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.ratings = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить рейтинги'
        }
      })
      .finally(() => this.loading = false)
    },
    getNextRatings() {
      if (this.ratings.length == 0) return
      this.loading = true;
      this.error = '';
      let max_id = this.ratings[this.ratings.length - 1].id;
      axios
      .get('/api/rating/list/mine', {headers: {'x-access-token': localStorage.token}, params: { maxid: max_id }})
      .then(response => {
        this.ratings = this.ratings.concat(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить рейтинги'
        }
      })
      .finally(() => this.loading = false)
    },
    actionPanelToggle(panel) {
      if (this.actionPanel == panel) {
        this.actionPanel = null;
      } else {
        this.actionPanel = panel;
      }
    },
    createRating() {
      if (this.createRatingName == '') return
      this.creatingRating = true;
      this.createRatingError = '';
      axios
      .post('/api/rating/create', {name: this.createRatingName}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.getRatings();
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.createRatingError = error.response.data;
        } else {
          this.createRatingError = 'Не получилось создать рейтинг'
        }
      })
      .finally(() => this.creatingRating = false)
    },
    deleteRating() {
      if (this.deleteRatingName != this.selectedRating.name) {
        this.deleteRatingError = "Название рейтинга не совпадает";
        return
      }
      this.deletingRating = true;
      this.deleteRatingError = '';
      axios
      .post('/api/rating/delete', {id: this.selectedRating.id}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.getRatings();
        this.selectedRating = null;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.deleteRatingError = error.response.data;
        } else {
          this.deleteRatingError = 'Не получилось удалить рейтинг'
        }
      })
      .finally(() => this.deletingRating = false)
    },
    selectRating(rating) {
      if (this.selectedRating == rating) {
        this.selectedRating = null
      } else {
        this.selectedRating = rating;
      }
    }
  },
  mounted() {
    this.role = localStorage.role;
    this.username = localStorage.username;
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.replace({ path: '/login' })
    }
    this.getRatings();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
li.selected {
  background-color: rgb(93 113 172);
  color: #ffffff;
}
li a:hover {
  color: #248d24
}
</style>
