<template>
  <h3>{{ tournament_name }}</h3>
  <span v-if="error != ''" class="error">{{error}}</span>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <div id='participants-div'>
    <div class='action-tabs' v-if="isEditor && !loading && !tournament_team">
      <div class='action-tabs-header' :class="{'collapsed': !actionPanel}">
        <button class='action' @click="actionPanelToggle('addPlayer')" :class="{'active': (actionPanel == 'addPlayer')}">
          Добавить игрока
        </button>
        <div class="vl"></div>
        <button class='action' @click="actionPanelToggle('createPlayer')" :class="{'active': (actionPanel == 'createPlayer')}">
          Создать нового игрока
        </button>
        <div class="vl"></div>
        <button class='action' @click="actionPanelToggle('addRatingPlayers')" :class="{'active': (actionPanel == 'addRatingPlayers')}" v-if="rating_id">
          Добавить игроков из рейтинга
        </button>
      </div>
      <div class="action-panels" v-if="actionPanel">
        <div id="add-player-div" class="action-panel" v-show="actionPanel == 'addPlayer'">
          <div class="search-div">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" placeholder="Поиск игроков..." v-model="searchString" v-on:keyup.enter="searchPlayer">
            <button :disabled="searching" v-on:click="searchPlayer">
              <span v-show="!searching">Поиск</span>
              <i class="fas fa-spinner fa-pulse" v-show="searching"></i>
            </button>
          </div>
          <div v-if="isMobile" id="mobile-search-content">
            <div class="searched-player" v-for="(player, index) in foundPlayers" :key="player.id" :class="{'last': index == foundPlayers.length - 1}">
              <div class="searched-player-info">
                <h4 class="searched-player-name">{{ player.full_name }}</h4>
                <label v-if="player.birth">{{ `Дата рождения: ${player.birth}` }}</label>
                <label v-if="player.city && player.city != 'null'">{{ `Город: ${player.city}` }}</label>
                <label v-if="player.rank">{{ `Разряд: ${player.rank}` }}</label>
              </div>
              <a @click="addParticipant(player.id)" class="add-button">
                <i class="fa-solid fa-circle-plus" v-show="!updating"></i>
                <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
              </a>
            </div>
          </div>
          <div v-else id="search-content">
            <table v-show="foundPlayers.length">
              <thead>
                <tr>
                  <th style="width: 25%;">Имя</th>
                  <th style="width: 25%;">Дата Рождения</th>
                  <th style="width: 25%;">Город</th>
                  <th style="width: 24%;">Разряд</th>
                  <th style="width: 1%;">+</th>
                </tr>
              </thead>  
              <tbody>
                <tr v-for="player in foundPlayers" :key="player.id">
                  <td>{{ player.full_name }}</td>
                  <td>{{ player.birth }}</td>
                  <td>{{ player.city }}</td>
                  <td>{{ player.rank }}</td>
                  <td>
                    <button @click="addParticipant(player.id)" :disabled="updating">
                      <i class="fa-solid fa-square-plus" v-show="!updating"></i>
                      <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="create-player-div" v-show="actionPanel == 'createPlayer'" class='action-panel'>
          <label>Имя</label>
          <input type="text" v-model="newPlayer.full_name">
          <label>Дата рождения</label>
          <Datepicker :value="newPlayer.birthDate" class='datepicker' @input="birthDateChange"></Datepicker>
          <label>Страна</label>
          <v-select v-show="!countries_loading" v-model="newPlayer.country" :options="countries" label="name" @option:selected="countryChanged()" class="country-select"></v-select>
          <div v-show="countries_loading" class="loader"><i class="fas fa-spinner fa-pulse"></i></div>
          <label>Город</label>
          <input type="text" v-model="newPlayer.city">
          <label>Звание</label>
          <input type="text" v-model="newPlayer.rank">
          <button @click="createPlayer" :disabled="creatingPlayer">
            <span v-show="!creatingPlayer">Создать и добавить</span>
            <i class="fas fa-spinner fa-pulse" v-show="creatingPlayer"></i>
          </button>
        </div>
        <div id="rating-players-div" v-show="actionPanel == 'addRatingPlayers'" class='action-panel'>
          <div id="loader" v-if="loadingRating">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
          <div v-else class='rating-participants-container'>
            <div class="actions">
              <button @click="addRatingParticipants" :disabled="updating || !selectedRatingParticipants.length">
                <i class="fas fa-spinner fa-pulse" v-if="updating"></i>
                <span v-else>Добавить</span>
              </button>
              <div class="list-control" v-if="!isMobile">
                <a @click="unselectAllRatingParticipants">Снять все выделения</a>
                <a @click="selectAllRatingParticipants">Выделить всех</a>
                <div>
                  <a @click="selectTopRatingParticipants">Выделить топ</a>
                  <select v-model="rating_top_select">
                    <option v-for="n in ratingParticipants.length" :key="n" :value="n">{{n}}</option>
                  </select> 
                </div>
              </div>
            </div>
            <div class="list-settings" v-show="ratingParticipants.length">
              <div class="search-setting">
                <a class="search-button" @click="searchRatingClicked()">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </a>
                <div v-show="searchingRatingParticipant" class='search-string'>
                  <input v-model='searchRatingParticipantString' id='search-rating-input'>
                  <a class="search-clear-button" @click="searchRatingParticipantString = null">
                    <i class="fa-solid fa-xmark"></i>
                  </a>
                </div>
              </div>
              <div class="sort-setting" v-click-outside="() => sortSelectingRating = false" v-if="isMobile">
                <a class="sort-button" :class="{'active': sortSelectingRating}" @click="sortSelectingRating = !sortSelectingRating">
                  <i class="fa-solid fa-arrow-down-short-wide"></i>
                </a>
                <div class="sort-options" v-show="sortSelectingRating">
                  <label @click="ratingHeaderClick('name'); sortSelectingRating = false">Имя</label>
                  <label @click="ratingHeaderClick('city'); sortSelectingRating = false">Город</label>
                  <label @click="ratingHeaderClick('birth'); sortSelectingRating = false">День рожденния</label>
                  <label @click="ratingHeaderClick('place'); sortSelectingRating = false">Место</label>
                  <label @click="ratingHeaderClick('points'); sortSelectingRating = false">Очки</label>
                  <label @click="ratingHeaderClick('tournaments'); sortSelectingRating = false">Кол-во турниров</label>
                </div>
              </div>
              <div class="select-setting" v-click-outside="() => selectingOption = false" v-if="isMobile">
                <a class="sort-button" :class="{'active': selectingOption}" @click="selectingOption = !selectingOption">
                  <i class="fa-solid fa-square-check"></i>
                </a>
                <div class="sort-options" v-show="selectingOption">
                  <label @click="selectAllRatingParticipants(); selectingOption = false">Выделить всех</label>
                  <label @click="unselectAllRatingParticipants(); selectingOption = false">Снять все выделения</label>
                  <label @click="rating_top_select = 10; selectTopRatingParticipants(); selectingOption = false">Выделить топ 10</label>
                  <label @click="rating_top_select = 10; selectTopRatingParticipants(); selectingOption = false">Выделить топ 20</label>
                  <label @click="rating_top_select = 10; selectTopRatingParticipants(); selectingOption = false">Выделить топ 30</label>
                  <label @click="rating_top_select = 10; selectTopRatingParticipants(); selectingOption = false">Выделить топ 40</label>
                  <label @click="rating_top_select = 10; selectTopRatingParticipants(); selectingOption = false">Выделить топ 50</label>
                  <label @click="rating_top_select = 10; selectTopRatingParticipants(); selectingOption = false">Выделить топ 60</label>
                </div>
              </div>
            </div>
            <div class="rating-players-list-mobile" v-if="isMobile">
              <div class="rating-player" v-for="(participant, index) in sortedRatingParticipants" :key="participant.id" :class="{'last': index == sortedRatingParticipants.length - 1}">
                <input type='checkbox' v-model="participant.selected" :disabled="participant.select_disabled">
                <div class="rating-player-info">
                  <h4 class="rating-player-name">{{participant.name}}</h4>
                  <div class="rating-player-details">
                    <label v-if="participant.city">{{`Город: ${participant.city}`}}</label>
                    <label v-if="participant.birth">{{`Дата рождения: ${participant.birth}`}}</label>
                    <label>{{`Место: ${participant.place}`}}</label>
                    <label>{{`Очки: ${participant.points}`}}</label>
                    <label>{{`Кол-во турниров: ${participant.tournaments}`}}</label>
                  </div>
                </div>
              </div>
            </div> 
            <table v-else>
              <thead>
                <th></th>
                <th @click="ratingHeaderClick('name')">Имя</th>
                <th @click="ratingHeaderClick('city')">Город</th>
                <th @click="ratingHeaderClick('birth')">День рожденния</th>
                <th @click="ratingHeaderClick('place')">Место</th>
                <th @click="ratingHeaderClick('points')">Очки</th>
                <th @click="ratingHeaderClick('tournaments')">Сыграл турниров</th>
              </thead>
              <tbody>
                <tr v-for="participant in sortedRatingParticipants" :key="participant.id">
                  <td>
                    <input type='checkbox' v-model="participant.selected" :disabled="participant.select_disabled">
                  </td>
                  <td>{{ participant.name }}</td>
                  <td>{{ participant.city }}</td>
                  <td>{{ participant.birth }}</td>
                  <td>{{ participant.place }}</td>
                  <td>{{ participant.points }}</td>
                  <td>{{ participant.tournaments }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="content" v-if="!isMobile">
      <div class="list-settings" v-show="participants.length || tournament_team">
        <a class='add-button' title="Добавить команду" @click="create_team_dialog = true" v-if="tournament_team && isEditor"><i class="fa-solid fa-circle-plus"></i></a>
        <div class="search-setting" v-if="!tournament_team">
          <a class="search-button" @click="searchClicked()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </a>
          <div v-show="searchingParticipant" class='search-string'>
            <input v-model='searchParticipantString' id='search-input'>
            <a class="search-clear-button" @click="searchParticipantString = null">
              <i class="fa-solid fa-xmark"></i>
            </a>
          </div>
        </div>
        <div class="right-settings" v-show="participants.length">
          <div id="filter-settings-container" v-click-outside="() => filter_settings_show = false">
            <a title="Настройка отображения" @click="filter_settings_show=!filter_settings_show">
              <i class="fa-solid fa-filter"></i>
            </a>
            <div id="filter-settings-dropdown" v-show="filter_settings_show">
              <input type="checkbox" v-model="filter_settings.birth_day" @change="() => saveFilterSettings()"/>
              <label>Дата рождения</labeL>
              <input v-if="!tournament_team" type="checkbox" v-model="filter_settings.country" @change="() => saveFilterSettings()"/>
              <label v-if="!tournament_team">Страна</labeL>
              <input type="checkbox" v-model="filter_settings.region" @change="() => saveFilterSettings()"/>
              <label>Регион</labeL>
              <input type="checkbox" v-model="filter_settings.city" @change="() => saveFilterSettings()"/>
              <label>Город</labeL>
              <input type="checkbox" v-model="filter_settings.rank" @change="() => saveFilterSettings()"/>
              <label>Звание</labeL>
              <input v-if="handicap_id" type="checkbox" v-model="filter_settings.handicap_group" @change="() => saveFilterSettings()"/>
              <label v-if="handicap_id">Гандикап / группа</labeL>
              <input v-if="rating_id" type="checkbox" v-model="filter_settings.rating_place" @change="() => saveFilterSettings()"/>
              <label v-if="rating_id">Рейтинг / место</labeL>
              <input v-if="rating_id" type="checkbox" v-model="filter_settings.rating_points" @change="() => saveFilterSettings()"/>
              <label v-if="rating_id">Рейтинг / очки</labeL>
              <input v-if="rating_id" type="checkbox" v-model="filter_settings.rating_efficiency" @change="() => saveFilterSettings()"/>
              <label v-if="rating_id">Рейтинг / эффективность</labeL>
              <input type="checkbox" v-model="filter_settings.lot" @change="() => saveFilterSettings()"/>
              <label>Жребий</labeL>
              <input v-if="chart_type == 'round'" type="checkbox" v-model="filter_settings.group_place" @change="() => saveFilterSettings()"/>
              <label v-if="chart_type == 'round'" >Место в группе</labeL>
              <input v-if="chart_type == 'round'" type="checkbox" v-model="filter_settings.group_win_loss" @change="() => saveFilterSettings()"/>
              <label v-if="chart_type == 'round'">Выигр/Проигр</labeL>
              <input v-if="chart_type == 'round'" type="checkbox" v-model="filter_settings.group_points" @change="() => saveFilterSettings()"/>
              <label v-if="chart_type == 'round'">Групповые очки</labeL>
              <input type="checkbox" v-model="filter_settings.place" @change="() => saveFilterSettings()"/>
              <label>Место</labeL>
              <input type="checkbox" v-model="filter_settings.points" @change="() => saveFilterSettings()"/>
              <label>Очки</labeL>
              <input v-if="isEditor" type="checkbox" v-model="filter_settings.comment" @change="() => saveFilterSettings()"/>
              <label v-if="isEditor">Комментарий</labeL>
            </div>
          </div>
          <a title="Жребий" @click="lotParticipants" v-if="isEditor">
            <i class="fa-solid fa-dice" v-if="!updating"></i>
            <i class="fas fa-spinner fa-pulse" v-else></i>
          </a>
          <a title="Удалить жеребьевку" id="clear-lots" @click="clearLots" v-if="isEditor">
            <i v-if="!updating" class="fa-solid fa-ban"></i>
            <i v-if="!updating" class="fa-solid fa-dice"></i>
            <i class="fas fa-spinner fa-pulse" v-else></i>
          </a>
          <div class="sort-setting" v-click-outside="() => sortSelecting = false" v-if="tournament_team">
            <a class="sort-button" :class="{'active': sortSelecting}" @click="sortSelecting = !sortSelecting">
              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </a>
            <div class="sort-options" v-show="sortSelecting">
              <label @click="headerClick('name'); sortSelecting = false">Имя</label>
              <label @click="headerClick('lot'); sortSelecting = false">Жребий</label>
              <label v-show="handicap_id" @click="headerClick('group'); sortSelecting = false">Группа</label>
              <label v-show="isEditor" @click="headerClick('comment'); sortSelecting = false">Комментарий</label>
              <label v-show="tournament_status=='finished'" @click="headerClick('place'); sortSelecting = false">Место</label>
              <label v-show="tournament_status=='finished'" @click="headerClick('points'); sortSelecting = false">Очки</label>
            </div>
          </div>
        </div>
      </div>
      <table v-show="participants.length" v-if="!tournament_team">
        <thead>
          <tr>
            <th class="id-column">#</th>
            <th @click="headerClick('name')">Имя</th>
            <th @click="headerClick('birth')" v-if="filter_settings.birth_day">Дата рождения</th>
            <th @click="headerClick('country_name')" v-if="filter_settings.country">Страна</th>
            <th @click="headerClick('region')" v-if="filter_settings.region">Регион</th>
            <th @click="headerClick('city')" v-if="filter_settings.city">Город</th>
            <th @click="headerClick('rank')" v-if="filter_settings.rank">Звание</th>
            <th @click="headerClick('group')" v-if="filter_settings.handicap_group && handicap_id">Гандикап/группа</th>
            <th @click="headerClick('rating_place')" v-if="filter_settings.rating_place && rating_id">Рейтинг/место</th>
            <th @click="headerClick('rating_points')" v-if="filter_settings.rating_points && rating_id">Рейтинг/очки</th>
            <th @click="headerClick('rating_efficiency')" v-if="filter_settings.rating_efficiency && rating_id">Рейтинг/эффективность</th>
            <th @click="headerClick('lot')" v-if="filter_settings.lot">Жребий</th>
            <th @click="headerClick('group_place')" v-if="filter_settings.group_place && chart_type == 'round'">Место в группе</th>
            <th @click="headerClick('group_win_loss')" v-if="filter_settings.group_win_loss && chart_type == 'round'">Выигр/Проигр</th>
            <th @click="headerClick('group_points')" v-if="filter_settings.group_points && chart_type == 'round'">Групповые Очки</th>
            <th @click="headerClick('place')" v-if="filter_settings.place">Место</th>
            <th @click="headerClick('points')" v-if="filter_settings.points">Очки</th>
            <th @click="headerClick('comment')" v-if="isEditor && filter_settings.comment">Комментарий</th>
            <th class="delete-column" v-if="isEditor">-</th>
          </tr>
        </thead>  
        <tbody>
          <tr v-for="(participant, index) in sortedParticipants" :key="participant.id">
            <td class="id-column">{{index+1}}</td>
            <td>
              <div class="name-cell">
                <div class="flag-container" v-if="participant.country_label"><country-flag :country="participant.country_label" :title="participant.country_name" size='small'/></div>
                <router-link :to="`/players/${participant.player_id}/info`" class="player-link">
                  {{participant.name}}
                </router-link>
              </div>
            </td>
            <td v-if="filter_settings.birth_day">{{ participant.birth }}</td>
            <td v-if="filter_settings.country">
              <span>{{ participant.country_name != 'null' ? participant.country_name : '' }}</span>
            </td>
            <td v-if="filter_settings.region">
              <span>{{ participant.region != 'null' ? participant.region : '' }}</span>
            </td>
            <td v-if="filter_settings.city">
              <span>{{ participant.city != 'null' ? participant.city : '' }}</span>
            </td>
            <td v-if="filter_settings.rank">
              <span>{{ participant.rank != 'null' ? participant.rank : '' }}</span>
            </td>
            <td v-if="filter_settings.handicap_group && handicap_id">
              <input v-if="isEditor" type="number" v-model="participant.group" @blur="handicapUpdate(participant)">
              <span v-else>{{participant.group}}</span>
            </td>
            <td v-if="filter_settings.rating_place && rating_id">
              <span>{{ participant.rating_place != 'null' ? participant.rating_place : '' }}</span>
            </td>
            <td v-if="filter_settings.rating_points && rating_id">
              <span>{{ participant.rating_points != 'null' ? participant.rating_points : '' }}</span>
            </td>
            <td v-if="filter_settings.rating_efficiency && rating_id">
              <span>{{ participant.rating_efficiency != 'null' ? participant.rating_efficiency : '' }}</span>
            </td>
            <td v-if="filter_settings.lot">
              <input v-if="!games.length && isEditor" type="number" min="1" :max="participants.length" v-model="participant.lot" @blur="participantLotChange(participant)">
              <span v-else>{{participant.lot}}</span>
            </td>
            <td v-if="filter_settings.group_place && chart_type == 'round'">{{participant.group_place}}</td>
            <td v-if="filter_settings.group_win_loss && chart_type == 'round'">{{`${participant.win_count}/${participant.loss_count}`}}</td>
            <td v-if="filter_settings.group_points && chart_type == 'round'">{{participant.group_points}}</td>
            <td v-if="filter_settings.place">{{participant.place}}</td>
            <td v-if="filter_settings.points">{{participant.points}}</td>
            <td v-if="filter_settings.comment && isEditor">
              <input type="text" v-model="participant.comment_temp" @blur="participantUpdate(participant)" v-show="!updating">
              <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
            </td>
            <td class="delete-column" v-if="isEditor">
              <button @click="deleteParticipant(participant.id)" :disabled="!isDeleteCapable(participant) || updating">
                <i class="fa-solid fa-trash-can" v-show="!updating"></i>
                <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="teams-container"  v-if="tournament_team" v-show="teams.length">
        <div v-for="(team, index) in sortedTeams" :key="team.id" class="team-line" :class="{'last': index == teams.length - 1}">
          <div class="team-line-header">
            <div class="team-header-part">
              <label class="team-index">{{ index+1 }}</label>
              <input v-model="team.name" v-if="team.rename_team && isEditor" v-click-outside="() => {team.rename_team = false; renameTeam(team);}">
              <label class="team-name" v-else>{{ team.name }}</label>
              <a class="team-rename" @click="team.rename_team = true" v-if="!team.rename_team && isEditor" v-show="!team.rename_running"><i class="fa-solid fa-pencil"></i></a>
              <a class="team-rename" @click="team.rename_team = false; renameTeam(team);" v-else v-show="!team.rename_running && isEditor"><i class="fa-solid fa-floppy-disk"></i></a>
              <i class="fas fa-spinner fa-pulse" v-show="team.rename_running && isEditor"></i>
            </div>
            <div class="team-header-part team-info">
              <label v-if="handicap_id" v-show="filter_settings.handicap_group">{{ `Гандикап / группа: ${teamGroup(team.id)}` }}</label>
              <label v-if="participant_by_team[team.id][0].lot && !isEditor" v-show="filter_settings.lot">{{ `Жребий: ${participant_by_team[team.id][0].lot}` }}</label>
              <label v-if="isEditor" class="lot-label" v-show="filter_settings.lot">
                Жребий:
                <input v-if="isEditor" type="number" v-model="participant_by_team[team.id][0].lot" @blur="participantUpdate(participant_by_team[team.id][0])" v-show="!updating">
              </label>
              <label v-if="isEditor" class="comment-label pc" v-show="filter_settings.comment">
                Комментарий:
                <input v-if="isEditor" type="text" v-model="participant_by_team[team.id][0].comment_temp" @blur="participantUpdate(participant_by_team[team.id][0])" v-show="!updating">
              </label>
              <label v-if="participant_by_team[team.id][0].place" v-show="filter_settings.place">{{ `Место: ${participant_by_team[team.id][0].place}` }}</label>
              <label v-if="participant_by_team[team.id][0].points" v-show="filter_settings.points">{{ `Очки: ${participant_by_team[team.id][0].points}` }}</label>
              <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
            </div>
            <div class="team-header-part team-actions" v-if="isEditor">
              <a class='team-member-add-button' title="Добавить игрока" @click="add_team_player_team = team; add_team_player_dialog = true" v-show="!team.deleting"><i class="fa-solid fa-circle-plus"></i></a>
              <a class='team-member-delete-button' title="Удалить команду" @click="removeTeam(team)" v-show="!team.deleting"><i class="fa-solid fa-trash-can"></i></a>
              <i class="fas fa-spinner fa-pulse" v-show="team.deleting"></i>
            </div>
          </div>
          <div class="team-line-content">
            <div class="team-member" v-for="(player,index) in players_by_team[team.id]" :key="player.id" :class="{'last': index == players_by_team[team.id].length - 1}">
              <div class="team-member-part member-name">
                <country-flag :country="player.country_label" :title="player.country_name" size='small' v-if="player.country_label"/>
                <router-link :to="`/players/${player.player_id}/info`" class="player-link">
                  {{player.full_name}}
                </router-link>
              </div>
              <div class="team-member-part member-info">
                <label v-if="player.region && player.region != 'null'" v-show="filter_settings.region">{{ `Регион: ${player.region}` }}</label>
                <label v-if="player.city && player.city != 'null'" v-show="filter_settings.city">{{ `Город: ${player.city}` }}</label>
                <label v-if="player.rank && player.rank != 'null'" v-show="filter_settings.rank">{{ `Звание: ${player.rank}` }}</label>
                <label v-if="formatBirthDate(player)" v-show="filter_settings.birth_day">{{ `ДР: ${formatBirthDate(player)}` }}</label>
                <div class="participant-handicap" v-show="handicap_id && filter_settings.handicap_group">
                  <div v-if="isEditor && handicap_id">
                    <label class="handicap-label" v-if="!player.handicap_updating">
                      Гандикап / группа:
                      <input type="number" v-model="player.group" @blur="handicapUpdate(player)" class="handicap-input">
                    </label>
                    <i class="fas fa-spinner fa-pulse" v-else></i>
                  </div>
                  <label v-if="!isEditor && handicap_id">{{`Гандикап / группа: ${player.group ? player.group : 0}`}}</label>
                </div>
              </div> 
              <div class="team-member-part member-actions" v-if="isEditor">
                <a class='team-member-delete-button' title="Удалить Игрока" v-show="!player.deleting" @click="removeTeamMember(player)"><i class="fa-solid fa-trash-can"></i></a>
                <i class="fas fa-spinner fa-pulse" v-show="player.deleting"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else id="mobile-content">
      <div class="list-settings" v-show="participants.length || tournament_team">
        <a class='add-button' title="Добавить команду" @click="create_team_dialog = true" v-if="tournament_team && isEditor"><i class="fa-solid fa-circle-plus"></i></a>
        <div class="search-setting" v-if="!tournament_team">
          <a class="search-button" @click="searchClicked()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </a>
          <div v-show="searchingParticipant" class='search-string'>
            <input id='search-input' :value="searchParticipantString" @input="e => searchParticipantString = e.target.value">
            <a class="search-clear-button" @click="searchParticipantString = null">
              <i class="fa-solid fa-xmark"></i>
            </a>
          </div>
        </div>
        <div class="right-settings" v-show="participants.length">
          <div id="filter-settings-container" v-click-outside="() => filter_settings_show = false">
            <a title="Настройка отображения" @click="filter_settings_show=!filter_settings_show">
              <i class="fa-solid fa-filter"></i>
            </a>
            <div id="filter-settings-dropdown" v-show="filter_settings_show">
              <input type="checkbox" v-model="filter_settings.birth_day" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.birth_day=!filter_settings.birth_day; saveFilterSettings()">Дата рождения</labeL>
              <input type="checkbox" v-model="filter_settings.region" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.region=!filter_settings.region; saveFilterSettings()">Регион</labeL>
              <input type="checkbox" v-model="filter_settings.city" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.city=!filter_settings.city; saveFilterSettings()">Город</labeL>
              <input type="checkbox" v-model="filter_settings.rank" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.rank=!filter_settings.rank; saveFilterSettings()">Звание</labeL>
              <input v-if="handicap_id" type="checkbox" v-model="filter_settings.handicap_group" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.handicap_group=!filter_settings.handicap_group; saveFilterSettings()" v-if="handicap_id">Гандикап / группа</labeL>
              <input v-if="rating_id" type="checkbox" v-model="filter_settings.rating_place" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.rating_place=!filter_settings.rating_place; saveFilterSettings()" v-if="rating_id">Рейтинг / место</labeL>
              <input v-if="rating_id" type="checkbox" v-model="filter_settings.rating_points" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.rating_points=!filter_settings.rating_points; saveFilterSettings()" v-if="rating_id">Рейтинг / очки</labeL>
              <input v-if="rating_id" type="checkbox" v-model="filter_settings.rating_efficiency" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.rating_efficiency=!filter_settings.rating_efficiency; saveFilterSettings()" v-if="rating_id">Рейтинг / эффективность</labeL>
              <input type="checkbox" v-model="filter_settings.lot" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.lot=!filter_settings.lot; saveFilterSettings()">Жребий</labeL>
              <input v-if="chart_type == 'round'" type="checkbox" v-model="filter_settings.group_place" @change="() => saveFilterSettings()"/>
              <label v-if="chart_type == 'round'" @click="filter_settings.group_place=!filter_settings.group_place; saveFilterSettings()">Место в группе</labeL>
              <input v-if="chart_type == 'round'" type="checkbox" v-model="filter_settings.group_win_loss" @change="() => saveFilterSettings()"/>
              <label v-if="chart_type == 'round'" @click="filter_settings.group_win_loss=!filter_settings.group_win_loss; saveFilterSettings()">Выигр/Проигр</labeL>
              <input v-if="chart_type == 'round'" type="checkbox" v-model="filter_settings.group_points" @change="() => saveFilterSettings()"/>
              <label v-if="chart_type == 'round'" @click="filter_settings.group_points=!filter_settings.group_points; saveFilterSettings()">Групповые очки</labeL>
              <input type="checkbox" v-model="filter_settings.place" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.place=!filter_settings.place; saveFilterSettings()">Место</labeL>
              <input type="checkbox" v-model="filter_settings.points" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.points=!filter_settings.points; saveFilterSettings()">Очки</labeL>
              <input v-if="isEditor" type="checkbox" v-model="filter_settings.comment" @change="() => saveFilterSettings()"/>
              <label @click="filter_settings.comment=!filter_settings.comment; saveFilterSettings()" v-if="isEditor">Комментарий</labeL>
            </div>
          </div>
          <a title="Жребий" @click="lotParticipants" v-if="isEditor">
            <i class="fa-solid fa-dice" v-if="!updating"></i>
            <i class="fas fa-spinner fa-pulse" v-else></i>
          </a>
          <a title="Удалить жеребьевку" id="clear-lots" @click="clearLots" v-if="isEditor">
            <i v-if="!updating" class="fa-solid fa-ban"></i>
            <i v-if="!updating" class="fa-solid fa-dice"></i>
            <i class="fas fa-spinner fa-pulse" v-else></i>
          </a>
          <div class="sort-setting" v-click-outside="() => sortSelecting = false">
            <a class="sort-button" :class="{'active': sortSelecting}" @click="sortSelecting = !sortSelecting">
              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </a>
            <div class="sort-options" v-show="sortSelecting">
              <label @click="headerClick('name'); sortSelecting = false">Имя</label>
              <label @click="headerClick('lot'); sortSelecting = false">Жребий</label>
              <label v-show="!tournament_team" @click="headerClick('city'); sortSelecting = false">Город</label>
              <label v-show="!tournament_team" @click="headerClick('region'); sortSelecting = false">Регион</label>
              <label v-show="handicap_id" @click="headerClick('group'); sortSelecting = false">Гандикап / группа</label>
              <label v-show="rating_id" @click="headerClick('rating_place'); sortSelecting = false">Рейтинг / место</label>
              <label v-show="rating_id" @click="headerClick('rating_points'); sortSelecting = false">Рейтинг / очки</label>
              <label v-show="rating_id" @click="headerClick('rating_efficiency'); sortSelecting = false">Рейтинг / эффективность</label>
              <label v-show="isEditor" @click="headerClick('comment'); sortSelecting = false">Комментарий</label>
              <label v-if="chart_type == 'round' && filter_settings.group_place" @click="headerClick('group_place'); sortSelecting = false">Место в группе</label>
              <label v-if="chart_type == 'round' && filter_settings.group_win_loss" @click="headerClick('group_win_loss'); sortSelecting = false">Выигр/Проигр</label>
              <label v-if="chart_type == 'round' && filter_settings.group_points" @click="headerClick('group_points'); sortSelecting = false">Групповые очки</label>
              <label v-show="tournament_status=='finished'" @click="headerClick('place'); sortSelecting = false">Место</label>
              <label v-show="tournament_status=='finished'" @click="headerClick('points'); sortSelecting = false">Очки</label>
            </div>
          </div>
        </div>
      </div>
      <div class="participants-list" v-show="participants.length" v-if="!tournament_team">
        <div class="participant-cell" v-for="(participant, index) in sortedParticipants" :key="participant.id" :class="{'last': index == sortedParticipants.length - 1}">
          <div class="participant-header">
            <h4>{{`#${index+1}`}}</h4>
            <div class="name_plus_delete_div">
              <div class="flag-container" v-if="participant.country_label"><country-flag :country="participant.country_label" :title="participant.country_name" size='small'/></div>
              <h4>
                <router-link :to="`/players/${participant.player_id}/info`" class='player-link'>
                  {{participant.name}}
                </router-link>
              </h4>
              <button @click="deleteParticipant(participant.id)" :disabled="!isDeleteCapable(participant) || updating" v-if="isEditor">
                <i class="fa-solid fa-trash-can" v-show="!updating"></i>
                <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
              </button>
            </div>
            <label v-show="filter_settings.lot">
              <i class="fa-solid fa-dice"></i>
              <input v-if="!games.length && isEditor" type="number" min="1" :max="participants.length" v-model="participant.lot" @blur="participantLotChange(participant)">
              <span v-else>{{participant.lot}}</span>
            </label>
          </div>
          <div class="participant-attrs">
            <div class="participant-rank" v-if="filter_settings.rank && participant.rank">
              <label>{{ participant.rank }}</label>
            </div>
            <div class="participant-location">
              <label v-if="participant.region && filter_settings.region">{{`Регион: ${participant.region}`}}</label>
              <label v-if="participant.birth && filter_settings.birth_day">{{participant.birth}}</label>
              <label v-if="participant.city && filter_settings.city" class="city">{{`Город: ${participant.city}`}}</label>
            </div>
            <div class="participant-handicap" v-show="handicap_id && filter_settings.handicap_group">
              <div v-if="isEditor">
                <label>Гандикап / группа: </label>
                <input type="number" v-model="participant.group" @blur="handicapUpdate(participant)">
              </div>
              <label v-else>{{`Гандикап / группа: ${participant.group ? participant.group : 0}`}}</label>
            </div>
            <div class="participant-rating" v-if="rating_id && (filter_settings.rating_place || filter_settings.rating_points || filter_settings.rating_efficiency)">
              <h4>Рейтинг</h4>
              <div class="participant-rating-attrs">
                <span v-if="filter_settings.rating_place">{{ `Место: ${participant.rating_place}` }}</span>
                <span v-if="filter_settings.rating_points">{{ `Очки: ${participant.rating_points}` }}</span>
                <span v-if="filter_settings.rating_efficiency">{{ `Эффективность: ${participant.rating_efficiency}` }}</span>
              </div>
            </div>
            <div v-if="chart_type == 'round'" class="participant-group">
              <label v-if="filter_settings.group_place">{{`Место в группе: ${participant.group_place}`}}</label>
              <label v-if="filter_settings.group_win_loss">{{`Выигр/Проигр: ${participant.win_count}/${participant.loss_count}`}}</label>
              <label v-if="filter_settings.group_points">{{`Групповые очки: ${participant.group_points}`}}</label>
            </div>
            <div v-show="isEditor" class="participant-comment" v-if="filter_settings.comment">
              <label>Комментарий: </label>
              <input type="text" v-model="participant.comment_temp" @blur="participantUpdate(participant)" v-show="!updating">
            </div>
            <div v-show="tournament_status=='finished'" class="participant-result">
              <label v-if="filter_settings.place">{{`Место: ${participant.place}`}}</label>
              <label v-if="filter_settings.points">{{`Очки: ${participant.points}`}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="teams-container"  v-if="tournament_team" v-show="teams.length">
        <div v-for="(team, index) in sortedTeams" :key="team.id" class="team-line" :class="{'last': index == teams.length - 1}">
          <div class="team-line-header">
            <div class="team-header-part">
              <label class="team-index">{{ index+1 }}</label>
              <input v-model="team.name" v-if="team.rename_team && isEditor" v-click-outside="() => {team.rename_team = false; renameTeam(team);}">
              <label class="team-name" v-else>{{ team.name }}</label>
              <a class="team-rename" @click="team.rename_team = true" v-if="!team.rename_team && isEditor" v-show="!team.rename_running"><i class="fa-solid fa-pencil"></i></a>
              <a class="team-rename" @click="team.rename_team = false; renameTeam(team);" v-else v-show="!team.rename_running && isEditor"><i class="fa-solid fa-floppy-disk"></i></a>
              <i class="fas fa-spinner fa-pulse" v-show="team.rename_running && isEditor"></i>
            </div>
            <div class="team-header-part team-info">
              <label v-if="handicap_id" v-show="filter_settings.handicap_group">{{ `Гандикап / группа: ${teamGroup(team.id)}` }}</label>
              <label v-if="participant_by_team[team.id][0].lot && !isEditor" v-show="filter_settings.lot">{{ `Жребий: ${participant_by_team[team.id][0].lot}` }}</label>
              <label v-if="isEditor" v-show="filter_settings.lot" class="lot-label">
                Жребий:
                <input v-if="isEditor" type="number" v-model="participant_by_team[team.id][0].lot" @blur="participantUpdate(participant_by_team[team.id][0])" v-show="!updating && filter_settings.lot">
              </label>
              <label v-if="isEditor" v-show="filter_settings.comment" class="comment-label">
                Комментарий:
                <input v-if="isEditor" v-show="!updating && filter_settings.comment" type="text" v-model="participant_by_team[team.id][0].comment" @blur="participantUpdate(participant_by_team[team.id][0])">
              </label>
              <label v-if="participant_by_team[team.id][0].place" v-show="filter_settings.place">{{ `Место: ${participant_by_team[team.id][0].place}` }}</label>
              <label v-if="participant_by_team[team.id][0].points" v-show="filter_settings.points">{{ `Очки: ${participant_by_team[team.id][0].points}` }}</label>
              <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
            </div>
            <div class="team-header-part team-actions" v-if="isEditor">
              <a class='team-member-add-button' title="Добавить игрока" @click="add_team_player_team = team; add_team_player_dialog = true" v-show="!team.deleting"><i class="fa-solid fa-circle-plus"></i></a>
              <a class='team-member-delete-button' title="Удалить команду" @click="removeTeam(team)" v-show="!team.deleting"><i class="fa-solid fa-trash-can"></i></a>
              <i class="fas fa-spinner fa-pulse" v-show="team.deleting"></i>
            </div>
          </div>
          <div class="team-line-content">
            <div class="team-member" v-for="(player,index) in players_by_team[team.id]" :key="player.id" :class="{'last': index == players_by_team[team.id].length - 1}">
              <div class="team-member-part member-name">
                <country-flag :country="player.country_label" :title="player.country_name" size='small' v-if="player.country_label"/>
                <router-link :to="`/players/${player.player_id}/info`" class="player-link">
                  {{player.full_name}}
                </router-link>
              </div>
              <div class="team-member-part member-info">
                <label v-if="player.region && player.region != 'null'" v-show="filter_settings.region">{{ `Регион: ${player.region}` }}</label>
                <label v-if="player.city && player.city != 'null'" v-show="filter_settings.city">{{ `Город: ${player.city}` }}</label>
                <label v-if="formatBirthDate(player)" v-show="filter_settings.birth_day">{{ `ДР: ${formatBirthDate(player)}` }}</label>
                <label v-if="player.rank && player.rank != 'null'" v-show="filter_settings.rank">{{ `Звание: ${player.rank}` }}</label>
                <div class="participant-handicap" v-show="handicap_id && filter_settings.handicap_group">
                  <div v-if="isEditor && handicap_id">
                    <label v-if="!player.handicap_updating" class="handicap-label">
                      Гандикап / группа: 
                      <input type="number" v-model="player.group" @blur="handicapUpdate(player)" class="handicap-input">
                    </label>
                    <i class="fas fa-spinner fa-pulse" v-else></i>
                  </div>
                  <label v-if="!isEditor && handicap_id">{{`Гандикап / группа: ${player.group ? player.group : 0}`}}</label>
                </div>
              </div> 
              <div class="team-member-part member-actions" v-if="isEditor">
                <a class='team-member-delete-button' title="Удалить Игрока" v-show="!player.deleting" @click="removeTeamMember(player)"><i class="fa-solid fa-trash-can"></i></a>
                <i class="fas fa-spinner fa-pulse" v-show="player.deleting"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" v-if="create_team_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="create_team_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Создание новой команды` }}</label>
        <input type="text" placeholder="Название" v-model="create_team_name" v-on:keyup.enter="createTeam">
        <div class="modal-buttons">
          <button @click="createTeam">
            <i class="fas fa-spinner fa-pulse" v-if="creating_team"></i>
            <span v-else>Создать</span>
          </button>
        </div>
      </div>
      <span v-if="create_team_error" class="error">{{create_team_error}}</span>
    </div>
    <div class="modal" v-if="add_team_player_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="add_team_player_dialog=false; player_search_str=null; player_search_list=[]"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Добавление игрока в команду ${add_team_player_team.name}` }}</label>
        <div class="modal-tabs">
          <button @click="add_team_player_tab = 'search'" :class="{'active':  add_team_player_tab == 'search'}">Поиск</button>
          <div class="vl"></div>
          <button @click="add_team_player_tab = 'create'; if (!this.countries.length) this.getCountries();" :class="{'active':  add_team_player_tab == 'create'}">Создать</button>
          <div class="vl" v-if="rating_id"></div>
          <button v-if="rating_id" @click="add_team_player_tab = 'rating'; if (!this.countries.length) this.getCountries();" :class="{'active':  add_team_player_tab == 'rating'}">Рейтинг</button>
        </div>
        <div class="player-search-div" :class="{'mobile': isMobile, 'expanded': player_search_list.length}" v-show="!add_team_player_progress" v-if="add_team_player_tab == 'search'">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input :value="player_search_str" @input='evt=>searchPlayerInput(evt.target.value)'/>
          <i class="fas fa-spinner fa-pulse" v-show="player_searching"></i>
          <Transition name="expand">
            <div class="players-list" v-show="player_search_list.length">
              <a v-for="player in player_search_list" :key="player.id" @click="addTeamMember(player.id)">
                <country-flag :country="player.country_label" :title="player.country_name" size='small'/>
                <label>{{player.full_name}}</label>
                <div class="vl" v-if="player.city"></div>
                <label v-if="player.city">{{player.city}}</label>
                <div class="vl" v-if="formatBirthDate(player)"></div>
                <label v-if="formatBirthDate(player)">{{formatBirthDate(player)}}</label>
                <div v-if="player.rank" class="vl"></div>
                <label v-if="player.rank">{{player.rank}}</label>
              </a>
            </div>
          </Transition>
        </div>
        <div class="player-create-div" v-if="add_team_player_tab == 'create'">
          <label>Имя</label>
          <input type="text" v-model="newPlayer.full_name">
          <label>Дата рождения</label>
          <Datepicker :value="newPlayer.birthDate" class='datepicker' @input="birthDateChange"></Datepicker>
          <label>Страна</label>
          <v-select v-show="!countries_loading" v-model="newPlayer.country" :options="countries" label="name" @option:selected="countryChanged()" class="country-select"></v-select>
          <div v-show="countries_loading" class="loader"><i class="fas fa-spinner fa-pulse"></i></div>
          <label>Город</label>
          <input type="text" v-model="newPlayer.city">
          <label>Звание</label>
          <input type="text" v-model="newPlayer.rank">
          <button @click="createPlayer" :disabled="creatingPlayer">
            <span v-show="!creatingPlayer">Создать и добавить</span>
            <i class="fas fa-spinner fa-pulse" v-show="creatingPlayer"></i>
          </button>
        </div>
        <div class="player-rating-div" v-if="add_team_player_tab == 'rating'"  v-show="!add_team_player_progress">
          <div class="rating-player-search">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input v-model="searchRatingParticipantString"/>
          </div>
          <div class="player-rating-list">
            <a class="rating-player" v-for="(player, index) in sortedRatingParticipants" :key="player.id" :class="{last: index == sortedRatingParticipants.length - 1}" @click="addTeamMember(player.player_id)">
              <label>{{player.place}}</label>
              <label>{{player.name}}</label>
              <div class="vl" v-if="player.city"></div>
              <label v-if="player.city">{{player.city}}</label>
              <div class="vl" v-if="formatBirthDate(player)"></div>
              <label v-if="formatBirthDate(player)">{{formatBirthDate(player)}}</label>
              <div v-if="player.rank" class="vl"></div>
              <label v-if="player.rank">{{player.rank}}</label>
            </a>
          </div>
        </div>
        <i class="fas fa-spinner fa-pulse" v-show="add_team_player_progress"></i>
      </div>
      <span v-if="create_team_error" class="error">{{create_team_error}}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Datepicker from 'vuejs3-datepicker';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CountryFlag from 'vue-country-flag-next'
export default {
  name: 'TournamentParticipants',
  components: {
    'v-select': vSelect,
    'Datepicker': Datepicker,
    'country-flag': CountryFlag
  },
  data: function() {
    return {
      username: null,
      role: null,
      participants: [],
      games: [],
      chart_type: null,
      tournament_id: null,
      tournament_name: null,
      tournament_status: null,
      tournament_team: false,
      handicap_id: null,
      rating_id: null,
      fetchedRatingParticipants: [],
      loadingRating: false,
      rating_top_select: null,
      owner: '',
      loading: false,
      searchString: '',
      searching: false,
      fetchedPlayers: [],
      error: '',
      actionPanel: null,
      updating: false,
      newPlayer: {
        full_name: '',
        birth_year: '',
        birth_month: '',
        birth_day: '',
        rank: '',
        city: ''
      },
      creatingPlayer: false,
      participantsSort: null,
      previousParticipantsSort: null,
      ratingParticipantsSort: null,
      previousRatingParticipantsSort: null,
      regions_loading: true,
      regions: [],
      sortSelecting: false,
      sortSelectingRating: false,
      selectingOption: false,
      searchingParticipant: false,
      searchParticipantString: null,
      searchingRatingParticipant: false,
      searchRatingParticipantString: null,
      countries_loading: false,
      countries: [],
      teams: [],
      team_members: [],
      create_team_dialog: false,
      create_team_name: null,
      creating_team: false,
      create_team_error: null,
      add_team_player_dialog: false,
      add_team_player_team: null,
      add_team_player_progress: false,
      add_team_player_tab: 'search',
      player_search_str: null,
      player_search_list: [],
      player_search_timeout: null,
      player_searching: false,
      rating_player_search_str: null,
      filter_settings_show: false,
      filter_settings: {
        birth_day: true,
        country: true,
        region: false,
        city: false,
        rank: false,
        handicap_group: true,
        rating_place: false,
        rating_points: false,
        rating_efficiency: false,
        lot: true,
        place: true,
        points: true,
        group_place: true,
        group_win_loss: true,
        group_points: true,
        comment: true
      }
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    foundPlayers () { 
      return this.fetchedPlayers.map((player) => {
        let birth_str = "";
        if (player.birth_day != null) {
          let birth_day_str = (player.birth_day.toString().length == 1) ? '0' + player.birth_day : player.birth_day;
          birth_str+=birth_day_str + '.'
        }
        if (player.birth_month != null) {
          let birth_month_str = (player.birth_month.toString().length == 1) ? '0' + player.birth_month : player.birth_month;
          birth_str+=birth_month_str + '.';
        }
        if (player.birth_year != null) birth_str+=player.birth_year;
        player.birth = birth_str;
        return player;
      });
    },
    modifiedParticipants() {
      if (this.chart_type != 'round') return this.participants
      let parts = this.participants;
      parts.forEach(part => {
        part.win_count = 0;
        part.loss_count = 0;
      })
      let parts_by_id = this.groupArrayBy(parts, 'id');
      this.games.forEach(game => {
        if (game.status == 'finished') {
          parts_by_id[game.player1][0].win_count += game.player1_score;
          parts_by_id[game.player1][0].loss_count += game.player2_score;
          parts_by_id[game.player2][0].win_count += game.player2_score;
          parts_by_id[game.player2][0].loss_count += game.player1_score;
        }
      });
      let sorted_parts = [...parts];
      sorted_parts.sort((a,b) => {
        if (a.group_points > b.group_points) return -1
        if (a.group_points < b.group_points) return 1
        if (a.win_count > b.win_count) return -1
        if (a.win_count < b.win_count) return 1
        if (a.loss_count > b.loss_count) return 1
        if (a.loss_count < b.loss_count) return -1
        return 0
      })
      for (let i=0; i<sorted_parts.length; i++) {
        parts_by_id[sorted_parts[i].id][0].group_place = i+1;
      }
      return parts
    },
    filteredParticipants() {
      if (!this.searchParticipantString) {
        return this.modifiedParticipants;
      }
      return this.modifiedParticipants.filter(player => player.name && player.name.toLowerCase().indexOf(this.searchParticipantString.toLowerCase()) !== -1);
    },
    sortedParticipants () {
      if (this.participantsSort == null) return this.filteredParticipants;
      let sorted = this.filteredParticipants;
      let ascending = true;
      if (this.participantsSort == this.previousParticipantsSort) ascending = false;
      sorted.sort(this.dynamicSort(this.participantsSort, ascending));
      return sorted
    },
    sortedTeams () {
      let sorted = this.teams;
      sorted.forEach(team => {
        team.place = this.participant_by_team[team.id][0].place;
        team.points = this.participant_by_team[team.id][0].points;
        team.lot = this.participant_by_team[team.id][0].lot;
        team.group = this.teamGroup(team.id);
      })
      if (this.participantsSort == null) return sorted;
      let ascending = true;
      if (this.participantsSort == this.previousParticipantsSort) ascending = false;
      sorted.sort(this.dynamicSort(this.participantsSort, ascending));
      return sorted
    },
    isMobile() {
      return screen.width <= 1000;
    },
    ratingParticipants() {
      let result = this.fetchedRatingParticipants.map(participant => {
        let birth_str = "";
        if (participant.birth_day != null) {
          let birth_day_str = (participant.birth_day.toString().length == 1) ? '0' + participant.birth_day : participant.birth_day;
          birth_str+=birth_day_str + '.'
        }
        if (participant.birth_month != null) {
          let birth_month_str = (participant.birth_month.toString().length == 1) ? '0' + participant.birth_month : participant.birth_month;
          birth_str+=birth_month_str + '.';
        }
        if (participant.birth_year != null) birth_str+=participant.birth_year;
        participant.birth = birth_str;
        participant.select_disabled = false;
        for (let i=0; i<this.participants.length; i++) {
          if (this.participants[i].player_id == participant.player_id) {
            participant.select_disabled = true;
            participant.selected = false;
            break;
          }
        }
        return participant;
      });
      result.sort((a,b) => b.points - a.points);
      return result;
    },
    selectedRatingParticipants() {
      return this.ratingParticipants.filter(part => part.selected);
    },
    sortedRatingParticipants () {
      if (this.ratingParticipantsSort == null) return this.filteredRatingParticipants;
      let ascending = true;
      if (this.ratingParticipantsSort == this.previousRatingParticipantsSort) ascending = false;
      return [...this.filteredRatingParticipants].sort(this.dynamicSort(this.ratingParticipantsSort, ascending));
    },
    filteredRatingParticipants() {
      if (!this.searchRatingParticipantString) {
        return this.ratingParticipants;
      }
      return this.ratingParticipants.filter(player => player.name && player.name.toLowerCase().indexOf(this.searchRatingParticipantString.toLowerCase()) !== -1);
    },
    players_by_team() {
      return this.groupArrayBy(this.team_members, 'team_id');
    },
    participant_by_team() {
      return this.groupArrayBy(this.participants, 'team_id');
    }
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.name = '';
      this.info = '';
      axios
      .get('/api/tournament/details', { params: { id: this.$route.params.id } })
      .then(response => {
        this.participants = response.data.participants;
        this.participants.forEach(participant => {
          if (participant.city == 'null') participant.city = '';
          if (participant.country == 'null') participant.country = '';
          if (participant.rank == 'null') participant.rank = '';
          if (participant.region == 'null') participant.region = '';
          if (participant.rating_efficiency) participant.rating_efficiency = Math.round(participant.rating_efficiency) + '%';
          let birth_str = "";
          if (participant.birth_day != null) {
            let birth_day_str = (participant.birth_day.toString().length == 1) ? '0' + participant.birth_day : participant.birth_day;
            birth_str+=birth_day_str + '.'
          }
          if (participant.birth_month != null) {
            let birth_month_str = (participant.birth_month.toString().length == 1) ? '0' + participant.birth_month : participant.birth_month;
            birth_str+=birth_month_str + '.';
          }
          if (participant.birth_year != null) birth_str+=participant.birth_year;
          participant.birth = birth_str;
          participant.comment_temp = participant.comment;
        })
        this.owner = response.data.owner;
        this.tournament_id = response.data.id;
        this.tournament_name = response.data.name;
        this.tournament_status = response.data.status;
        this.games = response.data.games;
        this.handicap_id = response.data.handicap_id;
        this.rating_id = response.data.rating_id;
        this.tournament_team = response.data.team;
        if (this.rating_id) {
          this.getRatingParticipants();
        }
        if (response.data.teams) {
          this.teams = response.data.teams;
          this.team_members = response.data.team_members;
        }
        if (response.data.chart_type) {
          this.chart_type = response.data.chart_type;
        } else {
          this.chart_type = 'classic';
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
      .finally(() => this.loading = false)
    },
    getRatingParticipants() {
      this.error = '';
      this.loadingRating = true;
      this.fetchedRatingParticipants = [];
      axios
      .get('/api/rating/participants', { params: { id: this.rating_id } })
      .then(response => {
        this.fetchedRatingParticipants = response.data.participants;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'failed to get rating participants';
        }
      })
      .finally(() => this.loadingRating = false)
    },
    addRatingParticipants() {
      if (!this.tournament_id) {
        console.log('tournament_id is not defined');
        return
      }
      if (!this.selectedRatingParticipants.length) {
        console.log('No selected participants');
        return
      }
      this.error = '';
      this.updating = true;
      axios
      .post('/api/tournament/participant/addscope', {tournament_id: this.tournament_id, players: this.selectedRatingParticipants}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    unselectAllRatingParticipants() {
      this.ratingParticipants.forEach(participant => {
        participant.selected = false;
      })
    },
    selectAllRatingParticipants() {
      this.ratingParticipants.forEach(participant => {
        if (!participant.select_disabled) {
          participant.selected = true;
        }
      })
    },
    selectTopRatingParticipants() {
      this.ratingParticipants.forEach(participant => {
        if (!participant.select_disabled && participant.place <= this.rating_top_select) {
          participant.selected = true;
        } else {
          participant.selected = false;
        }
      });
    },
    getRegions() {
      this.regions_loading = true;
      this.region = [];
      axios
      .get('/api/player/get/regions')
      .then(response => {
        this.regions = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.regions_loading = false)
    },
    getCountries() {
      this.countries_loading = true;
      this.countries = [];
      axios
      .get('/api/player/get/countries')
      .then(response => {
        this.countries = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.countries_loading = false)
    },
    addParticipant(player_id) {
      if (!this.tournament_id) {
        console.log('tournament_id is not defined');
        return
      }
      if (!player_id ) {
        console.log('player_id is not defined');
        return
      }
      this.error = '';
      this.updating = true;
      axios
      .post('/api/tournament/participant/add', {tournament_id: this.tournament_id, player_id: player_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    deleteParticipant(participant_id) {
      if (!this.tournament_id || !participant_id ) {
        return
      }
      this.error = '';
      this.updating = true;
      axios
      .post('/api/tournament/participant/delete', {participant_id: participant_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    searchPlayer() {
      this.searching = true;
      this.fetchedPlayers = [];
      axios
      .get('/api/player/search', { params: { search: this.searchString } })
      .then(response => (this.fetchedPlayers = response.data))
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.searching = false)
    },
    searchPlayerNew(str) {
      this.player_search_list = [];
      axios
      .get('/api/player/search', { params: { search: str } })
      .then(response => (this.player_search_list = response.data))
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.player_searching = false)
    },
    searchPlayerInput(str) {
      this.player_search_str = str;
      this.player_search_list = [];
      if (str.length < 3) {
        if (this.player_search_timeout) {
          clearTimeout(this.player_search_timeout);
          this.player_search_timeout = null;
        }
        this.player_searching = false;
        return
      }
      if (this.player_search_timeout) {
        clearTimeout(this.player_search_timeout);
        this.player_search_timeout = null;
      }
      this.player_search_timeout = setTimeout(() => {this.searchPlayerNew(str)}, 1000);
      this.player_searching = true;
    },
    createPlayer() {
      if (!this.newPlayer.full_name) {
        this.error = 'Полное имя необходимо для создания';
        return
      }
      this.error = '';
      this.creatingPlayer = true;
      axios
      .post('/api/player/create', this.newPlayer, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        let player = response.data[0];
        if (!this.tournament_team) {
          this.addParticipant(player.id);
        } else {
          this.addTeamMember(player.id);
          this.add_team_player_tab = 'search';
        }
        this.newPlayer =  {
          full_name: '',
          birth_year: '',
          birth_month: '',
          birth_day: '',
          rank: '',
          city: ''
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.creatingPlayer = false)
    },
    createTeam() {
      this.create_team_error = null;
      if (!this.create_team_name || this.create_team_name.includes(`'`)) {
        this.create_team_error = 'Неправильное название команды';
        return
      }
      this.error = '';
      this.creating_team = true;
      axios
      .post('/api/tournament/team/add', {tournament_id: this.tournament_id, team_name: this.create_team_name}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.teams = response.data.teams;
        this.participants = response.data.participants;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => {
        this.creating_team = false;
        this.create_team_dialog = false;
      })
    },
    removeTeam(team) {
      if (!this.tournament_id) {
        console.log('tournament_id is not defined');
        return
      }
      if (!team) {
        console.log('team is not defined');
        return
      }
      this.error = '';
      team.deleting = true;
      axios
      .post('/api/tournament/team/delete', {tournament_id: this.tournament_id, team_id: team.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data.participants;
        this.teams = response.data.teams;
        this.team_members = response.data.team_members;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
        team.deleting = false;
      })
    },
    addTeamMember(player_id) {
      if (!this.tournament_id) {
        console.log('tournament_id is not defined');
        return
      }
      if (!player_id ) {
        console.log('player_id is not defined');
        return
      }
      if (!this.add_team_player_team) {
        console.log('No team defined where to add player');
        return;
      }
      this.error = '';
      this.add_team_player_progress = true;
      axios
      .post('/api/tournament/team/member/add', {tournament_id: this.tournament_id, player_id: player_id, team_id: this.add_team_player_team.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.team_members = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.add_team_player_progress = false)
    },
    removeTeamMember(member) {
      if (!this.tournament_id) {
        console.log('tournament_id is not defined');
        return
      }
      if (!member) {
        console.log('player is not defined');
        return
      }
      this.error = '';
      member.deleting = true;
      axios
      .post('/api/tournament/team/member/delete', {tournament_id: this.tournament_id, member_id: member.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.team_members = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
        member.deleting = false;
      })
    },
    playerUpdate(participant) {
      if (!this.tournament_id || !participant ) {
        return
      }
      this.error = '';
      this.updating = true;
      axios
      .post('/api/player/edit', {player: {id: participant.player_id, city: participant.city, region_id: participant.region_id}}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        participant.city = response.data.city;
        participant.region_id = response.data.region_id;
        participant.region = response.data.region;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    participantUpdate(participant) {
      this.error = '';
      if (participant.comment != participant.comment_temp) participant.comment = participant.comment_temp
      if (!participant.comment) participant.comment = '';
      axios
      .post('/api/tournament/participant/edit', {participant_id: participant.id, comment: participant.comment, lot: participant.lot}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
    },
    lotParticipants() {
      if (this.updating) return;
      this.error = '';
      this.updating = true;
      axios
      .post('/api/tournament/participant/lot', {tournament_id: this.tournament_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    clearLots() {
      if (this.updating) return;
      this.error = '';
      this.updating = true;
      axios
      .post('/api/tournament/participant/lot/clear', {tournament_id: this.tournament_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    handicapUpdate(participant) {
      this.error = '';
      participant.handicap_updating = true;
      if (participant.handicap_participant_id) {
        axios
        .post('/api/handicap/edit/participant', {id: participant.handicap_participant_id, group: participant.group}, {headers: {'x-access-token': localStorage.token}})
        .then(() => {})
        .catch(error => {
          participant.group = null;
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          }
        })
        .finally(() => participant.handicap_updating = false)
      } else {
        axios
        .post('/api/handicap/add/participant', {id: this.handicap_id, player_id: participant.player_id, group: participant.group}, {headers: {'x-access-token': localStorage.token}})
        .then(response => {
          participant.handicap_participant_id = response.data.id;
        })
        .catch(error => {
          participant.group = null;
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          }
        })
        .finally(() => participant.handicap_updating = false)
      }
    },
    actionPanelToggle(panel) {
      if (this.actionPanel == panel) {
        this.actionPanel = null;
      } else {
        this.actionPanel = panel;
      }
      if (this.actionPanel == 'createPlayer' && !this.countries.length) {
        this.getCountries();
      }
    },
    participantLotChange(participant) {
      this.error = '';
      if (!participant.lot) {
        participant.lot = null;
      }
      if (participant.lot != null && (participant.lot > this.participants.length || participant.lot < 1)) {
        this.error = `жребий должен быть от 1 до ${this.participants.length}`;
        participant.lot = null;
        return
      }
      this.participants.forEach(element => {
        if (element.id == participant.id) return
        if (element.lot && element.lot == participant.lot) {
          this.error = `такой же жребий у ${element.name}`;
          participant.lot = null;
          return;
        }
      });
      if (!this.error) {
        this.participantUpdate(participant);
      }
    },
    dynamicSort(property, ascending) {
      return function (a,b) {
        if (!isNaN(parseInt(a[property],10)) && !isNaN(parseInt(b[property],10)) && !a[property].toString().includes('-') && !b[property].toString().includes('-')
        && !a[property].toString().includes('%') && !b[property].toString().includes('%')) {
          a[property] = parseInt(a[property],10);
          b[property] = parseInt(b[property],10);
        }
        if (ascending){
          if (a[property] == null) return 1
          if (b[property] == null) return -1
          if (property == 'place') {
            return (parseInt(a[property].toString().split('-')[0],10) > parseInt(b[property].toString().split('-')[0],10)) ? 1 : -1;
          } else if (property == 'rating_efficiency') {
            return (parseInt(a[property],10) > parseInt(b[property],10)) ? 1 : -1;
          } else {
            return (a[property] > b[property]) ? 1 : -1;
          }
        } else {
          if (a[property] == null) return -1
          if (b[property] == null) return 1
          if (property == 'place') {
            return (parseInt(a[property].toString().split('-')[0],10) < parseInt(b[property].toString().split('-')[0],10)) ? 1 : -1;
          } else if (property == 'rating_efficiency') {
            return (parseInt(a[property],10) < parseInt(b[property],10)) ? 1 : -1;
          } else {
            return (a[property] < b[property]) ? 1 : -1;
          }
        }
      }
    },
    headerClick(property) {
      if (this.previousParticipantsSort == this.participantsSort) this.previousParticipantsSort = null;
      else this.previousParticipantsSort = this.participantsSort;
      this.participantsSort = property;
      localStorage.participantsSort = this.participantsSort;
    },
    ratingHeaderClick(property) {
      if (this.previousRatingParticipantsSort == this.ratingParticipantsSort) this.previousRatingParticipantsSort = null;
      else this.previousRatingParticipantsSort = this.ratingParticipantsSort;
      this.ratingParticipantsSort = property;
    },
    isDeleteCapable(participant) {
      if (!this.games.length) return true
      for (let i=0; i<this.games.length; i++) {
        if (this.games[i].status != 'finished' && this.games[i].status != 'running') continue;
        if ((this.games[i].player1 == participant.id && parseInt(this.games[i].player2,10)!=0) || (this.games[i].player2 == participant.id && parseInt(this.games[i].player1,10)!=0)) {
          return false
        }
      }
      return true
    },
    regionChanged(participant) {
      if (participant.region) {
        participant.region_id = participant.region.id;
        participant.region = participant.region.name;
      } else {
        participant.region_id = null;
        participant.region = null;
      }
      this.playerUpdate(participant);
    },
    searchClicked() {
      this.searchingParticipant = !this.searchingParticipant;
      if (this.searchingParticipant) {
        let input = document.getElementById('search-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    searchRatingClicked() {
      this.searchingRatingParticipant = !this.searchingRatingParticipant;
      if (this.searchingRatingParticipant) {
        let input = document.getElementById('search-rating-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    birthDateChange(date) {
      this.newPlayer.birth_year = date.getFullYear();
      this.newPlayer.birth_month = date.getMonth() + 1;
      this.newPlayer.birth_day = date.getDate();
    },
    countryChanged() {
      if (this.newPlayer.country.id) {
        this.newPlayer.country_id = this.newPlayer.country.id;
      } else {
        this.newPlayer.country_id = null;
      }
    },
    formatBirthDate(player) {
      let birth_str = "";
      if (player.birth_day != null) {
        let birth_day_str = (player.birth_day.toString().length == 1) ? '0' + player.birth_day : player.birth_day;
        birth_str+=birth_day_str + '.'
      }
      if (player.birth_month != null) {
        let birth_month_str = (player.birth_month.toString().length == 1) ? '0' + player.birth_month : player.birth_month;
        birth_str+=birth_month_str + '.';
      }
      if (player.birth_year != null) birth_str+=player.birth_year;
      return birth_str;
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    renameTeam(team) {
      if (!team.name) return
      this.error = '';
      team.rename_running = true;
      axios
      .post('/api/tournament/team/rename', {tournament_id: this.tournament_id, team_id: team.id, team_name: team.name}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        team.name = response.data.name;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => {
        team.rename_running = false;
        team.rename_team = false;
      })
    },
    teamGroup(team_id) {
      if (!team_id) return null;
      let group_sum = 0;
      if (!this.players_by_team[team_id] || !this.players_by_team[team_id].length) return null;
      this.players_by_team[team_id].forEach(player => {
        if (!player.group) return null;
        group_sum += player.group;
      });
      // return Math.round(group_sum / this.players_by_team[team_id].length);
      return group_sum;
    },
    getFilterSettings() {
      if (localStorage.filter_settings) {
        this.filter_settings = JSON.parse(localStorage.filter_settings);
      }
    },
    saveFilterSettings() {
      localStorage.filter_settings = JSON.stringify(this.filter_settings);
    }
  },
  created() {
    this.getDetails();
    this.getRegions();
    this.getFilterSettings();
    if (localStorage.participantsSort) {
      this.participantsSort = localStorage.participantsSort;
    }
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<style scoped>
#participants-div {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-size: 13px;
}
#participants-div input {
  font-size: 13px;
  border: 1px solid #9d9d9d;
  border-radius: 7px;
  text-align: center;
}
#participants-div input::-webkit-outer-spin-button,
#participants-div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#participants-div input[type=number] {
  -moz-appearance: textfield;
}
th.with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
th button {
  margin: 0;
  padding: 0;
  background: inherit;
  color: inherit;
  border: none;
}
td input {
  width: 100%;
  box-sizing: border-box;
}
td button {
  border: none;
  margin: 0;
  color: black;
  padding: 0;
}
td button:hover {
  color: #248d24;
  background: inherit;
}
td button:disabled {
  color: rgb(119, 119, 119);
  background: inherit;
}
.action-panels {
  margin-bottom: 20px;
}
.action-panel label {
  color: rgb(109, 109, 109);
  margin: 0px 10px;
}
#add-player-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#lots-div {
  display: flex;
  flex-direction: row;
}
#search-content {
  width: 100%;
}

table .id-column {
  width: 20px;
}
table .delete-column {
  width: 13px;
}
.v-select {
  font-size: 12px;
}
.list-settings {
  display: flex;
  margin: 0 10px;
  align-items: center;
  margin-bottom: 5px;
}
.list-settings .right-settings {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.right-settings > a {
  margin: 5px;
}
.right-settings #clear-lots {
  position: relative;
  width: 22px;
  height: 22px;
}
.right-settings #clear-lots > i {
  position: absolute;
}
#clear-lots .fa-ban {
  font-size: 22px;
  color: #ff6060;
  z-index: 2;
}
#clear-lots .fa-dice {
  top: 4px;
  left: 3px;
  font-size: 12px;
}
#clear-lots .fa-spinner {
  top: 4px;
  left: 3px;
}
.sort-setting, .select-setting {
  position: relative;
  display: flex;
  margin: 0 10px;
}
.sort-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  z-index: 100;
  right: 0;
  top: 20px;
  padding: 10px;
}
.sort-options label {
  margin: 5px 5px;
  text-align: center;
  margin-bottom: 10px;
}
.search-setting {
  display: flex;
}
.search-button {
  margin: 5px;
}
.search-string {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
}
.search-string input {
  border: none !important;
  height: 100%;
  padding: 0;
  text-align: center;
  width: 200px;
}
.search-string input:focus {
  outline: none;
}
.search-clear-button {
  margin: 5px;
  align-self: center;
}
.participants-list {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  flex-direction: column;
  margin: 10px;
  overflow: visible;
}
.participant-cell {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-bottom: 1px solid #ccc;
}
.participant-cell.last {
  border-bottom: none;
}
.participant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.participant-header h4 {
  margin: 0;
}
.participant-header i {
  margin-right: 5px;
}
.participant-attrs {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}
.participant-attrs > * {
  margin: 4px;
}
.participant-rank {
  display: flex;
  justify-content: center;
  color: #694000;
  margin-top: -5px;
}
.participant-handicap {
  display: flex;
  color: #1517ac;
  justify-content: center
}
.participant-handicap input {
  width: 25px;
  text-align: center;
}
.participant-rating {
  display: flex;
  flex-direction: column;
  border: 1px solid #002c00;
  border-radius: 10px;
  background: #f3f9f3;
}
.participant-rating > h4 {
  color: #005200;
  margin: 0;
  text-align: center;
  font-size: 12px;
}
.participant-rating span {
  color: #002900;
}
.participant-rating-attrs {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 10px;
  justify-content: space-between;
}
.participant-location {
  display: flex;
  justify-content: space-between;
  color: #767676;
}
.participant-location > div {
  display: flex;
  flex-direction: column;
}
.participant-location .city {
  text-align: right;
}
.participant-group {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #226222;
}

.participant-result {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #226222;
}
.participant-comment {
  display: table;
}
.participant-comment label {
  display: table-cell;
  width: 85px;
}
.participant-comment input {
  display: table-cell;
  width: 100%;
  text-align: center;
}
.name_plus_delete_div {
  display: flex;
}
.name_plus_delete_div button {
  border: none;
  margin: 0;
  color: #e10808;
  padding: 0;
  font-size: 12px;
  margin-left: 10px;
}
.name_plus_delete_div button:hover {
  color: #e77a7a;
  background: inherit;
}
.name_plus_delete_div button:disabled {
  color: rgb(119, 119, 119);
  background: inherit;
}
.datepicker {
  align-self: start;
}
.name-cell {
  display: flex;
}
.list-control {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.list-control a {
  font-size: 13px;
  text-decoration: underline;
  color: #000091;
}
.list-control select {
  margin: 0;
  font-size: 12px;
}
#mobile-search-content {
  width: 100%;
}
.searched-player {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.searched-player.last {
  border: none;
}
.searched-player-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 8px;
}
.searched-player-name {
  align-self: center;
  margin: 10px;
}
a.add-button {
  align-self: center;
  font-size: 20px;
}
.rating-player {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.rating-player.last {
  border: none;
}
.rating-player-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 8px;
}
.rating-player-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.rating-player-name {
  align-self: center;
  margin: 10px;
}
.actions {
  padding: 0;
}
h5 {
  font-size: 17px;
  color: #0a3d0a;
  margin: 5px;
}
.add-button {
  align-self: flex-start !important;
  margin: 10px;
}
.teams-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  margin: 3px;
  overflow: hidden;
}
.team-line {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
}
.team-line.last {
  border-bottom: none;
}
.team-line-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f2f2f2;
}
.team-header-part, .team-member-part {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.team-index {
  display: flex;
  align-items: center;
  font-size: 15px;
  border-right: 1px solid #ccc;
  border-radius: 0 0 10px 0;
  margin-top: 0px;
  margin-right: 10px;
  padding: 5px;
  background-color: #062302;
  color: white;
  align-self: stretch;
  text-align: center;
}
.team-name {
  font-size: 13px;
  color: #034f03;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
}
.team-info {
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 0;
}
.team-rename {
  margin-left: 10px;
}
.team-rename:hover {
  color: #068d06;
}
.team-line-header .fa-spinner {
  margin-left: 10px;
}
.team-info > label, .member-info label {
  color: #565656;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px 5px;
  margin: 3px;
}
.lot-label, .handicap-label {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 5px !important;
}
.lot-label > input, .handicap-label > input {
  margin-left: 3px;
  width: 25px;
  border-radius: 5px !important;
}
.comment-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1px 0px 0px 0px !important;
}
.comment-label.pc {
  flex-direction: row;
  padding: 0px 0px 0px 5px !important;
}
.comment-label > input {
  border-radius: 5px !important;
}
.comment-label.pc > input {
  margin-left: 3px;
}

.team-actions, .member-actions {
  margin-right: 6px;
}
.team-actions > a, .member-actions > a {
  margin: 0 5px;
  font-size: 16px;
}
.team-member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border-bottom: 1px solid #ddd;
}
.team-member.last {
  border-bottom: none;
}
.team-member-add-button {
  color:#248d24;
}
.team-member-add-button:hover {
  color:#1ebc1e;
}
.team-member-delete-button {
  color: #c30000;
}
.team-member-delete-button:hover {
  color: #da4040;
}
.team-line-content {
  display: flex;
  margin: 10px;
  flex-direction: column;
}
.member-name {
  text-align: left;
}
.member-name > a {
  margin-left: 10px;
}
.member-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.member-info .handicap-input {
  width: 20px;
  text-align: center;
}
.member-info .handicap-input::-webkit-outer-spin-button,
.member-info .handicap-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.member-info .handicap-input[type=number] {
  -moz-appearance: textfield;
}

.player-search-div {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
  align-self: stretch;
}
.player-search-div.mobile {
  width: auto;
  align-self: stretch;
}
.player-search-div.expanded {
  border-radius: 10px 10px 0 0;
}
.player-search-div > i {
  font-size: 13px;
  margin: 5px;
}
.player-search-div > input {
  outline: none;
  border: none;
  flex: 1;
  font-size: 13px;
  margin: 0;
}
.player-search-div > label {
  font-size: 13px;
  margin-left: auto;
  font-weight: bold;
}
.player-search-div > button {
  font-size: 13px;
  padding: 5px;
  border: none;
  margin: 0px;
  margin-left: auto;
  font-weight: bold;
  color: #5f5d5d;
  background-color: inherit;
}
.player-search-div > button:hover {
  background-color: inherit;
  color: #9b0606;
}
.player-search-div .players-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 200;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  max-height: 400px;
  top: 36px;
  left: -1px;
  width: 100%;
  transition: height .4s ease-in-out;
}
.player-search-div .players-list a {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
}
.player-search-div .players-list a:hover {
  background-color: #ececec;
}
.player-create-div {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.player-create-div > label {
  color: #727272;
}
.player-create-div > .datepicker, .player-create-div > .v-select {
  margin: 10px;
}
.player-rating-div {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
}
.player-rating-list {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: auto;
}
.rating-player {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
}
.rating-player:hover {
  background-color: #ececec;
}
#filter-settings-container {
  position: relative;
}
#filter-settings-dropdown{
  position: absolute;
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  background: #f2f2f2;
  right: 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  z-index: 100
}
#filter-settings-dropdown > * {
  margin: 5px 5px;
  text-align: left;
  margin-bottom: 10px;
}
</style>