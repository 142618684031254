<template>
  <TopMenu v-if="!(this.$route.path.startsWith('/game') || this.$route.path.startsWith('/plashka') || this.hideMenu || this.region=='KG')" :notifications="notifications"/>
  <div id='v-app' :class="{'mobile': isMobile}" v-if="this.region!='KG'">
    <LeftMenu v-if="!isMobile && !(this.$route.path.startsWith('/game') || this.$route.path.startsWith('/plashka') || this.hideMenu)"/>
    <div :id="this.$route.path.startsWith('/plashka') ? 'plashka' : 'main'">
      <router-view @notifications-update="notificationsUpdate" @notification-update="notificationUpdate" @login="onLogin"></router-view>
    </div>
  </div>
  <div class="popup-list" v-if="this.role == 'operator' && this.region!='KG'">
    <transition name="pop" v-for="notification in notifications" :key="notification.id" appear>
      <div class="notification popup" :class="[notification.type]" v-show="notification.popup">
        <div class="notification-content">
          <div class="notification-icon" :class="notification.type">
            <i class="fa-solid fa-circle-info" v-if="notification.type=='info'"></i>
            <i class="fa-solid fa-triangle-exclamation" v-if="notification.type=='warning'"></i>
          </div>
          <div class="notification-message">
            <pre>{{notification.message}}</pre>
          </div>
          <div class="notification-close">
            <i class="fa-solid fa-xmark" @click="notification.popup = false"></i>
          </div> 
        </div>
        <button class="notification-ok" @click="readNotification(notification)">Понятно</button>
      </div>
    </transition> 
  </div>
</template>

<script>
import LeftMenu from './components/LeftMenu.vue'
import TopMenu from './components/TopMenu.vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    LeftMenu,
    TopMenu
  },
  data: function() {
    return {
      hideMenu: false,
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      notifications: [],
      websocket: null,
      websocketRetryCount: 0,
      region: null
    }
  },
  watch:{
    $route (to, from){
      if (from.query.menu && !to.query.menu) {
        this.$router.replace({path: to.path, query: from.query})
      }
      if (to.query.menu == 'hide') this.hideMenu = true;
      else this.hideMenu = false;
    }
  },
  computed: {
    isMobile() {
      return screen.width <= 1000;
    },
    isOperator() {
      return this.role == 'operator';
    }
  },
  methods: {
    listNotifications() {
      this.notifications = [];
      axios
      .get('/api/account/notification/list', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        response.data.forEach(notification => notification.popup = !notification.read)
        this.notifications = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
        }
      })
    },
    notificationsUpdate(value) {
      this.notifications = value;
      this.notifications.forEach(notification => notification.popup = !notification.read)
    },
    notificationUpdate(notification) {
      for (let i=0; i<this.notifications.length; i++) {
        if (this.notifications[i].id == notification.id) {
          this.notifications[i] = notification;
          this.notifications[i].popup = !this.notifications[i].read;
          break;
        }
      }
    },
    async socketOnMessage(event) {
      let event_message = JSON.parse(await event.data.text());
      if (event_message.type == "new_notification") {
        this.notifications.push(event_message.data);
        this.notifications[this.notifications.length - 1].popup = !this.notifications[this.notifications.length - 1].read;
      } else if (event_message.type == "edit_notification") {
        for (let i=0; i<this.notifications.length; i++) {
          if (this.notifications[i].id == event_message.data.id) {
            this.notifications[i] = event_message.data;
            this.notifications[i].popup = !this.notifications[i].read;
            break;
          }
        }
      } else if (event_message.type == "delete_notification") {
        for (let i=0; i<this.notifications.length; i++) {
          if (this.notifications[i].id == event_message.data.id) {
            this.notifications.splice(i,1);
            break;
          }
        }
      }
    },
    async socketOnClose() {
      console.log('socket closed');
      this.websocketRetryCount++;
      if (this.websocketRetryCount > 3) return
      document.cookie = `x-access-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
      this.setupSocket();
    },
    socketOnOpen() {
      console.log('socket opened')
      document.cookie = `x-access-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
      this.websocketRetryCount = 0;
    },
    setupSocket() {
      if (location.host != '127.0.0.1:8080') {
        document.cookie = `x-access-token=${localStorage.token};`
        this.websocket = new WebSocket(`wss://${location.host}/websocket`);
        this.websocket.onmessage = this.socketOnMessage;
        this.websocket.onclose = this.socketOnClose;
        this.websocket.onopen = this.socketOnOpen;
      }
    },
    readNotification(notification) {
      notification.popup = false;
      notification.read = true;
      axios .post('/api/account/notification/read', {id: notification.id}, {headers: {'x-access-token': localStorage.token}})
    },
    onLogin() {
      if (this.role == 'operator') {
        this.listNotifications();
        this.setupSocket();
      }
    }
  },
  async mounted() {
    this.region = localStorage.region;
    if (this.isOperator) {
      this.listNotifications();
      this.setupSocket();
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#v-app {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 0;
}
div#main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
}
#plashka {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: black;
  color: #ffffff;
  width: auto;
  align-self: flex-start;
}
#content,#admin-content {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: stretch;
  overflow: visible;
  margin: 10px;
}
#loader {
  color: #248d24;
  width: 100%;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
div.search-div {
  display: flex;
  align-items: center;
}
div.search-div i {
  margin-right: 5px;
}
div.search-div input {
  width: 200px;
  height: 30px;
  padding: 0;
  padding-left: 5px;
}
button {
  background-color: #ffffff;
  color: rgb(47 129 48);
  border: 1px solid rgb(47 129 48);
  border-radius: 10px;
  margin: 10px;
  padding: 7px;
}
button:hover {
  background-color: rgb(47 129 48);
  color: #ffffff;
}
button:disabled {
  background-color: #ddd;
  color: black;
}
table:not(.custom) {
  width: 100%;
  border-collapse: collapse;
}
table:not(.custom) th, table td {
  border: 1px solid #ddd;
}
table:not(.custom) th {
  background-color: rgb(52, 51, 58);
  color: aliceblue;
  padding: 10px;
  text-align: left;
  font-weight: normal;
}
table:not(.custom) td {
  padding: 10px;
  padding-left: 10px;
}
table:not(.custom) td.not-found {text-align: center;}
table:not(.custom) tr:nth-child(even){background-color: #f2f2f2;}
table:not(.custom) tr:not(.not-found):hover {background-color: rgb(230, 228, 228); color: black;}
table:not(.custom) tr.selected {
  background-color: rgb(93 113 172);
  color: #ffffff;
}
ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 0
}
li > a {
  display: block;
  padding: 7px;
  text-decoration: none;
  color: inherit;
}
ul li:nth-child(even){background-color: #f2f2f2;}
ul li:hover {background-color: #ddd; color: black;}
.tabs {
  display: flex;
  align-self: stretch;
  background: #e1e5e2;
  overflow: visible;
  justify-content: stretch;
}
.tabs a {
  padding: 10px 10px;
  text-decoration: none;
  color: #34333a;
  font-size: 15px;
  border: none;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  font-family: Trebuchet MS,Lucida Sans Unicode,Lucida Grande,Lucida Sans,Arial,sans-serif;
}
.tabs a:hover {
  color: #349e33;
}
.tabs a.active {
  color: #349e33;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid #349e33;
}
.vl {
  border-left: 1px solid #8b8b8b;
  align-self: stretch;
  margin-left: 10px;
  margin-right: 10px;
}
.error {
  color: red !important; 
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.success {
  color: green;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
button.warning {
  background-color: #ffffff;
  color: red;
  border: 1px solid red;
}
button.warning:hover {
  background-color: red;
  color: #ffffff;
}
.actions {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 10px 20px;
}
.actions .warning {
  margin-left: auto;
}
.actions-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.actions a {
  margin: 10px;
  font-size: 20px;
}
.actions a:hover {
  color: #248d24;
}
.actions .delete-button {
  margin-left: auto;
  color: #e10808;
}
.actions .delete-button:hover {
  color: #e77a7a;
}
.action-tabs {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  overflow: hidden;
}
.action-tabs-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  align-self: stretch;
  background: #f2f2f2;
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
}
.action-tabs-header.collapsed {
  border-bottom: none;
}
.action-tabs-header button {
  margin: 0;
  border-radius: 0;
  padding: 10px;
  border: none;
  background: inherit;
  color: inherit;
}
.action-tabs-header button:hover {
  color: #ffffff;
  background: #248d24;
}
.action-tabs-header button.active {
  color: #ffffff;
  background: #248d24;
}
.action-panels {
  margin: 10px;
}
.action-panel {
  display: flex;
  flex-direction: column;
  overflow: auto
}
.action-panel button {
  align-self: center;
}
.action-panel > input, 
.action-panel select,
.action-panel .datepicker,
.action-panel .v-select {
  padding: 3px;
  margin: 10px;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
button.vs__clear {
  margin: 0;
  margin-right: 8px;
}
h3 {
  margin: 10px;
  font-size: 15px;
  text-align: center;
  align-self: flex-start;
}
#name-div {
  display: flex;
  align-items: center;
  margin: 5px;
  align-self: stretch;
  flex-wrap: wrap;
}
#name-div a {
  font-size: 15px;
  text-decoration: none;
  color: #445142;
  padding: 3px;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 0;
  background: inherit;
  border-radius: 5px;
}
#name-div a:hover {
  color: #248d24;
}
#name-div i {
  margin: 5px;
}
#name-div input {
  width: 100%;
}
.more-button {
  align-self: center;
}
a.add-button {
  font-size: 30px;
  color: #248d24;
}
a.add-button:hover {
  color: #84dc84;
}
.create-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  padding: 10px;
  max-width: 500px;
  width: 90%;
}
.create-dialog > * {
  margin: 5px;
}
.create-dialog > label {
  margin-bottom: 0;
  color: rgb(111, 111, 111);
  align-self: flex-start;
}
.create-dialog > input {
  align-self: stretch;
}
.create-dialog .actions {
  align-self: stretch;
  justify-content: space-evenly;
}
.create-dialog .error {
  width: 100%;
}
.label-with-edit {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  margin-bottom: 5px;
}
.label-with-edit a {
  font-size: 12px;
  margin-left: 10px;
  text-decoration: none;
  color: #445142;
}
.label-with-edit a:hover {
  color: #248d24;
}
.player-link {
  text-decoration: none;
  color: inherit;
}
.fa-youtube {
  color: #f00;
}
.fa-youtube:hover {
  color: rgb(255, 78, 78);
}
.v-select .dropdown-menu {
  display:block;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(52 52 52 / 40%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  box-shadow: 0 0 10px #1e1e1e;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  max-height: 80%;
}
.modal-content .modal-header {
  display: flex;
  align-self: flex-end;
  color: black;
}
.modal-content > label {
  margin: 20px;
  text-align: center;
  font-size: 18px;
  font-family: sans-serif;
  color: #0b0b39;
}
.modal-content input, .modal-content select, .modal-content textarea {
  margin: 10px;
  align-self: stretch;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #CCC;
  outline: none;
}
.modal-content textarea {
  min-width: 300px;
}
.modal-content span {
  align-self: stretch;
  margin-left: 10px;
  color: #555555;
}
.modal-content > .modal-row {
  align-self: start;
}
.modal-buttons {
  display: flex;
  justify-content: center;
}
.modal-buttons span {
  margin: 0;
}
.modal-tabs {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 10px;
  margin-top: 0;
}
.modal-tabs > button {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 3px;
  color:#0e7c0d;
}
.modal-tabs > button:hover, .modal-tabs > button.active {
  border-bottom: 3px solid rgb(2, 102, 2);
  background: white;
  color:#0e7c0d;
}

.info-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  flex-direction: column;
  margin: 5px;
  overflow: visible;
  align-self: stretch;
}
.info-container-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  align-self: stretch;
  padding: 10px;
  background: #f2f2f2;
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.info-container-content {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 10px;
  min-width: 300px;
}
.info-container-content > a {
  text-decoration: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
}
.info-container-content > a:hover {
  background: rgb(226, 226, 226);
}
.info-container-content > a.last {
  border: none;
}
.info-container-content .name {
  color: rgb(5, 10, 6);
  font-size: 16px;
  margin: 3px;
  font-weight: bold;
  text-align: center;
}

.notification-container {
  display: flex;
}
.notification {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 10px;
  min-width: 300px;
  overflow: hidden;
  max-width: 600px;
  flex: 1;
}
.notification.popup {
  box-shadow: 0 0 5px #ccc;
  align-self: flex-end;
}
.notification.info {
  border: 1px solid #005b00;
  background-color: #ebffeb;
}
.notification.warning {
  border: 1px solid orange;
  background-color: #faefba;
}
.notification-content {
  display: flex;
}
.notification-icon {
  padding: 15px;
  align-self: center;
}
.fa-circle-info {
  color: #005b00;
}
.fa-triangle-exclamation {
  color: orange;
}
.notification-message {
  padding: 10px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-message > pre { 
  margin: 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.popup-list {
  position: absolute;
  right: 5px;
  top: 53px;
  display: flex;
  flex-direction: column;
  transition: top 0.5s;
}
.notification-close {
  margin: 10px;
}
.notification-ok {
  align-self: center;
}
.pop-enter-active,
.pop-leave-active {
  transition: 1s;
}
.pop-enter-from,
.pop-leave-to {
  transform: translate(150%, 0);
}
.chart-notifications {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: auto;
}
.chart-notification {
  border: 1px solid red;
  background: rgb(255 239 239);
  border-radius: 8px;
  padding: 10px;
  margin: 2px;
  font-family: Trebuchet MS,Lucida Sans Unicode,Lucida Grande,Lucida Sans,Arial,sans-serif;
}
.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
.gear_setting_container {
  position: relative;
}
a.gear_settings_expand_button {
  padding: 5px !important;
  background: inherit !important;
  color: #026002 !important;
  margin: 0 !important;
  margin-left: auto !important;
  display: flex;
}
a.gear_settings_expand_button > i {
  margin: 0 !important;
}
a.gear_settings_expand_button.expanded {
  color: white !important;
  background: rgb(59 59 59) !important;
  border-radius: 0 !important;
}
.fa-gear {
  font-size: 20px;
  color: inherit;
}
.gear_settings_expand {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: rgb(59 59 59);
  align-items: stretch;
  z-index: 1000;
  right: 0px;
  top: 30px;
}
.gear_settings_expand > button {
  border: none;
  background: inherit;
  color: white;
  margin: 0;
  border-radius: 0;
  padding: 20px;
  font-family: Trebuchet MS,Lucida Sans Unicode,Lucida Grande,Lucida Sans,Arial,sans-serif;
}
.gear_settings_expand > button:hover {
  background: rgb(90, 90, 90)
}
</style>
