<template>
  <div id="top-bar">
    <div class="drop-down-menu left" v-click-outside="() => top_menu_show = false" v-if="isMobile">
      <a id="top-menu-button" @click="top_menu_show = !top_menu_show" :class="{ active: top_menu_show }">
        <i class="fa-solid fa-bars"></i>
      </a>
      <div class="drop-down-items left" v-show="top_menu_show">
        <router-link to='/players' class="left-menu-item" @click="top_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/players') }">
          Игроки
        </router-link>
        <router-link to='/tournaments' class="left-menu-item" @click="top_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/tournaments') }">
          Турниры
        </router-link>
        <router-link to='/ratings' class="left-menu-item" @click="top_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/ratings') }">
          Рейтинги
        </router-link>
        <router-link to='/handicaps' class="left-menu-item" @click="top_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/handicaps') }">
          Гандикапы
        </router-link>
        <router-link to='/clubs' class="left-menu-item" @click="top_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/clubs') }">
          Клубы
        </router-link>
      </div>
    </div>
    <div id="logo">
      <span class="green">Fair</span>
      <span>Play</span>
    </div>
    <div id="right-container" v-if="username == 'guest'" :class="{mobile: isMobile}">
      <a id="support" @click="support_modal = true">
        <i class="fa-regular fa-circle-question"></i>
      </a>
      <router-link to='/register' class="left-menu-item" @click="account_menu_show = false" title="Регистрация" v-if="!isMobile && username == 'guest'"
        :class="{ desktopregister: !isMobile }">
        <label>Регистрация</label>
      </router-link>
      <router-link to='/login' class="left-menu-item" @click="account_menu_show = false" title="Войти"
        :class="{ active: this.$route.path.startsWith('/login'), desktoplogin: !isMobile }" v-if="username == 'guest'">
        <i class="fa-solid fa-user" v-if="isMobile"></i>
        <label v-else>Войти</label>
      </router-link>
    </div>
    <div id="account-drop-down" v-click-outside="() => account_menu_show = false" v-else>
      <a id="support" @click="support_modal = true">
        <i class="fa-regular fa-circle-question"></i>
      </a>
      <router-link to='/admin/notifications' id="notifications">
        <i class="fa-regular fa-envelope"></i>
        <i v-show="newNotificationsCount" class="notifications-count">{{ newNotificationsCount }}</i>
      </router-link>
      <a class='drop-down-menu' @click="account_menu_show = !account_menu_show" :class="{ active: account_menu_show, mobile: isMobile }">
        <i class="fa-solid user-icon" :class="user_icon"></i>
        <label v-if="!isMobile">{{username}}</label>
        <i v-if="!isMobile && !account_menu_show" class="fa-solid fa-caret-down caret"></i>
        <i v-if="!isMobile && account_menu_show" class="fa-solid fa-caret-up caret"></i>
      </a>
      <div class='drop-down-items right' v-show="account_menu_show">
        <router-link to='/admin/info' class="left-menu-item" @click="account_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/admin/info') }">
          Профиль
        </router-link>
        <hr>
        <router-link to='/admin/accounts' class="left-menu-item" @click="account_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/admin/accounts') }" v-if="role == 'administrator'">
          Пользователи
        </router-link>
        <router-link to='/admin/tournaments' class="left-menu-item" @click="account_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/admin/tournaments') }">
          Мои турниры
        </router-link>
        <router-link to='/admin/ratings' class="left-menu-item" @click="account_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/admin/ratings') }">
          Мои рейтинги
        </router-link>
        <router-link to='/admin/handicaps' class="left-menu-item" @click="account_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/admin/handicaps') }">
          Мои гандикапы
        </router-link>
        <router-link to='/admin/regulations' class="left-menu-item" @click="account_menu_show = false"
          :class="{ active: this.$route.path.startsWith('/admin/regulations') }">
          Мои регламенты
        </router-link>
        <hr>
        <a class="left-menu-item" @click="account_menu_show = false; logout()">
          Выйти
        </a>
      </div>
    </div>
    <div class="modal" v-show="support_modal">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="support_modal=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>По всем вопросом, связанным с работой FairPlay, обращайтесь через WhatsApp по телефону +7(929)588-50-33.</label>
        <div class="modal-buttons">
          <button @click="openWhatsApp()">
            <span>Перейти в WhatsApp</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      get user_icon() {
        let result = 'fa-user';
        if (localStorage.role) {
          if (localStorage.role == 'administrator'){
            result = 'fa-user-astronaut';
          } else if (localStorage.role == 'operator') {
            result = 'fa-user-tie';
          }
        }
        return result;
      },
      top_menu_show: false,
      account_menu_show: false,
      support_modal: false,
    }
  },
  props: {
    notifications: Array
  },
  methods: {
    logout() {
      localStorage.removeItem('username');
      localStorage.removeItem('name');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      if (this.$route.path.startsWith('/admin')) this.$router.replace({ path: '/login' });
      else {
        window.location.reload();
      }
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    openWhatsApp() {
      window.open('https://api.whatsapp.com/send/?phone=79295885033&text&type=phone_number&app_absent=0', '_blank', 'noreferrer');
    }
  },
  computed: {
    isMobile() {
      return screen.width <= 1000;
    },
    isOperator() {
      return this.role == 'operator';
    },
    newNotificationsCount() {
      let newNotifications = this.groupArrayBy(this.notifications, 'read')['false'];
      if (!newNotifications) return 0;
      else return newNotifications.length;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#top-bar {
  width: 100%;
  display: flex;
  background-color: #021a06;
  color: aliceblue;
  justify-content: space-between;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  z-index: 2;
  position: relative;
}
div#logo {
  margin: 20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: xx-large;
  height: 49px;
  display: flex;
  align-items: center;
  margin: auto;
}
div#logo span.green {
  color: rgb(77, 187, 77);
}
#account-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  color: #ffffff;
}
#account-logo i {
  margin:10px;
}
#account-logo a {
  color: #ffffff;
  font-size: 20px;
}
#account-logo a:hover {
  color: rgb(77, 187, 77);
}
#account-drop-down {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top:6px;
}
#right-container {
  display: flex;
  margin: auto;
  color: inherit;
  background-color: inherit;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 20px;
  position: absolute;
  right: 0;
}
#right-container.mobile {
  top: 3px;
} 
#right-container > a {
  text-decoration: none;
  color: inherit;
  margin-left: 5px;
}
#right-container > a:hover {
  color: rgb(77, 187, 77);
}

.drop-down-menu {
  display: flex;
  padding: 10px;
  padding-left: 0;
  background-color: inherit;
  font-size: 20px;
}
.drop-down-menu.left {
  position: absolute;
  padding-left: 10px;
  top: 3px;
}
.drop-down-menu label {
  font-size: 15px;
}
.drop-down-menu .user-icon {
  font-size: 17px;
  margin: 0 10px;
}
.drop-down-menu .caret {
  font-size: 12px;
  margin: 0 10px;
}

#account-drop-down .drop-down-items {
  top: 44px;
}
.drop-down-menu.left .drop-down-items {
  top: 47px;
}
.drop-down-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #021a06;
  color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  top: 50px;
}
.drop-down-items.right {
  right: 0;
  font-size: 15px;
}
.drop-down-items.left {
  left: 0;
  font-size: 15px;
}
.drop-down-items a {
  text-decoration: none;
  color: inherit;
  margin: 10px;
}
.drop-down-items a:hover {
  color: rgb(77, 187, 77);
}
#top-bar #logo {
  font-size: 20px;
}
.drop-down-items hr {
  margin: 0;
  border-color: #21a521;
}
.desktoplogin {
  background: #30a030;
  color:white;
  border-radius: 10px;
  font-size: 13px;
  padding: 7px;
}
a.desktoplogin:hover {
  color: black !important
}
.desktoplogin label {
  color: inherit
}
.desktopregister {
  background: #3039a0;
  color:white;
  border-radius: 10px;
  font-size: 13px;
  padding: 7px;
}
a.desktopregister:hover {
  color: black !important
}
.desktopregister label {
  color: inherit
}
#support, #notifications {
  margin-right: 10px;
  font-size: 18px;
  text-decoration: none;
  color: white;
}
#support:hover, #notifications:hover {
  color: rgb(77, 187, 77);
}
#notifications {
  position: relative;
}
.notifications-count {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 10px;
  width: auto;
  height: auto;
  aspect-ratio: 1 / 1;
  top: -3px;
  right: -7px;
  height: 11px;
  padding: 1px 2px;
}
</style>
