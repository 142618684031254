<template>
  <span v-if="error" class="error">{{error}}</span>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <div id="content" v-if="isAdministrator" v-show="!loading">
    <a class='add-button' title="Добавить рейтинг" @click="create_notification_dialog = true" v-show="!create_notification_dialog"><i class="fa-solid fa-circle-plus"></i></a>
    <div class="notifications-list">
      <div class="notification-container" v-for="notification in notifications" :key="notification.id" >
        <div class="notification" :class="notification.type">
          <div class="notification-content">
            <div class="notification-icon" :class="notification.type">
              <i class="fa-solid fa-circle-info" v-if="notification.type=='info'"></i>
              <i class="fa-solid fa-triangle-exclamation" v-if="notification.type=='warning'"></i>
            </div>
            <div class="notification-message">
              <pre>{{notification.message}}</pre>
            </div>
            <div class="new_accounts" v-if="notification.new_account" title="Новый пользователь получит уведомление">new</div>
          </div>
          <div class="notification-accounts" v-if="role=='administrator'" v-show="notification.accounts && notification.accounts.length">
            <div class="notification-account" v-for="account in notification.accounts" :key="account.username" :class="{'read': account.read}">
              <label>{{ account.username }}</label>
              <i class="fa-regular fa-eye" v-if="account.read"></i>
              <i class="fa-regular fa-eye-slash" v-else></i>
            </div>
          </div>
        </div>
        <div class="notification-actions">
          <i class="fas fa-spinner fa-pulse" v-if="deleting_notification"></i>
          <i class="fa-solid fa-trash-can" title="Удалить уведомление" v-else @click="deleteNotification(notification)"></i>
          <i class="fas fa-spinner fa-pulse" v-if="editing_notification"></i>
          <i class="fa-solid fa-pen" title="Изменить уведомление" v-else @click="edit_notification = { ...notification }; edit_notification_dialog = true;"></i>
        </div>
      </div>
    </div>
    <div class="modal" v-if="create_notification_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="create_notification_dialog=false"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Новое уведомление` }}</label>
        <span>Тип</span>
        <div class="modal-row">
          <select v-model="create_notification_type">
            <option value="info">info</option>
            <option value="warning">warning</option>
          </select>
          <i class="fa-solid fa-circle-info" v-if="create_notification_type=='info'"></i>
          <i class="fa-solid fa-triangle-exclamation" v-if="create_notification_type=='warning'"></i>
        </div>
        <div class="modal-row">
          <input type="checkbox" v-model="create_notification_new_account">
          <span>Уведомлять новых пользователей</span>
        </div>
        <span>Сообщение</span>
        <textarea rows="3" v-model="create_notification_message"></textarea>
        <span>Кому отправить</span>
        <div id="loader" v-if="accounts_loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div class="accounts-list" v-else>
          <div class="role-container" v-for="role in roles" :key="role.role">
            <div class="account-row role">
              <input type="checkbox" @click="e => accountsByRole[role.role].accounts.forEach(account => account.create_selected = e.target.checked)">
              <span>{{ role.role }}</span>
              <i class="fa-solid fa-chevron-left" v-if="role.create_hidden" @click="role.create_hidden = false"></i>
              <i class="fa-solid fa-chevron-down" v-if="!role.create_hidden" @click="role.create_hidden = true"></i>
            </div>
            <div class="account-row account" v-for="account in accountsByRole[role.role].accounts" :key="account.username" v-show="!role.create_hidden">
              <input type="checkbox" v-model="account.create_selected">
              <span :title="account.name">{{ account.username }}</span>
              <i class="fa-regular fa-eye" v-if="account.create_read" @click="account.create_read = false" title="прочитано" v-show="account.create_selected"></i>
              <i class="fa-regular fa-eye-slash" v-else  @click="account.create_read = true" title="не прочитано" v-show="account.create_selected"></i>
            </div>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="createNotification">
            <i class="fas fa-spinner fa-pulse" v-if="creating_notification"></i>
            <span v-else>Создать</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal" v-if="edit_notification_dialog">
      <div class="modal-content">
        <div class="modal-header">
          <a @click="edit_notification_dialog=false; edit_notification=null"><i class="fa-solid fa-xmark"></i></a>
        </div>
        <label>{{ `Изменить уведомление` }}</label>
        <span>Тип</span>
        <div class="modal-row">
          <select v-model="edit_notification.type">
            <option value="info">info</option>
            <option value="warning">warning</option>
          </select>
          <i class="fa-solid fa-circle-info" v-if="edit_notification.type=='info'"></i>
          <i class="fa-solid fa-triangle-exclamation" v-if="edit_notification.type=='warning'"></i>
        </div>
        <div class="modal-row">
          <input type="checkbox" v-model="edit_notification.new_account">
          <span>Уведомлять новых пользователей</span>
        </div>
        <span>Сообщение</span>
        <textarea rows="3" v-model="edit_notification.message"></textarea>
        <span>Кому отправить</span>
        <div id="loader" v-if="accounts_loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div class="accounts-list" v-else>
          <div class="role-container" v-for="role in roles" :key="role.role">
            <div class="account-row role">
              <input type="checkbox" @click="e => accountsByRole[role.role].accounts.forEach(account => account.edit_selected = e.target.checked)">
              <span>{{ role.role }}</span>
              <i class="fa-solid fa-chevron-left" v-if="role.edit_hidden" @click="role.edit_hidden = false"></i>
              <i class="fa-solid fa-chevron-down" v-if="!role.edit_hidden" @click="role.edit_hidden = true"></i>
            </div>
            <div class="account-row account" v-for="account in accountsByRole[role.role].accounts" :key="account.username" v-show="!role.edit_hidden">
              <input type="checkbox" v-model="account.edit_selected">
              <span :title="account.name">{{ account.username }}</span>
              <i class="fa-regular fa-eye" v-if="account.edit_read" @click="account.edit_read = false" title="прочитано" v-show="account.edit_selected"></i>
              <i class="fa-regular fa-eye-slash" v-else  @click="account.edit_read = true" title="не прочитано" v-show="account.edit_selected"></i>
            </div>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="editNotification">
            <i class="fas fa-spinner fa-pulse" v-if="creating_notification"></i>
            <span v-else>Изменить</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="content" v-else v-show="!loading">
    <label v-if="!notifications.length">У вас нет никаких уведомлений!</label>
    <div class="info-container" v-show="notificationsByRead['false']">
      <div class="info-container-header">
        <span>Новые уведомления</span> 
      </div>
      <div class="info-container-content">
        <div class="notification-container" v-for="notification in notificationsByRead['false']" :key="notification.id" >
          <div class="notification" :class="notification.type">
            <div class="notification-content">
              <div class="notification-icon" :class="notification.type">
                <i class="fa-solid fa-circle-info" v-if="notification.type=='info'"></i>
                <i class="fa-solid fa-triangle-exclamation" v-if="notification.type=='warning'"></i>
              </div>
              <div class="notification-message">
                <pre>{{notification.message}}</pre>
              </div>
            </div>
          </div>
          <div class="notification-actions">
            <i class="fas fa-spinner fa-pulse" v-if="notification.read_progress"></i>
            <i class="fa-regular fa-eye" title="пометить как прочитанное" v-else @click="readNotification(notification, true)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="info-container" v-show="notificationsByRead['true']">
      <div class="info-container-header">
        <span>Прочитанные уведомления</span> 
      </div>
      <div class="info-container-content">
        <div class="notification-container" v-for="notification in notificationsByRead['true']" :key="notification.id" >
          <div class="notification" :class="notification.type">
            <div class="notification-content">
              <div class="notification-icon" :class="notification.type">
                <i class="fa-solid fa-circle-info" v-if="notification.type=='info'"></i>
                <i class="fa-solid fa-triangle-exclamation" v-if="notification.type=='warning'"></i>
              </div>
              <div class="notification-message">
                <pre>{{notification.message}}</pre>
              </div>
            </div>
          </div>
          <div class="notification-actions">
            <i class="fas fa-spinner fa-pulse" v-if="notification.read_progress"></i>
            <i class="fa-regular fa-eye-slash" title="пометить как непрочитанное" v-else @click="readNotification(notification, false)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdminNotificationsPage',
  emits: ['notifications-update', 'notification-update'],
  data: function() {
    return {
      get name() {
        let result =  'guest';
        if (localStorage.name) result = localStorage.name;
        return result;
      },
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      notifications: [],
      fetched_accounts: [],
      roles: [],
      error: null,
      loading: false,
      accounts_loading: false,
      create_notification_dialog: false,
      create_notification_type: 'info',
      create_notification_new_account: false,
      create_notification_message: '',
      creating_notification: false,
      deleting_notification: false,
      edit_notification_dialog: false,
      edit_notification: null,
      editing_notification: false
    }
  },
  methods: {
    listNotifications() {
      this.loading = true;
      this.error = '';
      this.notifications = [];
      axios
      .get('/api/account/notification/list', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.notifications = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить уведомления'
        }
      })
      .finally(() => this.loading = false)
    },
    getAccounts() {
      this.error = '';
      this.accounts_loading = true;
      this.fetched_accounts = [];
      axios
      .get('/api/account/list', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.fetched_accounts = response.data;
        this.roles = [];
        let roles_object = {}
        this.fetched_accounts.forEach(account => {
          if (!roles_object[account.role]) {
            roles_object[account.role] = true
            this.roles.push({
              role: account.role,
              create_hidden: false,
              edit_hiddden: false
            })
          }
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить пользователей'
        }
      })
      .finally(() => this.accounts_loading = false)
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    createNotification() {
      this.creating_notification = true;
      this.error = '';
      let body = {
        message: this.create_notification_message,
        type: this.create_notification_type,
        new_account: this.create_notification_new_account,
        accounts: []
      }
      this.accounts.forEach(account => {
        if (account.create_selected) {
          if (!account.create_read) account.create_read = false;
          body.accounts.push({
            username: account.username,
            read: account.create_read
          });
        }
      })
      axios
      .post('/api/account/notification/create', body, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.listNotifications()
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать уведомление'
        }
      })
      .finally(() => {
        this.creating_notification = false;
        this.create_notification_dialog = false;
        this.create_notification_type = 'info';
        this.create_notification_new_account = false;
        this.create_notification_message = '';
      })
    },
    deleteNotification(notification) {
      this.deleting_notification = true;
      this.error = '';
      axios
      .post('/api/account/notification/delete', {id: notification.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.listNotifications()
        } else {
          this.error = 'Failed to delete notification'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать уведомление'
        }
      })
      .finally(() => {
        this.deleting_notification = false;
      })
    },
    editNotification() {
      this.editing_notification = true;
      this.edit_notification_dialog = false;
      this.error = '';
      let body = {
        id: this.edit_notification.id,
        message: this.edit_notification.message,
        type: this.edit_notification.type,
        new_account: this.edit_notification.new_account,
        accounts: []
      }
      this.accounts.forEach(account => {
        if (account.edit_selected) {
          if (!account.edit_read) account.edit_read = false;
          body.accounts.push({
            username: account.username,
            read: account.edit_read
          });
        }
      })
      axios
      .post('/api/account/notification/edit', body, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.listNotifications()
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось изменить уведомление'
        }
      })
      .finally(() => {
        this.editing_notification = false;
        this.edit_notification = null;
      })
    },
    readNotification(notification, read) {
      notification.read_progress = true;
      let url = '/api/account/notification/read';
      if (!read) {
        url = '/api/account/notification/unread'
      }
      this.error = '';
      axios
      .post(url, {id: notification.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          notification.read = read;
        }
        this.$emit('notification-update', notification)
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось прочитать уведомление'
        }
      })
      .finally(() => {
        notification.read_progress = false;
      })

    }
  },
  computed: {
    isAdministrator() {
      return this.role == 'administrator';
    },
    // console: () => console,
    accounts() {
      let result = [...this.fetched_accounts];
      result.forEach(account => {
        account.edit_selected = false;
        account.create_read = false;
      });
      let accounts_by_username = this.groupArrayBy(result, 'username');
      if (this.edit_notification && this.edit_notification.accounts && this.edit_notification.accounts.length) {
        this.edit_notification.accounts.forEach(account => {
          accounts_by_username[account.username][0].edit_read = account.read;
          accounts_by_username[account.username][0].edit_selected = true;
        })
      }
      return result;
    },
    accountsByRole() {
      let result = {}
      this.accounts.forEach(account => {
        if (!result[account.role]) {
          result[account.role] = {
            hidden: false,
            accounts: []
          }
        }
        result[account.role].accounts.push(account);
      });
      return result
    },
    notificationsByRead() {
      let result = this.groupArrayBy(this.notifications, 'read');
      return result
    }
  },
  mounted() {
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.push({ path: '/login' })
    }
    this.listNotifications();
    if (this.isAdministrator) {
      this.getAccounts();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #content {
    align-self: center;
  }
  a.add-button {
    align-self: center;
  } 
  .notifications-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .notification-accounts {
    display: flex;
    flex-wrap: wrap;
    background-color: #fafafa;
    border-top: 1px solid #9e9e9e;
  }
  .notification-account {
    display: flex;
    padding: 3px;
    align-items: center;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid orange;
    font-size: 12px;
    background: #f7f7f7
  }
  .notification-account.read {
    border: 1px solid #005b00;
  }
  .notification-account > i {
    margin-left: 5px;
    color: #9a6400;
  }
  .notification-account.read > i {
    color: #005b00;
  }
  div.new_accounts {
    padding: 3px;
    border-radius: 5px;
    margin: 2px;
    align-self: start;
    font-size: 12px;
    background: green;
    color: white;
  }
  .notification-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10px;
  }
  .notification-actions > i {
    margin: 3px;
    margin-bottom: 10px;
  }
  .notification-actions > .fa-eye-slash:hover, .notification-actions > .fa-eye:hover {
    color: rgb(82, 157, 82);
  }
  i.fa-trash-can {
    color: red;
  }
  i.fa-trash-can:hover {
    color: rgb(255, 148, 148);
  }
  i.fa-pen:hover {
    color: rgb(82, 157, 82);
  }
  .accounts-list {
    align-self: stretch;
    overflow: auto;
    max-height: 300px;
    margin-top: 10px;
  }
  .account-row {
    display: flex;
    align-items: center;
  }
  .account-row.role {
    font-size: 20px;
    background: #2a8400;
    color: white !important;
  }
  .account-row > i {
    margin-left: auto;
    margin-right: 10px;
  }
  .account-row.account > i:hover {
    color: rgb(82, 157, 82)
  }
  .account-row.role > span {
    color: white !important;
    align-self: auto;
  }
  .account-row.account {
    padding-left: 20px;
    background: #f1f5f1;
  }
  .account-row.account > span {
    align-self: auto;
  }
  </style>
