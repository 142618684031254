<template>
  <div class="add-table">
    <input type="number" placeholder="Номер стола" v-model="new_table" @keyup.enter="addTable()">
    <button @click="addTable()">Добавить</button>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div class="list-container" v-if="runningGames.length">
    <div v-for="game in manualGames" :key="game.table_number" class="plashka-container">
      <div class="table-number">{{`Стол #${game.table_number}`}}</div>
      <div class="url">
        <span>{{`https://fairplay.host/plashka/${game.tournament_id}/${game.table_number}`}}</span>
        <i class="fa-solid fa-clone" title="Копировать" @click="copyUrl(game)"></i>
        <label v-show="game.copied">скопирован</label>
      </div>
      <iframe :src="`https://fairplay.host/plashka/${game.tournament_id}/${game.table_number}`"></iframe>
    </div>
    <div v-for="game in runningGames" :key="game.table_number" class="plashka-container">
      <div class="table-number">{{`Стол #${game.table_number}`}}</div>
      <div class="url">
        <span>{{`https://fairplay.host/plashka/${game.tournament_id}/${game.table_number}`}}</span>
        <i class="fa-solid fa-clone" title="Копировать" @click="copyUrl(game)"></i>
        <label v-show="game.copied">скопирован</label>
      </div>
      <iframe :src="`https://fairplay.host/plashka/${game.tournament_id}/${game.table_number}`"></iframe>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PlahskaAllPage',
  components: {
  },
  data: function() {
    return {
      games: null,
      loading: false,
      error: '',
      new_table: null,
      manualGames: []
    }
  },
  
  methods: {
    getGames() {
      this.loading = true;
      this.error = '';
      this.games = [];
      axios
      .get('/api/tournament/get/games', { params: { id: this.$route.params.id } })
      .then(response => {
        this.games = response.data;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    copyUrl(game) {
      navigator.clipboard.writeText(`https://fairplay.host/plashka/${game.tournament_id}/${game.table_number}`)
      game.copied = true;
      setTimeout(() => {game.copied = false}, 1000);
    },
    addTable() {
      this.error = '';
      for (let i=0; i<this.runningGames.length; i++) {
        if (this.new_table == this.runningGames[i].table_number) {
          this.error = 'Плашка для этого стола уже есть'
          return
        }
      }
      for (let i=0; i<this.manualGames.length; i++) {
        if (this.new_table == this.manualGames[i].table_number) {
          this.error = 'Плашка для этого стола уже есть'
          return
        }
      }
      let new_game = {
        tournament_id: this.$route.params.id,
        table_number: this.new_table
      }
      this.manualGames.push(new_game);
    }
  },
  computed: {
    runningGames() {
      if (!this.games.length) return []
      return this.games.filter((game) => {
        return game.status == 'running';
      });
    }
  },
  created() {
    this.getGames();
  }
}
</script>

<style scoped>
.list-container {
  width: 100%;
}
.add-table {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-table input {
  height: 25px;
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 1px 5px;
}
iframe {
  width: 100%;
  height: 38.5px;
  border: none;
}
.plashka-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  margin: 10px;
  padding: 10px;
}
.table-number {
  color: #248d24;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.url {
  margin-bottom: 10px;
}
.url span {
  margin-right: 10px;
  color: rgb(25, 25, 103);
}
.url i {
  color: rgb(51, 51, 51);
}
.url i:hover {
  color: #248d24;
}
.url label {
  margin-left: 5px;
  color: #aaa;
}
</style>