<template>
  <div id="login">
    <input type="text" placeholder="Username" v-model="username" @keyup.enter="login">
    <input type="password" placeholder="Password" v-model="password" @keyup.enter="login">
    <button :disabled="username == '' || password == '' || loading" @click="login">
      <span v-show="!loading">Войти</span>
      <i class="fas fa-spinner fa-pulse" v-show="loading"></i>
    </button>
    <router-link to='/register' title="Регистрация" class="register">
      <label>Регистрация</label>
    </router-link>
    <span class="error" v-if="error!=''">{{error}}</span>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'LoginPage',
  emits: ['login'],
  data: function() {
    return {
      username: '',
      password: '',
      loading: false,
      error: ''
    }
  },
  methods: {
    login: function() {
      if (this.username == '' || this.password == '' || this.loading) return;
      this.loading = true;
      this.error = '';
      axios
      .post('/api/account/login', { username: this.username, password: this.password })
      .then(response => {
        localStorage.name = response.data.name;
        localStorage.username = response.data.username;
        localStorage.token = response.data.token;
        localStorage.role = response.data.role;
        this.$emit('login');
        this.$router.go(-1);
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
        if (error.response) {
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #login {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  input {
    margin: 10px;
    align-content: center;
    text-align: center;
    padding: 5px;
    border: none;
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(100deg, #ffffff , rgb(47 129 48), #ffffff);
    border-image-slice: 1;
    outline: none;
    border-radius: 10px;
  }
  button {
    background-color: rgb(47 129 48);
    color: #ffffff;
    border: none;
    width: 60px;
    margin: 10px;
    padding: 5px;
  }
  button:disabled {
    background-color: #ddd;
    color: black;
  }
  .register {
    background: #3039a0;
    color:white;
    border-radius: 10px;
    font-size: 13px;
    padding: 7px;
    margin-top: 30px;
  }
  .register {text-decoration: none;}
  .register:hover {
    color: black;
  }
</style>
