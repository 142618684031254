<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div id="content" v-if="!loading && game">
    <div id="header">
      <div id="elapsed-time" v-if="game.status == 'running'">{{elapsedTime}}</div>
    </div>
    <label v-show="game.game_till" id="game-till">{{gameType == 2 ? `Игра до ${game.game_till} шаров` : `Игра до ${game.game_till} побед`}}</label>
    <label id="table-number">{{`Стол #${game_table}`}}</label>
    <div id="players">
      <div class="player">
        <div class="player-name">{{player1_name}}</div>
        <h3 class="score-header">Счет по партиям</h3>
        <div class="game-score">
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="gameScoreChange(true, false)">
            <i class="fa-solid fa-minus"></i>
          </button>
          <input v-model="game.player1_score" :disabled="!(isEditor && game.status == 'running') || updating_game" @blur="saveGame()">
          <div class="spin" v-show="updating_game"><i class="fas fa-circle-notch fa-spin"></i></div>
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="gameScoreChange(true, true)">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <h3 class="score-header">Счет по шарам</h3>
        <div class="game-score">
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="frameScoreChange(true, false)">
            <i class="fa-solid fa-minus"></i>
          </button>
          <input v-model="game.player1_frame_score" :disabled="!(isEditor && game.status == 'running') || updating_game" @blur="saveGame()">
          <div class="spin" v-show="updating_game"><i class="fas fa-circle-notch fa-spin"></i></div>
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="frameScoreChange(true, true)">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <button :disabled="updating_game" @click="frameWin(true)" v-if="isEditor && game.status == 'running'">Выиграл партию</button>
      </div>
      <div class="player">
        <div class="player-name">{{player2_name}}</div>
        <h3 class="score-header">Счет по партиям</h3>
        <div class="game-score">
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="gameScoreChange(false, false)">
            <i class="fa-solid fa-minus"></i>
          </button>
          <input v-model="game.player2_score" :disabled="!(isEditor && game.status == 'running') || updating_game" @blur="saveGame()">
          <div class="spin" v-show="updating_game"><i class="fas fa-circle-notch fa-spin"></i></div>
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="gameScoreChange(false, true)">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <h3 class="score-header">Счет по шарам</h3>
        <div class="game-score">
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="frameScoreChange(false, false)">
            <i class="fa-solid fa-minus"></i>
          </button>
          <input v-model="game.player2_frame_score" :disabled="!(isEditor && game.status == 'running') || updating_game" @blur="saveGame()">
          <div class="spin" v-show="updating_game"><i class="fas fa-circle-notch fa-spin"></i></div>
          <button :disabled="updating_game" v-if="isEditor && game.status == 'running'" @click="frameScoreChange(false, true)">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <button :disabled="updating_game" @click="frameWin(false)" v-if="isEditor && game.status == 'running'">Выиграл партию</button>
      </div>
    </div>
    <button :disabled="updating_game" @click="stopGame()" v-if="isEditor && game.status == 'running'">Завершить встречу</button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'GamePage',
  components: {
  },
  data: function() {
    return {
      username: 'guest',
      role: null,
      id: null,
      gameType: null,
      handicap_id: null,
      club_id: null,
      club: null,
      club_tables: [],
      participants: null,
      game: null,
      owner: null,
      tournament_id: null,
      handicap_relations: null,
      loading: true,
      now_timestamp: null, 
      error: null,
      updating_game: false,
    }
  },
  
  methods: {
    getGame() {
      if (this.updating_game) return;
      if (!this.game) this.loading = true;
      this.error = '';
      axios
      .get('/api/tournament/get/game', { params: { tournament_id: this.$route.params.tournament_id, table_number: this.$route.params.table_number } })
      .then(response => {
        if (!this.game || this.game.id != response.data.game.id) {
          this.club_id = response.data.club_id;
          this.handicap_id = response.data.handicap_id;
          if (this.handicap_id && !this.handicap_relations) {
            this.getHandicap(this.handicap_id);
          }
          this.participants = response.data.participants;
          this.game = response.data.game;
          this.owner = response.data.owner;
          this.tournament_id = response.data.id;
          this.gameType = response.data.game_type;
          if (this.club_id) this.getClub(this.club_id);
        }
      })
      .catch(error => {
        console.log(error);
        this.error = 'За столом никто не играет.'
      })
      .finally(() => this.loading = false)
    },
    getClub(id) {
      if (this.club && this.club.id == id) return;
      axios
      .get('/api/club/get', { params: { id: id } })
      .then(response => {
        this.club = response.data;
        let table_type = 'pyramid';
        if (this.gameType == '7') table_type = 'snooker';
        if (this.gameType == '8') table_type = 'pool';
        this.club_tables = this.groupArrayBy(this.club.tables, 'type')[table_type];
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
    },
    getHandicap(id) {
      axios
      .get('/api/handicap/get', { params: { id: id } })
      .then(response => {
        this.handicap_relations = response.data[0].relations;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
    },
    getNow() {
      this.now_timestamp = Date.now();
    },
    gameScoreChange(player1, up) {
      if (player1) {
        if (up) this.game.player1_score++;
        else this.game.player1_score--;
      } else {
        if (up) this.game.player2_score++;
        else this.game.player2_score--;
      }
      this.saveGame();
    },
    frameScoreChange(player1, up) {
      if (player1) {
        if (up) this.game.player1_frame_score++;
        else this.game.player1_frame_score--;
      } else {
        if (up) this.game.player2_frame_score++;
        else this.game.player2_frame_score--;
      }
      this.saveGame();
    },
    stopGame() {
      if (this.game.player1_score == this.game.player2_score) {
        this.error = 'Счет встречи не может быть одинаковым'
        return
      }
      this.updating_game = true;
      this.error = '';
      if (this.club_id) {
        for (let i=0; i<this.club_tables.length; i++) {
          if (this.game.table_number == this.club_tables[i].table_number) {
            this.club_tables[i].status = 'close';
            this.updateTable(this.club_tables[i]);
            break;
          }
        }
      }
      axios
      .post('/api/tournament/game/stop', {game: this.game}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.game.status = 'finished';
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating_game = false)
    },
    saveGame() {
      this.updating_game = true;
      this.error = '';
      axios
      .post('/api/tournament/game/save', {game: this.game}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating_game = false)
    },
    updateTable(table) {
      this.loading = true;
      this.error = null;
      axios
      .post('/api/club/edit/table', { club_id: table.club_id, id: table.id, status: table.status }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        let tables = response.data;
        let table_type = 'pyramid';
        this.club_tables = this.groupArrayBy(tables, 'type')[table_type];
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    frameWin(player1) {
      if (player1) {
        this.game.player1_score++;
      } else {
        this.game.player2_score++;
      }
      this.game.player1_frame_score = 0;
      this.game.player2_frame_score = 0;
      this.saveGame();
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    elapsedTime() {
      if (!this.game) return '';
      if (!this.game.start_time) return '';
      let seconds = Math.floor((this.now_timestamp - this.game.start_time)/1000);
      let hours = Math.floor(seconds/3600);
      seconds = seconds - (hours * 3600);
      if (hours.toString().length == 1) hours = '0' + hours;
      let minutes = Math.floor(seconds/60);
      seconds = seconds - (minutes * 60);
      if (minutes.toString().length == 1) minutes = '0' + minutes;
      if (seconds.toString().length == 1) seconds = '0' + seconds;
      return `${hours}:${minutes}:${seconds}`;
    },
    game_table() {
      let result = '';
      if (this.club_id) {
        for (let i=0; i<this.club_tables.length; i++) {
          if (this.game.table_number == this.club_tables[i].table_number) {
            result = this.club_tables[i].table_name;
            break;
          }
        }
      } else {
        result = this.game.table_number;
      }
      return result;
    },
    player1_name() {
      if (!this.game) return 'Игрок 1'
      if (this.participants[this.game.player1][0].team_name) return this.participants[this.game.player1][0].team_name;
      else if (this.participants[this.game.player1][0].name) return this.formatName(this.participants[this.game.player1][0].name);
      return 'X'
    },
    player2_name() {
      if (!this.game) return 'Игрок 2'
      if (this.participants[this.game.player2][0].team_name) return this.participants[this.game.player2][0].team_name;
      else if (this.participants[this.game.player2][0].name) return this.formatName(this.participants[this.game.player2][0].name);
      return 'X'
    }
  },
  created() {
    this.getGame();
    setInterval(this.getNow,1000);
    setInterval(this.getGame,20000);
    this.getNow();
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<style scoped>
#header {
  display: flex;
  justify-content: center;
}
#elapsed-time {
  width: auto;
  color: #248d24;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}
#players {
  display: flex;
  height: 100%;
  align-items: stretch;
}
.player {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
  margin: 5px;
  overflow: hidden;
}
.player-name {
  text-align: center;
  font-size: 25px;
  padding: 10px;
  background: rgb(52, 51, 58);
  color: aliceblue;
}
.score-header {
  text-align: center;
  margin: 0;
  margin-top: 20px;
  color: rgb(25, 25, 103);
  align-self: center;
}
.game-score {
  position: relative;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  height: 100px;
  overflow: hidden;
}
.game-score button {
  width: 100px;
  margin: 5px;
}
.game-score input {
  border: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  font-size: 30px;
}
.game-score .spin {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.game-score .spin i {
  font-size: 50px;
  color: gray;
}
#game-till {
  text-align: center;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #248d24;
}
#table-number {
  text-align: center;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: rgb(101, 101, 101);
}
</style>