<template>
  <div id='participants-div'>
    <div class='action-tabs' v-if="isEditor" v-show="!loading">
      <div class='action-tabs-header'>
        <button class='action' @click="actionPanelToggle('addPlayer')" :class="{'active': (actionPanel == 'addPlayer')}">
          Добавить игрока
        </button>
      </div>
      <div class="action-panels" v-if="isEditor && actionPanel">
        <div id="add-player-div" class="action-panel" v-show="actionPanel == 'addPlayer'">
          <div class="search-div">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" placeholder="Поиск игроков..." v-model="searchString" v-on:keyup.enter="searchPlayer">
            <button class="search-button" :disabled="searching" v-on:click="searchPlayer">
              <span v-show="!searching">Поиск</span>
              <i class="fas fa-spinner fa-pulse" v-show="searching"></i>
            </button>
          </div>
          <div id="search-content">
            <table v-show="fetchedPlayers.length">
              <thead>
                <tr>
                  <th  style="width: 24%;">Имя</th>
                  <th  style="width: 15%;">Дата Рождения</th>
                  <th  style="width: 24%;">Город</th>
                  <th  style="width: 23%;">Разряд</th>
                  <th  style="width: 13%;">Группа</th>
                  <th  style="width: 1%;">+</th>
                </tr>
              </thead>  
              <tbody>
                <tr v-for="player in fetchedPlayers" :key="player.id">
                  <td>{{ player.full_name }}</td>
                  <td>{{ birthdayFormat(player) }}</td>
                  <td>{{ player.city }}</td>
                  <td>{{ player.rank }}</td>
                  <td>
                    <input type="number" min="1" :max="handicap_num_groups" v-model="player.group">
                  </td>
                  <td>
                    <button @click="addParticipant(player.id, player.group)" :disabled="updating || !(player.group > 0 && player.group <= handicap_num_groups) ">
                      <i class="fa-solid fa-square-plus" v-show="!updating"></i>
                      <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <span v-if="error != ''" class="error">{{error}}</span>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div id="content">
      <div class="list-settings" v-show="participants.length">
        <div class="search-setting">
          <a class="search-button" @click="searchClicked()">
            <i class="fa-solid fa-magnifying-glass"></i>
          </a>
          <div v-show="searchingParticipant" class='search-string'>
            <input id='search-input' :value="searchParticipantString" @input="e => searchParticipantString = e.target.value">
            <a class="search-clear-button" @click="searchParticipantString = null">
              <i class="fa-solid fa-xmark"></i>
            </a>
          </div>
        </div>
      </div>
      <table v-show="participants.length">
        <thead>
          <tr>
            <th style="width: 10%;" @click="headerClick(null)">#</th>
            <th style="width: 69%;" @click="headerClick('name')">Имя</th>
            <th style="width: 10%;" @click="headerClick('group')">Группа</th>
            <th style="width: 10%;" @click="headerClick('change_date')">Дата назначения</th>
          </tr>
        </thead>  
        <tbody>
          <tr v-for="(participant, index) in sortedParticipants" :key="participant.id">
            <td>{{index+1}}</td>
            <td>
              <router-link :to="`/players/${participant.player_id}/info`" class='player-link'>
                {{participant.name}}
              </router-link>
            </td>
            <td>
              <input type="number" min="1" :max="handicap_num_groups"  v-model="participant.group" @blur="updateParticipant(participant.id, participant.group)" v-show="!updating" v-if="isEditor">
              <i class="fas fa-spinner fa-pulse" v-show="updating" v-if="isEditor"></i>
              <span v-if="!isEditor">{{participant.group}}</span>
            </td>
            <td>{{dateFormat(parseInt(participant.change_timestamp,10))}}</td>
            <td v-if="isEditor">
              <button @click="deleteParticipant(participant.id)" :disabled="updating">
                <i class="fa-solid fa-trash-can" v-show="!updating"></i>
                <i class="fas fa-spinner fa-pulse" v-show="updating"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HandicapParticipants',
  data: function() {
    return {
      username: null,
      role: null,
      participants: [],
      loading: false,
      searchString: '',
      searching: false,
      fetchedPlayers: [],
      error: '',
      actionPanel: null,
      updating: false,
      participantsSort: null,
      previousParticipantsSort: null,
      handicap_id: null,
      handicap_name: null,
      handicap_num_groups: null,
      owner: null,
      searchingParticipant: false,
      searchParticipantString: null
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    filteredParticipants() {
      if (!this.searchParticipantString) {
        return this.participants;
      }
      return this.participants.filter(player => player.name && player.name.toLowerCase().indexOf(this.searchParticipantString.toLowerCase()) !== -1);
    },
    sortedParticipants () {
      if (this.participantsSort == null) return this.filteredParticipants;
      let sorted = this.filteredParticipants;
      let ascending = true;
      if (this.participantsSort == this.previousParticipantsSort) ascending = false;
      sorted.sort(this.dynamicSort(this.participantsSort, ascending));
      return sorted
    }
  },
  methods: {
    getHandicap() {
      this.loading = true;
      this.error = '';
      this.handicap = null;
      axios
      .get('/api/handicap/get', { params: { id: this.$route.params.id } })
      .then(response => {
        this.handicap_id = response.data[0].id;
        this.handicap_name = response.data[0].name;
        this.handicap_num_groups = response.data[0].num_groups;
        this.participants = response.data[0].participants;
        this.owner = response.data[0].owner;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
      .finally(() => this.loading = false)
    },
    searchPlayer() {
      this.searching = true;
      this.fetchedPlayers = [];
      axios
      .get('/api/player/search', { params: { search: this.searchString } })
      .then(response => (this.fetchedPlayers = response.data))
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.searching = false)
    },
    dynamicSort(property, ascending) {
      return function (a,b) {
        if (ascending){
          return (a[property] > b[property]) ? 1 : -1;
        } else {
          return (a[property] < b[property]) ? 1 : -1;
        }
      }
    },
    headerClick(property) {
      if (this.previousParticipantsSort == this.participantsSort) this.previousParticipantsSort = null;
      else this.previousParticipantsSort = this.participantsSort;
      this.participantsSort = property;
    },
    actionPanelToggle(panel) {
      if (this.actionPanel == panel) {
        this.actionPanel = null;
      } else {
        this.actionPanel = panel;
      }
    },
    addParticipant(player_id, group) {
      if (!this.handicap_id) {
        console.log('handicap_id is not defined');
        return
      }
      if (!player_id ) {
        console.log('player_id is not defined');
        return
      }
      this.error = '';
      this.updating = true;
      axios
      .post('/api/handicap/add/participant', {id: this.handicap_id, player_id: player_id, group: group}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants.push(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    deleteParticipant(participant_id) {
      if (!participant_id ) return
      this.error = '';
      this.updating = true;
      axios
      .post('/api/handicap/delete/participant', {id: participant_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.participants = response.data[0].participants;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.updating = false)
    },
    updateParticipant(participant_id, group) {
      this.error = '';
      axios
      .post('/api/handicap/edit/participant', {id: participant_id, group: group}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
    },
    birthdayFormat(player) {
      let birth_str = "";
        if (player.birth_day != null) {
          let birth_day_str = (player.birth_day.toString().length == 1) ? '0' + player.birth_day : player.birth_day;
          birth_str+=birth_day_str + '.'
        }
        if (player.birth_month != null) {
          let birth_month_str = (player.birth_month.toString().length == 1) ? '0' + player.birth_month : player.birth_month;
          birth_str+=birth_month_str + '.';
        }
        if (player.birth_year != null) birth_str+=player.birth_year;
        return birth_str;
    },
    dateFormat(timestamp) {
      let date = new Date(timestamp);
      let day_str = (date.getDate().toString().length == 1) ? '0' + date.getDate() : date.getDate();
      let month_str = ((date.getMonth() + 1).toString().length == 1) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
      let result = `${day_str}.${month_str}.${date.getFullYear()}`;
      return result
    },
    searchClicked() {
      this.searchingParticipant = !this.searchingParticipant;
      if (this.searchingParticipant) {
        let input = document.getElementById('search-input');
        setTimeout(function() { input.focus() }, 1);
      }
    }
  },
  created() {
    this.getHandicap();
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#participants-div {
  width: 100%;
  overflow-y: auto;
  font-size: 13px;
}
td input {
  width: auto;
  margin: 0;
  padding: 3px;
}
td button {
  border: none;
  margin: 0;
  color: black;
  padding: 0;
}
td button:hover {
  color: #248d24;
  background: inherit;
}
td button:disabled {
  color: gray;
  background: inherit;
}
.action-panels {
  margin-bottom: 20px;
}
#add-player-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#search-content {
  width: 100%;
}
.list-settings {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  align-items: center;
  margin-bottom: 5px;
}
.sort-setting {
  position: relative;
  display: flex;
  margin: 0 10px;
}
.sort-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgb(52 51 58);
  color: #f1f8ff;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  top: 20px;
  right: 0;
}
.sort-options label {
  margin: 5px 10px;
}
.search-setting {
  display: flex;
}
.search-button {
  margin: 5px;
}
.search-string {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
}
.search-string input {
  border: none;
  height: 100%;
  padding: 0;
  text-align: center;
  width: 200px;
}
.search-string input:focus {
  outline: none;
}
.search-clear-button {
  margin: 5px;
  align-self: center;
}
</style>
