<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div class='actions' v-show="!loading">
    <a class='add-button' title="Добавить рейтинг" @click="create_rating_dialog = true" v-show="!create_rating_dialog" v-if="isOperator"><i class="fa-solid fa-circle-plus"></i></a>
    <div class="search-div">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input type="text" placeholder="Поиск рейтингов..." v-model="searchString" v-on:keyup.enter="searchRating(false)">
      <button class="search-button" :disabled="loading" v-on:click="searchRating(false)">
        <span>Поиск</span>
      </button>
    </div>
  </div>
  <div id="content">
    <div class="info-container" v-show="ratings.length" v-if="!create_rating_dialog">
      <div class="info-container-header">
        <span>Рейтинги</span> 
      </div>
      <div class="info-container-content">
        <router-link v-for="(rating, index) in ratings" :key="rating.id" :to="`/ratings/${rating.id}/participants`" :class="{'last': index == ratings.length - 1}">
          <div class="rating-info">
            <div class="name">
              {{rating.name}}
            </div>
            <div class="rating-places">
              <div v-for="winner in rating.winners" :key="winner.name">
                <label class="player_place">{{ `${winner.place}:` }}</label>
                <label class="player_name">{{ `${winner.name}` }}</label>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="create-dialog" v-else v-show="!loading">
      <label>Название</label>
      <input :disabled="creating_rating" v-model="new_rating_name">
      <div v-if="!creating_rating">
        <button @click="createRating()">Добавить</button>
        <button class="warning" @click="create_rating_dialog = false">Отменить</button>
      </div>
      <div id="loader" v-else>
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
    <button :disabled="more_loading" @click="getMore()" v-show="ratings.length && !create_rating_dialog && !searchString">
      <i class="fas fa-spinner fa-pulse" v-if="more_loading"></i>
      <span v-else>Еще</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'RatingsPage',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      searchString: '',
      ratings: [],
      loading: false,
      error: '',
      create_rating_dialog: false,
      creating_rating: false,
      new_rating_name: null,
      more_loading: false
    }
  },
  methods: {
    searchRating(more=false) {
      if (this.searchString == '') {
        this.listRatings();
        return;
      }
      let url = '/api/rating/search';
      if (more) {
        url += `?max_id=${this.ratings[this.ratings.length - 1].id}`
        this.more_loading = true;
      } else {
        this.ratings = [];
        this.loading = true;
      }
      axios
      .get(url, { params: { search: this.searchString } })
      .then(response => (this.ratings = this.ratings.concat(response.data)))
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
        this.more_loading = false
      })
    },
    listRatings(more=false) {
      let url = '/api/rating/list';
      if (more) {
        url += `?max_id=${this.ratings[this.ratings.length - 1].id}`
        this.more_loading = true;
      } else {
        this.ratings = [];
        this.loading = true;
      }
      axios
      .get(url)
      .then(response => (this.ratings = this.ratings.concat(response.data)))
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
        this.more_loading = false;
      })
    },
    getMore() {
      if (!this.searchString) {
        this.listRatings(true);
      } else {
        this.searchRating(true);
      }
    },
    createRating() {
      if (this.new_rating_name == '') return
      this.creating_rating = true;
      this.error = '';
      axios
      .post('/api/rating/create', {name: this.new_rating_name}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.$router.push({ path: `/ratings/${response.data.id}/participants` })
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать рейтинг'
        }
      })
      .finally(() => this.creating_rating = false)
    }
  },
  computed: {
    isOperator() {
      return this.role == 'operator' || this.role == 'administrator'
    }
  }, 
  created() {
    this.listRatings();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#content {
  align-self: center;
}
.search-div {
  margin-left: auto;
}
.rating-places {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
}
.player_place {
  color: black;
  font-weight: bold;
  margin-right: 3px;
}
.player_name {
  color: #0C1B6B;
  margin-right: 7px;
}
</style>
