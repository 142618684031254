<template>
  <div id="plashka-content" v-if="!loading">
    <div class="player player1">{{player1_name}}</div>
    <div class="frame-score">{{game ? game.player1_frame_score : '0'}}</div>
    <div class="game-score">{{game ? game.player1_score : '0'}}</div>
    <div>{{`(${game.game_till})`}}</div>
    <div class="game-score">{{game ? game.player2_score : '0'}}</div>
    <div class="frame-score">{{game ? game.player2_frame_score : '0'}}</div>
    <div class="player player2">{{player2_name}}</div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'PlashkaPage',
  components: {
  },
  data: function() {
    return {
      game: null,
      participants: null,
      loading: true,
      wb_till: null,
      lb_till: null,
      get_score_interval: null,
      get_score_error_count: 0,
      slow_score_check: false
    }
  },
  
  methods: {
    getGame() {
      axios
      .get('/api/tournament/get/game', { params: { tournament_id: this.$route.params.tournament_id, table_number: this.$route.params.table_number } })
      .then(response => {
        this.participants = response.data.participants;
        this.game = response.data.game;
        this.wb_till = response.data.wb_till;
        this.lb_till = response.data.lb_till;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.loading = false)
    },
    getGameScore() {
      if (!this.game.id) return
      axios
      .get('/api/tournament/get/gamescore', { params: { tournament_id: this.$route.params.tournament_id, table_number: this.$route.params.table_number } })
      .then(response => {
        this.game.player1_frame_score = response.data.player1_frame_score;
        this.game.player1_score = response.data.player1_score;
        this.game.player2_frame_score = response.data.player2_frame_score;
        this.game.player2_score = response.data.player2_score;
        if (this.game.player1 != response.data.player1 || this.game.player2 != response.data.player2)
          this.getGame();
        this.game.player1 = response.data.player1;
        this.game.player2 = response.data.player2;
        this.get_score_error_count = 0;
        if (this.slow_score_check) {
          clearInterval(this.get_score_interval);
          this.get_score_interval = setInterval(this.getGameScore, 5000);
          this.slow_score_check = false;
        }
      })
      .catch(error => {
        this.get_score_error_count++;
        console.log(error);
        if (!this.slow_score_check && this.get_score_error_count > 3) {
          clearInterval(this.get_score_interval);
          this.slow_score_check = true;
          this.get_score_interval = setInterval(this.getGameScore, 180000);
          this.get_score_error_count = 0;
        } else if (this.slow_score_check && this.get_score_error_count > 60) {
          clearInterval(this.get_score_interval);
        }
      })
      .finally(() => this.loading = false)

    },
    formatName(name) {
      let result;
      console.log(name);
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    }
  },
  computed: {
    game_till() {
      let result = null;
      if (this.game.stage.startsWith('LB')) {
        if (this.lb_till) result = this.lb_till;
      } else {
        if (this.wb_till) result = this.wb_till;
      }
      return result;
    },
    player1_name() {
      if (!this.game) return 'Игрок 1'
      if (this.participants[this.game.player1][0].team_name) return this.participants[this.game.player1][0].team_name;
      else if (this.participants[this.game.player1][0].name) return this.formatName(this.participants[this.game.player1][0].name);
      return 'Игрок 1'
    },
    player2_name() {
      if (!this.game) return 'Игрок 2'
      if (this.participants[this.game.player2][0].team_name) return this.participants[this.game.player2][0].team_name;
      else if (this.participants[this.game.player2][0].name) return this.formatName(this.participants[this.game.player2][0].name);
      return 'Игрок 2'
    }
  },
  created() {
    this.getGame();
    this.get_score_interval = setInterval(this.getGameScore, 5000);
  }
}
</script>

<style scoped>
#plashka-content {
  display: flex;
  width: auto;
  align-items: center;
  height: 38.5px;
}
.player {
  width: 210px;
  padding: 10px;
}
.player1 {
  text-align: right;
}
.player2 {
  text-align: left;
}
.frame-score {
  background: #ffce05;
  color: black;
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
}
.game-score {
  padding: 10px;
}
</style>