<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <h1 v-if="club" v-show="!loading">{{club.name}}</h1>
  <div id="content" v-if="club" v-show="!loading">
    <div class="tables">
      <div class="type-tables" v-for="type in ['pyramid', 'pool', 'snooker']" :key="type" v-show="tables_by_type[type] || isEditor">
        <div class="type-name">
          <label>{{convertTableType(type)}}</label>
          <a v-if="isEditor" @click="addTable(type)"><i class="fa-solid fa-circle-plus"></i></a>
        </div>
        <div class="tables-list">
          <div class="table" v-for="(table, index) in tables_by_type[type]" :key="table.id" :class="{'last': index == tables_by_type[type].length - 1}">
            <div class="cell">
              <div>
                <label>Номер</label>
              </div>
              <span>{{table.table_number}}</span>
            </div>
            <div class="cell">
              <div>
                <label>Имя</label>
                <a @click="changeTableName(table)" v-if="isEditor"><i class="fa-solid fa-pencil" :disabled="table.loading"></i></a>
              </div>
              <span v-show="!table.changing">{{table.table_name}}</span>
              <input v-show="table.changing" v-model="table.table_name" v-on:keyup.enter="table.changing = false; saveTable(table)" @focusout="table.changing=false; saveTable(table)" :id="table.id + '-table'" :disabled="table.loading">
            </div>
            <div class="cell">
              <div>
                <label>Статус</label>
              </div>
              <span :class="table.status" v-if="!isEditor">{{convertTableStatus(table.status)}}</span>
              <select :id="table.id + '-table-status'" v-model="table.status" @focusout="table.statusChanging=false" v-else @change="saveTable(table)" :disabled="table.loading">
                <option v-for="status in table_statuses" :key="status.value" :value="status.value">{{status.str}}</option>
              </select>
            </div>
            <div class="cell buttons" v-if="isEditor" :disabled="table.loading">
              <a title="Удалить" @click="deleteTable(table)" class="delete-button">
                <i v-if="table.loading" class="fas fa-spinner fa-pulse"></i>
                <i v-else class="fa-solid fa-trash-can"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ClubTables',
  data: function() {
    return {
      club: null,
      tables: [],
      error: '',
      loading: true,
      owner: null,
      table_statuses: [
        {value: 'open', str: 'Занят'},
        {value: 'close', str: 'Свободен'}
      ]
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    tables_by_type() {
      let tables_by_types = this.groupArrayBy(this.tables, 'type');
      Object.keys(tables_by_types).forEach(type => {
        tables_by_types[type].sort(this.dynamicSort('table_number', true));
      });
      return tables_by_types;
    }
  },
  methods: {
    getClub() {
      this.loading = true;
      this.error = '';
      this.club = null
      this.owner = null;
      axios
      .get('/api/club/get', { params: { id: this.$route.params.id } })
      .then(response => {
        this.club = response.data
        this.tables = response.data.tables
        this.tables.forEach(table => table.loading = false);
        this.owner = response.data.owner
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    addTable(type) {
      this.loading = true;
      this.error = null;
      axios
      .post('/api/club/create/table', { club_id: this.club.id, type: type }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.tables = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    deleteTable(table) {
      this.loading = true;
      this.error = null;
      axios
      .post('/api/club/delete/table', { club_id: this.club.id, id: table.id }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.tables = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    saveTable(table) {
      table.loading = true;
      this.error = null;
      axios
      .post('/api/club/edit/table', { club_id: this.club.id, id: table.id, table_name: table.table_name, status: table.status }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.tables = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => table.loading = false)
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    convertTableType(type) {
      if (type == 'pyramid') return "Пирамида";
      else if (type == 'pool') return "Пул";
      else if (type == 'snooker') return "Снукер";
      else return 'unknown'
    },
    convertTableStatus(status) {
      if (status == 'open') return "Занят";
      else if (status == 'close') return "Свободен";
      else return 'unknown'
    },
    changeTableName(table) {
      table.changing=!table.changing;
      if (table.changing) {
        let input = document.getElementById(`${table.id}-table`);
        setTimeout(function() { input.focus() }, 1);
      }
    },
    dynamicSort(property, ascending) {
      return function (a,b) {
        if (!isNaN(parseInt(a[property],10)) && !isNaN(parseInt(b[property],10)) && !a[property].toString().includes('-') && !b[property].toString().includes('-')) {
          a[property] = parseInt(a[property],10);
          b[property] = parseInt(b[property],10);
        }
        if (ascending){
          return (a[property] > b[property]) ? 1 : -1;
        } else {
          return (a[property] < b[property]) ? 1 : -1;
        }
      }
    },
  },
  created() {
    this.getClub();
    this.username = localStorage.username;
    this.role = localStorage.role;
  }
}
</script>

<style scoped>
h1 {
  align-self: flex-start;
  margin: 20px;
}
.tables {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.type-tables {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px #ccc;
  max-width: 500px;
  min-width: 200px;
}
.type-name {
  display: flex;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  align-self: stretch;
  padding: 10px;
  background: #f2f2f2;
}
.type-name label {
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
}
.type-name a {
  font-size: 18px;
  color: #248d24;
}
.type-name a:hover {
  color: #84dc84;
}
.tables-list {
  display: flex;
  flex-direction: column;
}
.table {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}
.table.last {
  border-bottom: none;
}
.cell {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.cell > div {
  display: flex;
  align-items: center;
}
.cell input {
  width: 50px
}
.cell select {
  margin: 5px;
}
.cell a {
  font-size: 12px;
  color: #445142;
}
.cell a:hover {
  color: #248d24;
}
.cell label {
  color: rgb(109, 109, 109);
  margin: 5px;
}
.cell span {
  margin: 5px;
}
.cell span.open {
  color: #a10000;
}
.cell span.close {
  color: #0e7c0d;
}
.cell.buttons {
  justify-content: center;
  align-self: stretch;
  padding: 3px;
}
.cell.cell.buttons a {
  color: #445142;
  font-size: 14px;
}
.cell.cell.buttons a:hover {
  color: #248d24;
}
.cell.cell.buttons a.delete-button {
  color: #e10808;
}
.cell.cell.buttons a.delete-button:hover {
  color: #e77a7a;
}
</style>
