<template>
  <div id="content">
    <div id='statement-div'>
      <span v-if="error != ''" class="error">{{error}}</span>
      <div id="loader" v-show="loading">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
      <div id="content" v-show="!loading" v-if="!isMobile">
        <h3>{{ tournament_name }}</h3>
        <h4 @click="() => {this.running_expanded = !this.running_expanded}" v-show="gamesByStatus.running && gamesByStatus.running.length">
          <span class="unselectable">Текущие встречи</span>
          <i class="fa-solid fa-angle-down expander" v-show="running_expanded"></i>
          <i class="fa-solid fa-angle-right expander" v-show="!running_expanded"></i>
        </h4>
        <table v-show="running_expanded && gamesByStatus.running && gamesByStatus.running.length">
          <thead>
            <tr>
              <th @click="headerClick('running', 'stage')">Этап</th>
              <th @click="headerClick('running', 'table_name')">Стол</th>
              <th @click="headerClick('running', 'time')">Длительнось</th>
              <th @click="headerClick('running', 'player1_name')">Имя</th>
              <th v-if="handicap_id">Фора</th>
              <th colspan="2" class="wide-header" v-if="isEditor">Счет</th>
              <th v-if="handicap_id">Фора</th>
              <th @click="headerClick('running', 'player2_name')">Имя</th>
              <th v-if="isEditor"></th>
            </tr>
          </thead>  
          <tbody>
            <tr v-for="game in sortedGamesByStatus.running" :key="game.id">
              <td>{{game.stage}}</td>
              <td :class="{'input-cell': isEditor}">
                <input type="number" v-if="isEditor && !updatingGame && !club_tables.length" v-model="game.table_name" @blur="gameTableNumberChange(game)">
                <select v-if="isEditor && !updatingGame && club_tables.length" v-model="game.table_name" @change="gameClubTableChange(game)">
                  <option value=""></option>
                  <option v-if="(game.status == 'running' || game.status == 'finished') && game.table_number" :value="game.table_name">{{game.table_name}}</option>
                  <option v-for="table in freeTables" :key="table.table_number" :value="table.table_name">{{table.table_name}}</option>
                </select>
                <i class="fas fa-spinner fa-pulse" v-show="isEditor && updatingGame"></i> 
                <span v-if="!isEditor">{{game.table_name}}</span>
              </td>
              <td>{{formatTime(game.time)}}</td>
              <td>{{game.player1_name}}</td>
              <td v-if="handicap_id">{{game.player1_handicap}}</td>
              <td v-if="isEditor" class="input-cell score-cell">
                <div>
                  <i class="fa-solid fa-minus" @click="game.player1_score--" v-show="!updatingGame"></i>
                  <input type="number" v-model="game.player1_score" v-show="!updatingGame">
                  <i class="fa-solid fa-plus" @click="game.player1_score++" v-show="!updatingGame"></i>
                  <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
                </div>
              </td>
              <td v-if="isEditor" class="input-cell score-cell">
                <div>
                  <i class="fa-solid fa-minus" @click="game.player2_score--" v-show="!updatingGame"></i>
                  <input type="number" v-model="game.player2_score" v-show="!updatingGame">
                  <i class="fa-solid fa-plus" @click="game.player2_score++" v-show="!updatingGame"></i>
                  <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
                </div>
              </td>
              <td v-if="handicap_id">{{game.player2_handicap}}</td>
              <td>{{game.player2_name}}</td>
              <td v-if="isEditor">
                <div class="game-controls">
                  <a @click="saveGame(game)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
                  <a class="play-button" @click="startGame(game)" v-show="game.status == 'ready' && !isNaN(parseInt(game.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
                  <a class="stop-button" @click="stopGame(game)" v-show="game.status != 'finished' && game.status != 'pending' && !isNaN(parseInt(game.player1_score,10)) && !isNaN(parseInt(game.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
                  <a class="cancel-button" @click="cancelGame(game)" v-show="game.status == 'finished' || game.status == 'running'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
                </div> 
              </td>
            </tr>
          </tbody>
        </table>  
        <h4 @click="() => {this.ready_expanded = !this.ready_expanded}" v-show="gamesByStatus.ready && gamesByStatus.ready.length">
          <span class="unselectable">Готовые встречи</span>
          <i class="fa-solid fa-angle-down expander" v-show="ready_expanded"></i>
          <i class="fa-solid fa-angle-right expander" v-show="!ready_expanded"></i>
        </h4>
        <table v-show="ready_expanded && gamesByStatus.ready && gamesByStatus.ready.length">
          <thead>
            <tr>
              <th @click="headerClick('ready', 'stage')">Этап</th>
              <th @click="headerClick('ready', 'game_number')">№ встречи</th>
              <th @click="headerClick('ready', 'table_name')" v-if="isEditor">Стол</th>
              <th @click="headerClick('ready', 'time')">Ожидание</th>
              <th @click="headerClick('ready', 'player1_name')">Имя</th>
              <th v-if="handicap_id">Фора</th>
              <th colspan="2" class="wide-header" v-if="isEditor">Счет</th>
              <th v-if="handicap_id">Фора</th>
              <th @click="headerClick('ready', 'player2_name')">Имя</th>
              <th v-if="isEditor"></th>
            </tr>
          </thead>  
          <tbody>
            <tr v-for="game in sortedGamesByStatus.ready" :key="game.id">
              <td>{{game.stage}}</td>
              <td>{{game.game_number}}</td>
              <td v-if="isEditor" class="input-cell">
                <input type="number" v-model="game.table_name" @blur="gameTableNumberChange(game)" v-show="!updatingGame" v-if="!club_tables.length">
                <select v-else v-model="game.table_name" @change="gameClubTableChange(game)" v-show="!updatingGame">
                  <option value=""></option>
                  <option v-if="(game.status == 'running' || game.status == 'finished') && game.table_number" :value="game.table_name">{{game.table_name}}</option>
                  <option v-for="table in freeTables" :key="table.table_number" :value="table.table_name">{{table.table_name}}</option>
                </select>
                <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
              </td>
              <td>{{formatTime(game.time)}}</td>
              <td>{{game.player1_name}}</td>
              <td v-if="handicap_id">{{game.player1_handicap}}</td>
              <td v-if="isEditor" class="input-cell score-cell">
                <div>
                  <i class="fa-solid fa-minus" @click="game.player1_score--" v-show="!updatingGame"></i>
                  <input type="number" v-model="game.player1_score" v-show="!updatingGame">
                  <i class="fa-solid fa-plus" @click="game.player1_score++" v-show="!updatingGame"></i>
                  <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
                </div>
              </td>
              <td v-if="isEditor" class="input-cell score-cell">
                <div>
                  <i class="fa-solid fa-minus" @click="game.player2_score--" v-show="!updatingGame"></i>
                  <input type="number" v-model="game.player2_score" v-show="!updatingGame">
                  <i class="fa-solid fa-plus" @click="game.player2_score++" v-show="!updatingGame"></i>
                  <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
                </div>
              </td>
              <td v-if="handicap_id">{{game.player2_handicap}}</td>
              <td>{{game.player2_name}}</td>
              <td v-if="isEditor">
                <div class="game-controls">
                  <a @click="saveGame(game)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
                  <a class="play-button" @click="startGame(game)" v-show="game.status == 'ready' && !isNaN(parseInt(game.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
                  <a class="stop-button" @click="stopGame(game)" v-show="game.status != 'finished' && game.status != 'pending' && !isNaN(parseInt(game.player1_score,10)) && !isNaN(parseInt(game.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
                  <a class="cancel-button" @click="cancelGame(game)" v-show="game.status == 'finished' || game.status == 'running'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
                </div> 
              </td>
            </tr>
          </tbody>
        </table>  
        <h4 @click="() => {this.finished_expanded = !this.finished_expanded}" v-show="gamesByStatus.finished && gamesByStatus.finished.length">
          <span class="unselectable">Завершенные встречи</span>
          <i class="fa-solid fa-angle-down expander" v-show="finished_expanded"></i>
          <i class="fa-solid fa-angle-right expander" v-show="!finished_expanded"></i>
        </h4>
        <table v-show="finished_expanded && gamesByStatus.finished && gamesByStatus.finished.length">
          <thead>
            <tr>
              <th @click="headerClick('finished', 'stage')">Этап</th>
              <th @click="headerClick('finished', 'table_name')">Стол</th>
              <th @click="headerClick('finished', 'time')">Длительнось</th>
              <th @click="headerClick('finished', 'player1_name')">Имя</th>
              <th v-if="handicap_id">Фора</th>
              <th colspan="2" class="wide-header">Счет</th>
              <th v-if="handicap_id">Фора</th>
              <th @click="headerClick('finished', 'player2_name')">Имя</th>
              <th v-if="isEditor"></th>
            </tr>
          </thead>  
          <tbody>
            <tr v-for="game in sortedGamesByStatus.finished" :key="game.id">
              <td>{{game.stage}}</td>
              <td :class="{'input-cell': isEditor}">
                <input type="number" v-if="isEditor && !updatingGame" v-model="game.table_name" @blur="gameTableNumberChange(game)">
                <i class="fas fa-spinner fa-pulse" v-show="isEditor && updatingGame"></i> 
                <span v-if="!isEditor">{{game.table_name}}</span>
              </td>
              <td>{{formatTime(game.time)}}</td>
              <td>{{game.player1_name}}</td>
              <td v-if="handicap_id">{{game.player1_handicap}}</td>
              <td :class="{'input-cell score-cell': isEditor}">
                <div v-if="isEditor">
                  <i class="fa-solid fa-minus" @click="game.player1_score--" v-show="!updatingGame"></i>
                  <input type="number" v-model="game.player1_score" v-show="!updatingGame">
                  <i class="fa-solid fa-plus" @click="game.player1_score++" v-show="!updatingGame"></i>
                  <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
                </div>
                <span v-else>{{game.player1_score}}</span>
              </td>
              <td :class="{'input-cell score-cell': isEditor}">
                <div v-if="isEditor">
                  <i class="fa-solid fa-minus" @click="game.player2_score--" v-show="!updatingGame"></i>
                  <input type="number" v-model="game.player2_score" v-show="!updatingGame">
                  <i class="fa-solid fa-plus" @click="game.player2_score++" v-show="!updatingGame"></i>
                  <i class="fas fa-spinner fa-pulse" v-show="updatingGame"></i> 
                </div>
                <span v-else>{{game.player2_score}}</span>
              </td>
              <td v-if="handicap_id">{{game.player2_handicap}}</td>
              <td>{{game.player2_name}}</td>
              <td v-if="isEditor">
                <div class="game-controls">
                  <a @click="saveGame(game)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
                  <a class="play-button" @click="startGame(game)" v-show="game.status == 'ready' && !isNaN(parseInt(game.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
                  <a class="stop-button" @click="stopGame(game)" v-show="game.status != 'finished' && game.status != 'pending' && !isNaN(parseInt(game.player1_score,10)) && !isNaN(parseInt(game.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
                  <a class="cancel-button" @click="cancelGame(game)" v-show="game.status == 'finished' || game.status == 'running'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
                </div> 
              </td>
            </tr>
          </tbody>
        </table>  
        <h4 @click="() => {this.pending_expanded = !this.pending_expanded}" v-show="gamesByStatus.pending && gamesByStatus.pending.length">
          <span class="unselectable">Предстоящие встречи</span>
          <i class="fa-solid fa-angle-down expander" v-show="pending_expanded"></i>
          <i class="fa-solid fa-angle-right expander" v-show="!pending_expanded"></i>
        </h4>
        <table v-show="pending_expanded && gamesByStatus.pending && gamesByStatus.pending.length">
          <thead>
            <tr>
              <th @click="headerClick('pending', 'stage')">Этап</th>
              <th @click="headerClick('pending', 'player1_name')">Имя</th>
              <th @click="headerClick('pending', 'player2_name')">Имя</th>
            </tr>
          </thead>  
          <tbody>
            <tr v-for="game in sortedGamesByStatus.pending" :key="game.id">
              <td>{{game.stage}}</td>
              <td>{{game.player1_name}}</td>
              <td>{{game.player2_name}}</td>
            </tr>
          </tbody>
        </table>  
      </div>
      <div id="mobile-content" v-show="!loading" v-else>
        <div class="category-container" id='running-games' v-show="gamesByStatus.running && gamesByStatus.running.length">
          <div class="category-header" :class="{'hidden': !running_expanded}">
            <h4 @click="() => {this.running_expanded = !this.running_expanded}">
              <span class="unselectable">Текущие встречи</span>
              <i class="fa-solid fa-angle-down expander" v-show="running_expanded"></i>
              <i class="fa-solid fa-angle-right expander" v-show="!running_expanded"></i>
            </h4>
            <a v-show="running_expanded" class="sort-button" :class="{'active': sort_options.running.sortSelecting}" @click="sort_options.running.sortSelecting = !sort_options.running.sortSelecting">
              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </a>
            <div class="sort-options" v-show="sort_options.running.sortSelecting" v-click-outside="() => sort_options.running.sortSelecting = false">
              <label @click="headerClick('running', 'stage'); sort_options.running.sortSelecting = false">Этап</label>
              <label @click="headerClick('running', 'table_name'); sort_options.running.sortSelecting = false">Стол</label>
              <label @click="headerClick('running', 'time'); sort_options.running.sortSelecting = false">Длительность</label>
              <label @click="headerClick('running', 'player1_name'); sort_options.running.sortSelecting = false">Игрок 1</label>
              <label @click="headerClick('running', 'player2_name'); sort_options.running.sortSelecting = false">Игрок 2</label>
            </div>
          </div>
          <div class="category-content" v-show="running_expanded && gamesByStatus.running && gamesByStatus.running.length">
            <div v-for="game in sortedGamesByStatus.running" :key="game.id" class='statement-string'>
              <div class="statement-string-header">
                <div class="table">
                  <label v-if="isEditor">Стол: </label>
                  <input type="number" v-if="isEditor && !updatingGame" v-model="game.table_name" @blur="gameTableNumberChange(game)" @focus="$event.target.select()">
                  <i class="fas fa-spinner fa-pulse" v-show="isEditor && updatingGame"></i> 
                  <label v-if="!isEditor">{{`Стол: ${game.table_name}`}}</label>
                </div>
                <div class="stage">
                  <label>{{game.stage}}</label>
                </div>
                <div class="time">
                  <label>{{`Длительность: ${formatTime((now_timestamp - parseInt(game.start_time,10))/1000)}`}}</label>
                </div>
              </div>
              <div class="statement-string-content">
                <div class="participant-info player1">
                  <div class="name">
                    <label>{{tournament_team ? game.player1_name : formatName(game.player1_name)}}</label>
                  </div>
                  <div class="scores">
                    <div class="handicap" v-show="game.player1_handicap">
                      <label>Фора: </label>
                      <label>{{game.player1_handicap}}</label>
                    </div>
                    <div class="score">
                      <label>Счет: </label>
                      <input type="number" v-model="game.player1_score" v-show="!updatingGame" @focus="$event.target.select()" v-if="isEditor">
                      <i class="fas fa-spinner fa-pulse" v-show="updatingGame" v-if="isEditor"></i> 
                      <label v-else>{{game.player1_score}}</label>
                    </div>
                  </div>
                </div>
                <div class="participant-info">
                  <div class="name">
                    <label>{{tournament_team ? game.player2_name : formatName(game.player2_name)}}</label>
                  </div>
                  <div class="scores">
                    <div class="score">
                      <label>Счет: </label>
                      <input type="number" v-model="game.player2_score" v-show="!updatingGame" @focus="$event.target.select()" v-if="isEditor">
                      <i class="fas fa-spinner fa-pulse" v-show="updatingGame" v-if="isEditor"></i> 
                      <label v-else>{{game.player2_score}}</label>
                    </div>
                    <div class="handicap" v-show="game.player2_handicap">
                      <label>Фора: </label>
                      <label>{{game.player2_handicap}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="game-controls" v-if="isEditor">
                <a @click="saveGame(game)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
                <a class="play-button" @click="startGame(game)" v-show="game.status == 'ready' && !isNaN(parseInt(game.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
                <a class="stop-button" @click="stopGame(game)" v-show="game.status != 'finished' && game.status != 'pending' && !isNaN(parseInt(game.player1_score,10)) && !isNaN(parseInt(game.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
                <a class="cancel-button" @click="cancelGame(game)" v-show="game.status == 'finished' || game.status == 'running'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
              </div> 
            </div>
          </div>
        </div>
        <div class="category-container" id='ready-games' v-show="gamesByStatus.ready && gamesByStatus.ready.length">
          <div class="category-header" :class="{'hidden': !ready_expanded}">
            <h4 @click="() => {this.ready_expanded = !this.ready_expanded}">
              <span class="unselectable">Готовые встречи</span>
              <i class="fa-solid fa-angle-down expander" v-show="ready_expanded"></i>
              <i class="fa-solid fa-angle-right expander" v-show="!ready_expanded"></i>
            </h4>
            <a v-show="ready_expanded" class="sort-button" :class="{'active': sort_options.ready.sortSelecting}" @click="sort_options.ready.sortSelecting = !sort_options.ready.sortSelecting">
              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </a>
            <div class="sort-options" v-show="sort_options.ready.sortSelecting" v-click-outside="() => sort_options.ready.sortSelecting = false">
              <label @click="headerClick('ready', 'stage'); sort_options.ready.sortSelecting = false">Этап</label>
              <label @click="headerClick('ready', 'game_number'); sort_options.ready.sortSelecting = false">№ встречи</label>
              <label @click="headerClick('ready', 'time'); sort_options.ready.sortSelecting = false">Ожидание</label>
              <label @click="headerClick('ready', 'player1_name'); sort_options.ready.sortSelecting = false">Игрок 1</label>
              <label @click="headerClick('ready', 'player2_name'); sort_options.ready.sortSelecting = false">Игрок 2</label>
            </div>
          </div>
          <div class="category-content" v-show="ready_expanded && gamesByStatus.ready && gamesByStatus.ready.length">
            <div v-for="game in sortedGamesByStatus.ready" :key="game.id" class='statement-string'>
              <div class="statement-string-header">
                <div class="game_number">{{`Встреча ${game.game_number}`}}</div>
                <div class="table">
                  <label v-if="isEditor">Стол: </label>
                  <input type="number" v-if="isEditor && !updatingGame" v-model="game.table_name" @blur="gameTableNumberChange(game)" @focus="$event.target.select()">
                  <i class="fas fa-spinner fa-pulse" v-show="isEditor && updatingGame"></i> 
                </div>
                <div class="stage">
                  <label>{{game.stage}}</label>
                </div>
                <div class="time">
                  <label>{{`Ожидание: ${formatTime((now_timestamp - parseInt(game.ready_time,10))/1000)}`}}</label>
                </div>
              </div>
              <div class="statement-string-content">
                <div class="participant-info player1">
                  <div class="name">
                    <label>{{tournament_team ? game.player1_name : formatName(game.player1_name)}}</label>
                  </div>
                  <div class="scores">
                    <div class="handicap" v-show="game.player1_handicap">
                      <label>Фора: </label>
                      <label>{{game.player1_handicap}}</label>
                    </div>
                    <div class="score">
                      <label>Счет: </label>
                      <input type="number" v-model="game.player1_score" v-show="!updatingGame" @focus="$event.target.select()" v-if="isEditor">
                      <i class="fas fa-spinner fa-pulse" v-show="updatingGame" v-if="isEditor"></i> 
                      <label v-else>{{game.player1_score}}</label>
                    </div>
                  </div>
                </div>
                <div class="participant-info">
                  <div class="name">
                    <label>{{tournament_team ? game.player2_name : formatName(game.player2_name)}}</label>
                  </div>
                  <div class="scores">
                    <div class="score">
                      <label>Счет: </label>
                      <input type="number" v-model="game.player2_score" v-show="!updatingGame" @focus="$event.target.select()" v-if="isEditor">
                      <i class="fas fa-spinner fa-pulse" v-show="updatingGame" v-if="isEditor"></i> 
                      <label v-else>{{game.player2_score}}</label>
                    </div>
                    <div class="handicap" v-show="game.player2_handicap">
                      <label>Фора: </label>
                      <label>{{game.player2_handicap}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="game-controls" v-if="isEditor">
                <a @click="saveGame(game)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
                <a class="play-button" @click="startGame(game)" v-show="game.status == 'ready' && !isNaN(parseInt(game.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
                <a class="stop-button" @click="stopGame(game)" v-show="game.status != 'finished' && game.status != 'pending' && !isNaN(parseInt(game.player1_score,10)) && !isNaN(parseInt(game.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
                <a class="cancel-button" @click="cancelGame(game)" v-show="game.status == 'finished' || game.status == 'running'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
              </div> 
            </div>
          </div>
        </div>
        <div class="category-container" id='finished-games' v-show="gamesByStatus.finished && gamesByStatus.finished.length">
          <div class="category-header" :class="{'hidden': !finished_expanded}">
            <h4 @click="() => {this.finished_expanded = !this.finished_expanded}">
              <span class="unselectable">Завершенные встречи</span>
              <i class="fa-solid fa-angle-down expander" v-show="finished_expanded"></i>
              <i class="fa-solid fa-angle-right expander" v-show="!finished_expanded"></i>
            </h4>
            <a v-show="finished_expanded" class="sort-button" :class="{'active': sort_options.finished.sortSelecting}" @click="sort_options.finished.sortSelecting = !sort_options.finished.sortSelecting">
              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </a>
            <div class="sort-options" v-show="sort_options.finished.sortSelecting" v-click-outside="() => sort_options.finished.sortSelecting = false">
              <label @click="headerClick('finished', 'stage'); sort_options.finished.sortSelecting = false">Этап</label>
              <label @click="headerClick('finished', 'table_name'); sort_options.finished.sortSelecting = false">Стол</label>
              <label @click="headerClick('finished', 'player1_name'); sort_options.finished.sortSelecting = false">Игрок 1</label>
              <label @click="headerClick('finished', 'player2_name'); sort_options.finished.sortSelecting = false">Игрок 2</label>
            </div>
          </div>
          <div class="category-content" v-show="finished_expanded && gamesByStatus.finished && gamesByStatus.finished.length">
            <div v-for="game in sortedGamesByStatus.finished" :key="game.id" class='statement-string'>
              <div class="statement-string-header">
                <div class="table">
                  <label v-if="isEditor">Стол: </label>
                  <input type="number" v-if="isEditor && !updatingGame" v-model="game.table_name" @blur="gameTableNumberChange(game)" @focus="$event.target.select()">
                  <i class="fas fa-spinner fa-pulse" v-show="isEditor && updatingGame"></i> 
                  <label v-if="!isEditor">{{`Стол: ${game.table_name}`}}</label>
                </div>
                <div class="stage">
                  <label>{{game.stage}}</label>
                </div>
                <div class="time">
                  <label>{{`Длительность: ${formatTime((parseInt(game.end_time,10) - parseInt(game.start_time,10))/1000)}`}}</label>
                </div>
              </div>
              <div class="statement-string-content">
                <div class="participant-info player1">
                  <div class="name">
                    <label>{{tournament_team ? game.player1_name : formatName(game.player1_name)}}</label>
                  </div>
                  <div class="scores">
                    <div class="handicap" v-show="game.player1_handicap">
                      <label>Фора: </label>
                      <label>{{game.player1_handicap}}</label>
                    </div>
                    <div class="score">
                      <label>Счет: </label>
                      <input type="number" v-model="game.player1_score" v-show="!updatingGame" @focus="$event.target.select()" v-if="isEditor">
                      <i class="fas fa-spinner fa-pulse" v-show="updatingGame" v-if="isEditor"></i> 
                      <label v-else>{{game.player1_score}}</label>
                    </div>
                  </div>
                </div>
                <div class="participant-info">
                  <div class="name">
                    <label>{{tournament_team ? game.player2_name : formatName(game.player2_name)}}</label>
                  </div>
                  <div class="scores">
                    <div class="score">
                      <label>Счет: </label>
                      <input type="number" v-model="game.player2_score" v-show="!updatingGame" @focus="$event.target.select()" v-if="isEditor">
                      <i class="fas fa-spinner fa-pulse" v-show="updatingGame" v-if="isEditor"></i> 
                      <label v-else>{{game.player2_score}}</label>
                    </div>
                    <div class="handicap" v-show="game.player2_handicap">
                      <label>Фора: </label>
                      <label>{{game.player2_handicap}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="game-controls" v-if="isEditor">
                <a @click="saveGame(game)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
                <a class="play-button" @click="startGame(game)" v-show="game.status == 'ready' && !isNaN(parseInt(game.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
                <a class="stop-button" @click="stopGame(game)" v-show="game.status != 'finished' && game.status != 'pending' && !isNaN(parseInt(game.player1_score,10)) && !isNaN(parseInt(game.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
                <a class="cancel-button" @click="cancelGame(game)" v-show="game.status == 'finished' || game.status == 'running'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
              </div> 
            </div>
          </div>
        </div>
        <div class="category-container" id='pending-games' v-show="gamesByStatus.pending && gamesByStatus.pending.length">
          <div class="category-header" :class="{'hidden': !pending_expanded}">
            <h4 @click="() => {this.pending_expanded = !this.pending_expanded}">
              <span class="unselectable">Предстоящие встречи</span>
              <i class="fa-solid fa-angle-down expander" v-show="pending_expanded"></i>
              <i class="fa-solid fa-angle-right expander" v-show="!pending_expanded"></i>
            </h4>
            <a v-show="pending_expanded" class="sort-button" :class="{'active': sort_options.pending.sortSelecting}" @click="sort_options.pending.sortSelecting = !sort_options.pending.sortSelecting">
              <i class="fa-solid fa-arrow-down-short-wide"></i>
            </a>
            <div class="sort-options" v-show="sort_options.pending.sortSelecting" v-click-outside="() => sort_options.pending.sortSelecting = false">
              <label @click="headerClick('pending', 'stage'); sort_options.pending.sortSelecting = false">Этап</label>
              <label @click="headerClick('pending', 'player1_name'); sort_options.pending.sortSelecting = false">Игрок 1</label>
              <label @click="headerClick('pending', 'player2_name'); sort_options.pending.sortSelecting = false">Игрок 2</label>
            </div>
          </div>
          <div class="category-content" v-show="pending_expanded && gamesByStatus.pending && gamesByStatus.pending.length">
            <div v-for="game in sortedGamesByStatus.pending" :key="game.id" class='statement-string'>
              <div class="statement-string-header">
                <div class="stage">
                  <label>{{game.stage}}</label>
                </div>
              </div>
              <div class="statement-string-content">
                <div class="participant-info player1">
                  <div class="name">
                    <label>{{tournament_team ? game.player1_name : formatName(game.player1_name)}}</label>
                  </div>
                </div>
                <div class="participant-info">
                  <div class="name">
                    <label>{{tournament_team ? game.player2_name : formatName(game.player2_name)}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-notifications" v-if="isEditor" v-show="notifications.length">
      <div v-for="notification in notifications" :key="notification" class="chart-notification">{{ notification }}</div>
    </div>
  </div> 
</template>

<script>
import axios from 'axios'
export default {
  name: 'TournamentStatement',
  data: function() {
    return {
      username: null,
      role: null,
      participants: [],
      fetchedGames: [],
      tournament_id: null,
      tournament_name: null,
      tournament_team: false,
      gameType: null,
      teams: [],
      team_members: [],
      handicap_id: null,
      handicap_relations: null,
      owner: '',
      loading: false,
      error: '',
      running_expanded: true,
      ready_expanded: true,
      finished_expanded: true,
      pending_expanded: false,
      updatingGame: false,
      now_timestamp: null,
      club_id: null,
      club: null,
      club_tables: [],
      games_previous_tables: {},
      sort_options: {
        running: {
          previousParticipantsSort: null,
          participantsSort: null,
          sortSelecting: false
        },
        ready: {
          previousParticipantsSort: null,
          participantsSort: null,
          sortSelecting: false
        },
        finished: {
          previousParticipantsSort: null,
          participantsSort: null,
          sortSelecting: false
        },
        pending: {
          previousParticipantsSort: null,
          participantsSort: null,
          sortSelecting: false
        },
      }
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    games () { 
      return this.fetchedGames.map((game) => {
        if (this.handicap_id) {
          let found = 0;
          this.participants.forEach(part => {
            if (part.id == game.player1) {
              if (this.tournament_team) {
                game.player1_group = this.teamHandicapGroup(this.participantsById[game.player1].team_id);
              } else {
                game.player1_group = part.group;
              }
              found++;
            }
            if (part.id == game.player2) {
              if (this.tournament_team) {
                game.player2_group = this.teamHandicapGroup(this.participantsById[game.player2].team_id);
              } else {
                game.player2_group = part.group;
              }
              found++;
            }
            if (found > 1) return
          });
          if (this.handicap_relations && game.player1_group && game.player2_group) {
            if (game.player1_group == game.player2_group) {
              if (this.gameType != 2) {
                game.player1_handicap = 8;
                game.player2_handicap = 8;
              } else {
                game.player1_handicap = game.game_till;
                game.player2_handicap = game.game_till;
              }
            } else {
              if (this.gameType != 2) {
                if (this.handicap_relations[8]) {
                  game.player1_handicap = this.handicap_relations[8][game.player1_group][game.player2_group];
                  game.player2_handicap = this.handicap_relations[8][game.player2_group][game.player1_group];
                }
              } else {
                if (this.handicap_relations[game.game_till]) {
                  game.player1_handicap = this.handicap_relations[game.game_till][game.player1_group][game.player2_group];
                  game.player2_handicap = this.handicap_relations[game.game_till][game.player2_group][game.player1_group];
                }
              }
            }
          }
        }
        game.table_name = game.table_number;
        if (game.table_number && this.club_tables.length) {
          this.club_tables.forEach(table => {
            if (table.table_number == game.table_number) {
              game.table_name = table.table_name;
              return
            }
          });
        }
        return game;
      });
    },
    participantsById() {
      let result = {};
      this.participants.forEach(part => {
        result[part.id] = part;
      });
      return result
    },
    gamesByStatus() {
      let result = {}
      this.games.forEach(game => {
        if (game.player1 == "0" || game.player2 == "0") return
        if (!result[game.status]) {
          result[game.status] = [];
        }
        result[game.status].push(game);
        if (this.participantsById[game.player1]) {
          if (this.tournament_team) game.player1_name = this.teamsById[this.participantsById[game.player1].team_id][0].name;
          else game.player1_name = this.participantsById[game.player1].name;
        }
        else game.player1_name = "";
        if (this.participantsById[game.player2]) {
          if (this.tournament_team) game.player2_name = this.teamsById[this.participantsById[game.player2].team_id][0].name;
          else game.player2_name = this.participantsById[game.player2].name
        }
        else game.player2_name = "";
        if (game.status == 'running') {
          game.time = (this.now_timestamp - parseInt(game.start_time,10))/1000
        } else if (game.status == 'ready') {
          game.time = (this.now_timestamp - parseInt(game.ready_time,10))/1000
        } else if (game.status == 'finished' && game.end_time && game.start_time) {
          game.time = (parseInt(game.end_time,10) - parseInt(game.start_time,10))/1000
        }
      });
      return result
    },
    sortedGamesByStatus() {
      let result = { ...this.gamesByStatus };
      Object.keys(this.sort_options).forEach(table => {
        if (this.sort_options[table].participantsSort == null) return
        let ascending = true;
        if (this.sort_options[table].participantsSort == this.sort_options[table].previousParticipantsSort) ascending = false;
        if (result[table]) {
          result[table].sort(this.dynamicSort(this.sort_options[table].participantsSort, ascending));
        }
      });
      return result
    },
    isMobile() {
      return screen.width <= 1000;
    },
    freeTables() {
      let tables_by_status = this.groupArrayBy(this.club_tables, 'status');
      return tables_by_status.close;
    },
    teamsById() {
      if (!this.tournament_team) return null;
      return this.groupArrayBy(this.teams, 'id');
    },
    teamMembersByTeamId() {
      if (!this.tournament_team) return null;
      return this.groupArrayBy(this.team_members, 'team_id');
    },
    notifications() {
      let notifications = [];
      if (this.games && this.handicap_relations && this.gameType == 2) {
        let game_tills = [];
        this.games.forEach(game => {
          if (!game_tills.includes(game.game_till)) {
            game_tills.push(game.game_till);
          }
        });
        game_tills.forEach(game_till => {
          if (!this.handicap_relations[game_till]) {
            notifications.push(`В выбранном гандикапе нет распределения фор для игры до ${game_till} шаров!\n Для встреч до ${game_till} шаров фора отображаться не будет.`);
          }
        });
      }
      return notifications;
    }
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.name = '';
      this.info = '';
      axios
      .get('/api/tournament/details', { params: { id: this.$route.params.id } })
      .then(response => {
        this.participants = response.data.participants;
        this.owner = response.data.owner;
        this.club_id = response.data.club_id;
        if (this.club_id) this.getClub(this.club_id);
        this.tournament_id = response.data.id;
        this.tournament_name = response.data.name;
        this.gameType = response.data.game_type;
        this.fetchedGames = response.data.games;
        this.fetchedGames.forEach(game => {
          if (game.status == 'running') this.games_previous_tables[game.id] = game.table_number;
        });
        this.handicap_id = response.data.handicap_id;
        if (this.handicap_id) {
          this.getHandicap(this.handicap_id);
        }
        this.tournament_team = response.data.team;
        if (this.tournament_team) {
          this.teams = response.data.teams;
          this.team_members = response.data.team_members;
        }
      })
      .catch(error => {
        console.log(error);
        this.error = error;
        this.loading = false;
      })
      .finally(() => this.loading = false)
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    getHandicap(id) {
      axios
      .get('/api/handicap/get', { params: { id: id } })
      .then(response => {
        this.handicap_relations = response.data[0].relations;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
    },
    gameTableNumberChange(game) {
      this.error = '';
      game.table_number = game.table_name;
      if (game.status == 'ready') {
        for (let i=0; i<this.games.length; i++) {
          if (this.games[i].id == game.id) continue
          if (this.games[i].status != 'running') continue
          if (game.table_number == this.games[i].table_number) {
            this.error = 'Стол с таким номером уже играет';
            game.table_number = null;
            return;
          }
        }
        return
      }
      this.switchingPlayer = null;
    },
    gameClubTableChange(game) {
      this.error = '';
      let table = null;
      if (!game.table_name) {
        game.table_number = null;
      } else {
        this.club_tables.forEach(t => {
          if (t.table_name == game.table_name) {
            table = t;
            game.table_number = table.table_number;
            return;
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
      }
      if (game.status == 'ready') {
        for (let i=0; i<this.games.length; i++) {
          if (this.games[i].id == game.id) continue
          if (this.games[i].status != 'running') continue
          if (game.table_number == this.games[i].table_number) {
            this.error = 'Стол с таким номером уже играет';
            game.table_number = null;
            return;
          }
        }
        return
      }
      this.switchingPlayer = null;
    },
    saveGame(game) {
      this.loading = true;
      this.error = '';
      axios
      .post('/api/tournament/game/save', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to cancel game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
      this.updateAllTables();
    },
    startGame(game) {
      this.error = '';
      this.loading = true;
      if (game.table_number && this.club_tables.length) {
        let table = null;
        this.club_tables.forEach(t => {
          if (t.table_number == game.table_number) {
            table = t;
            return
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
        table.status = 'open';
        this.updateTable(table);
      }
      axios
      .post('/api/tournament/game/start', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to start game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    stopGame(game) {
      this.loading = true;
      this.error = '';
      if (game.table_number && this.club_tables.length) {
        let table = null;
        this.club_tables.forEach(t => {
          if (t.table_number == game.table_number) {
            table = t;
            return
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
        table.status = 'close';
        this.updateTable(table);
      }
      axios
      .post('/api/tournament/game/stop', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to stop game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    cancelGame(game) {
      this.error = '';
      this.loading = true;
      if (game.table_number && this.club_tables.length) {
        let table = null;
        this.club_tables.forEach(t => {
          if (t.table_number == game.table_number) {
            table = t;
            return
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
        table.status = 'close';
        this.updateTable(table);
      }
      game.table_number = null;
      axios
      .post('/api/tournament/game/cancel', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to cancel game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    getNow() {
      this.now_timestamp = Date.now();
    },
    formatTime(seconds) {
      let result = '';
      let secs = seconds;
      let hours = Math.floor(secs/3600);
      if (hours >= 1) {
        result += `${hours}ч. `;
        secs = secs - (hours * 3600);
      }
      let minutes = Math.round(secs/60);
      result += `${minutes}м. `;
      return result;
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    },
    updateTable(table) {
      this.loading = true;
      this.error = null;
      axios
      .post('/api/club/edit/table', { club_id: table.club_id, id: table.id, status: table.status }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        let tables = response.data;
        let table_type = 'pyramid';
        if (this.gameType == '7') table_type = 'snooker';
        if (this.gameType == '8') table_type = 'pool';
        this.club_tables = this.groupArrayBy(tables, 'type')[table_type];
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    updateAllTables() {
      let tables_by_number = this.groupArrayBy(this.club_tables, 'table_number');
      this.games.forEach(game=>{
        if (game.status != 'running') return;
        //if table number was changed
        if (this.games_previous_tables[game.id] && this.games_previous_tables[game.id] != game.table_number) {
          let previous_table = tables_by_number[this.games_previous_tables[game.id]][0];
          previous_table.status = 'close'
          this.updateTable(previous_table);
          if (game.table_number) {
            this.games_previous_tables[game.id] = game.table_number;
          } else {
            delete this.games_previous_tables[game.id];
          }
        }
        if (!game.table_number) return;
        let table = tables_by_number[game.table_number][0];
        if (table.status == 'close') {
          table.status = 'open';
          this.updateTable(table);
        }
      });
    },
    getClub(id) {
      axios
      .get('/api/club/get', { params: { id: id } })
      .then(response => {
        this.club = response.data;
        let table_type = 'pyramid';
        this.club_tables = this.groupArrayBy(this.club.tables, 'type')[table_type];
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
    },
    dynamicSort(property, ascending) {
      return function (a,b) {
        if (!isNaN(parseInt(a[property],10)) && !isNaN(parseInt(b[property],10)) && !a[property].toString().includes('-') && !b[property].toString().includes('-')) {
          a[property] = parseInt(a[property],10);
          b[property] = parseInt(b[property],10);
        }
        if (ascending){
          return (a[property] > b[property]) ? 1 : -1;
        } else {
          return (a[property] < b[property]) ? 1 : -1;
        }
      }
    },
    headerClick(table, property) {
      if (this.sort_options[table].previousParticipantsSort == this.sort_options[table].participantsSort) this.sort_options[table].previousParticipantsSort = null;
      else this.sort_options[table].previousParticipantsSort = this.sort_options[table].participantsSort;
      this.sort_options[table].participantsSort = property;
      this.sort_options = { ...this.sort_options };
      this.sort_options[table].sortSelecting = false;
      localStorage.statementSortOptions = JSON.stringify(this.sort_options);
    },
    teamHandicapGroup(team_id) {
      if (!team_id) return
      if (!this.handicap_id) return
      let group_sum = 0;
      if (!this.teamMembersByTeamId[team_id] || !this.teamMembersByTeamId[team_id].length) {
        return null;
      }
      this.teamMembersByTeamId[team_id].forEach(member => {
        if (member.group) {
          group_sum += member.group;
        }
      })
      // return Math.round(group_sum / this.teamMembersByTeamId[team_id].length);
      return group_sum
    }
  },
  created() {
    this.getDetails();
    this.username = localStorage.username;
    this.role = localStorage.role;
    if (localStorage.statementSortOptions) {
      this.sort_options = JSON.parse(localStorage.statementSortOptions);
    }
    setInterval(this.getNow,60000);
    this.getNow();
  }
}
</script>

<style scoped>
#content {
  height: 100%;
}
#statement-div {
  width: 100%;
}
h4 {
  color: rgb(186 72 8);
  align-self: flex-start;
}
.wide-header {
  text-align: center;
}
.input-cell {
  padding: 0;
  height: 40px;
}
.score-cell > div {
  display: flex;
  height: 100%;
  align-items: center;
}
.score-cell input::-webkit-outer-spin-button,
.score-cell input::-webkit-inner-spin-button  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 15px;
}
.score-cell i {
  margin: 10px;
}
.score-cell i.fa-solid:hover {
  color: #248d24;
}
td input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 5px;
  margin: 0;
  box-sizing: border-box;
  background: inherit;
  text-align: center;
}
.expander {
  margin-left: 20px;
}
td .game-controls {
  margin-left: auto;
}
.game-controls a {
  font-size: 15px;
  text-decoration: none;
  color: #445142;
  padding: 3px;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 0;
  align-self: flex-start;
  background: inherit;
  border-radius: 5px;
}
.game-controls a:hover {
  color: #248d24;
}
.game-controls a.play-button {
  color: #248d24;
}
.game-controls a.play-button:hover {
  color: #84dc84;
}
.game-controls a.stop-button, .game-controls a.cancel-button {
  color: #e10808;
}
.game-controls a.stop-button:hover, .game-controls a.cancel-button:hover {
  color: #e77a7a;
}
.category-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  flex-direction: column;
  margin: 7px;
  overflow: hidden;
}
.category-header {
  border-bottom: 1px solid #ccc;
  align-self: stretch;
  padding: 10px;
  background: #f2f2f2;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
  overflow: visible;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  position: relative;
}
.category-header.hidden {
  border: none;
}
.category-header h4 {
  margin: 5px;
}
.category-header a {
  margin-left: auto;
}
.category-header .sort-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  z-index: 100;
  right: 0;
  top: 50px;
  padding: 10px;
}
.category-header .sort-options label {
  margin: 5px 5px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
}
.category-content {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
.statement-string {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  margin-bottom: -1px;
}
.statement-string-header {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
}
.statement-string-header > div {
  width: 33%;
  display: flex;  
}
.table label {
  display: flex;
  align-items: center;
}
.table input {
  width: 30px;
  font-size: 12px;
  text-align: center;
}
.stage {
  justify-content: center;
  font-weight: bold;
  color: #377824;
}
#pending-games .stage {
  width: 100%;
}
.time {
  justify-content: flex-end;
}
.statement-string-content {
  display: flex;
  justify-content: stretch;
  margin-bottom: 5px;
}
.participant-info {
  display: flex;
  flex-direction: column;
  padding: 7px;
  width: 50%;
}
.name {
  align-self: center;
  font-weight: bold;
  color: #4a4a4a;
}
.participant-info.player1 {
  border-right: 1px solid #ccc;
}
.scores {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}
.handicap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.score {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.player1 .score {
  margin-left: auto;
}
.scores label {
  margin: 3px;
}
.score input {
  width: 30px;
  font-size: 12px;
  text-align: center;
}
.statement-string .game-controls {
  align-self: center;
  margin: 5px;
}
</style>