<template>
  <div class="tabs">
    <router-link :to="`/ratings/${$route.params.id}/participants`"
      :class="{ active: this.$route.path.endsWith('/participants')}">Участники</router-link>
    <router-link :to="`/ratings/${$route.params.id}/tournaments`"
      :class="{ active: this.$route.path.endsWith('/tournaments')}">Турниры</router-link>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SpecificRatingsPage',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
