<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span class="error" v-show="error">{{error}}</span>
  <div class='actions' v-show="!loading">
    <a class='add-button' title="Добавить турнир" @click="create_tournament_dialog = true" v-show="!create_tournament_dialog"  v-if="isOperator"><i class="fa-solid fa-circle-plus"></i></a>
    <div class="search-div">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input type="text" placeholder="Поиск турниров..." v-model="searchString" v-on:keyup.enter="searchTournament(false)">
      <button class="search-button" :disabled="loading" v-on:click="searchTournament(false)">
        <span>Поиск</span>
      </button>
    </div>
  </div>
  <div id="content" v-if="!create_tournament_dialog" v-show="!loading">
    <div class="info-container" v-show="running_tournaments && running_tournaments.length">
      <div class="info-container-header">
        <span>Текущие турниры</span> 
      </div>
      <div class="info-container-content">
        <router-link v-for="(tournament, index) in running_tournaments" :key="tournament.id" :to="`/tournaments/${tournament.id}/info`" :class="{'last': index == running_tournaments.length - 1}">
          <div class='tournament-row'>
            <div class='name'>
              {{tournament.name}}
            </div>
            <div class='tournament-location'>
              <label class="place" v-if="tournament.club_name">{{tournament.club_name}}</label>
              <label class="date">{{formatFullDateTime(tournament.start_time)}}</label>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="info-container" v-show="pending_tournaments && pending_tournaments.length">
      <div class="info-container-header">
        <span>Предстоящие турниры</span> 
      </div>
      <div class="info-container-content">
        <router-link v-for="(tournament, index) in pending_tournaments" :key="tournament.id" :to="`/tournaments/${tournament.id}/info`" :class="{'last': index == pending_tournaments.length - 1}">
          <div class='tournament-row'>
            <div class='name'>
              {{tournament.name}}
            </div>
            <div class='tournament-location'>
              <label class="place" v-if="tournament.club_name">{{tournament.club_name}}</label>
              <label class="date">{{formatFullDateTime(tournament.start_time)}}</label>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="info-container" v-show="finished_tournaments && finished_tournaments.length">
      <div class="info-container-header">
        <span>Прошедшиe турниры</span> 
      </div>
      <div class="info-container-content">
        <router-link v-for="(tournament, index) in finished_tournaments" :key="tournament.id" :to="`/tournaments/${tournament.id}/info`" :class="{'last': index == finished_tournaments.length - 1}">
          <div class='tournament-row'>
            <div class='name'>
              {{tournament.name}}
            </div>
            <div class='tournament-location'>
              <label class="place" v-if="tournament.club_name">{{tournament.club_name}}</label>
              <label class="date">{{formatFullDateTime(tournament.start_time)}}</label>
            </div>
          </div>
          <div class="winners">
            <div class="winner" v-for="winner in tournament.winners" :key="winner.place">
              <i class="fa-solid fa-medal" :class="'place-' + winner.place"></i>
              <label>{{tournament.team ? winner.name : formatName(winner.name)}}</label>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <button :disabled="more_loading" @click="getMore()">
      <i class="fas fa-spinner fa-pulse" v-if="more_loading"></i>
      <span v-else>Еще</span>
    </button>
  </div>
  <div class="create-dialog" v-else v-show="!loading">
    <label>Название</label>
    <input :disabled="creating_tournament" v-model="new_tournament_name">
    <div v-if="!creating_tournament">
      <button @click="createTournament()">Добавить</button>
      <button class="warning" @click="create_tournament_dialog = false">Отменить</button>
    </div>
    <div id="loader" v-else>
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'TournamentsPage',
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      error: '',
      searchString: '',
      tournaments: [],
      loading: true,
      create_tournament_dialog: false,
      creating_tournament: false,
      new_tournament_name: null,
      more_loading: false
    }
  },
  methods: {
    searchTournament(more=false) {
      if (this.searchString == '') {
        this.listTournaments();
        return;
      }
      let url = '/api/tournament/search';
      if (more) {
        url += `?max_id=${this.tournaments[this.tournaments.length - 1].id}`
        this.more_loading = true;
      } else {
        this.tournaments = [];
        this.loading = true;
      }
      axios
      .get(url, { params: { search: this.searchString } })
      .then(response => (this.tournaments = this.tournaments.concat(response.data)))
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
        this.more_loading = false;
      })
    },
    listTournaments(more=false) {
      let url = '/api/tournament/list';
      if (more) {
        url += `?max_id=${this.tournaments[this.tournaments.length - 1].id}`
        this.more_loading = true;
      } else {
        this.tournaments = [];
        this.loading = true;
      }
      axios
      .get(url)
      .then(response => {
          this.tournaments = this.tournaments.concat(response.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
        this.more_loading = false;
      })
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    formatFullDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month}.${year} ${hour}:${min}`;
      return result
    },
    createTournament() {
      if (this.new_tournament_name == '') return
      this.creating_tournament = true;
      this.error = '';
      axios
      .post('/api/tournament/create', {name: this.new_tournament_name}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.$router.push({ path: `/tournaments/${response.data.id}/info` })
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать турнир'
        }
      })
      .finally(() => this.creating_tournament = false)
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    },
    getMore() {
      if (!this.searchString) {
        this.listTournaments(true);
      } else {
        this.searchTournament(true);
      }
    }
  },
  computed: {
    pending_tournaments() {
      return this.tournaments.filter(tournament => tournament.status == 'pending' && (!tournament.hidden || this.role == 'administrator' || tournament.owner == this.username));
    },
    running_tournaments() {
      return this.tournaments.filter(tournament => tournament.status == 'running' && (!tournament.hidden || this.role == 'administrator' || tournament.owner == this.username));
    },
    finished_tournaments() {
      let result = this.tournaments.filter(tournament => (tournament.status == 'finished' || tournament.status == null) && (!tournament.hidden || this.role == 'administrator' || tournament.owner == this.username));
      result.forEach(tournament => {
        if (tournament.winners) {
          tournament.winners.sort((b,a) => parseInt(b.place.split('-')[0], 10) - parseInt(a.place.split('-')[0], 10));
        }
      })
      return result;
    },
    isOperator() {
      return this.role == 'operator' || this.role == 'administrator'
    }
  },
  created() {
    this.listTournaments();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #content {
    align-self: center;
  }
  .info-container-content .tournament-location {
    display: flex;
    justify-content: space-between;
    color: rgb(103, 103, 103);
    font-size: 14px;
  }
  .winners {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    flex-wrap: wrap;
  }
  .winner {
    margin: 0 10px;
  }
  .winner label {
    color: #0c1b6a;
  }
  .place-1 {
    color: #c38908;
  }
  .place-2 {
    color: #8d8d8d;
  }
  .place-3, .place-3-4 {
    color: #cb5800;
  }
  .place-4 {
    color: #122d12;
  }
  .date {
    margin-left: auto;
  }
  .search-div {
    margin-left: auto;
  }
</style>
