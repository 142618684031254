<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <span v-if="error" class="error">{{error}}</span>
  <div id="content" v-show="name != ''" v-if="!isMobile">
    <div v-if="isEditor" id="name-div" v-show="!loading">
      <h1 v-if="!changingName">{{ name }}</h1>
      <input v-else v-model="name" v-on:keyup.enter="changeName">
      <a @click="changingName=!changingName" v-if="!changeNameInProgress"><i class="fa-solid fa-pencil"></i></a>
      <i class="fas fa-spinner fa-pulse" v-else></i>
    </div> 
    <h1 v-else>{{ name }}</h1>
    <div class="actions" v-if="isEditor">
      <a :disabled="rebuilding" @click="rebuildRating" title="Пересчитать">
        <i class="fa-solid fa-calculator" v-if="!rebuilding"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
      <a :disabled="clearing || !fetchedParticipants.length" @click="clearRating" title="Очистить" id="clear-button">
        <i class="fa-solid fa-snowplow" v-if="!clearing"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
      <a :disabled="deleting" @click="deleteRating" title="Удалить" id="delete-button">
        <i class="fa-solid fa-trash-can" v-if="!deleting"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
    </div>
    <div class="list-settings" v-show="fetchedParticipants.length">
      <div class="search-setting">
        <a class="search-button" @click="searchClicked()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </a>
        <div v-show="searching" class='search-string'>
          <input v-model='searchString' id='search-input'>
          <a class="search-clear-button" @click="searchString = null">
            <i class="fa-solid fa-xmark"></i>
          </a>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th @click="headerClick('place')">#</th>
          <th @click="headerClick('name')">Имя</th>
          <th @click="headerClick('birth')">Дата Рождения</th>
          <th @click="headerClick('city')">Город</th>
          <th @click="headerClick('rank')">Разряд</th>
          <th @click="headerClick('points')">Очки</th>
          <th @click="headerClick('tournaments')">Кол-во Турниров</th>
          <th @click="headerClick('game_win')">Выиграно Встреч</th>
          <th @click="headerClick('game_fail')">Проиграно Встреч</th>
          <th @click="headerClick('frame_win')">Выиграно Партий</th>
          <th @click="headerClick('frame_fail')">Проиграно Партий</th>
          <th @click="headerClick('efficiency')">Эффективность</th>
        </tr>
      </thead>  
      <tbody>
        <tr v-for="participant in sortedParticipants" :key="participant.id">
          <td>{{ participant.place }}</td>
          <td>
            <router-link :to="`/players/${participant.player_id}/info`" class='player-link'>
              {{participant.name}}
            </router-link>
          </td>
          <td>{{ participant.birth }}</td>
          <td>{{ participant.city }}</td>
          <td>{{ participant.rank }}</td>
          <td>{{ participant.points }}</td>
          <td>{{ participant.tournaments }}</td>
          <td>{{ participant.game_win }}</td>
          <td>{{ participant.game_fail }}</td>
          <td>{{ participant.frame_win }}</td>
          <td>{{ participant.frame_fail }}</td>
          <td>{{ participant.efficiency.toFixed(2) + '%' }}</td>
        </tr>
      </tbody>
    </table>     
  </div>
  <div id="mobile-content" v-else v-show="!loading">
    <div class="actions" v-if="isEditor">
      <a :disabled="rebuilding" @click="rebuildRating" title="Пересчитать">
        <i class="fa-solid fa-calculator" v-if="!rebuilding"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
      <a :disabled="clearing || !fetchedParticipants.length" @click="clearRating" title="Очистить" id="clear-button">
        <i class="fa-solid fa-snowplow" v-if="!clearing"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
      <a :disabled="deleting" @click="deleteRating" title="Удалить" id="delete-button">
        <i class="fa-solid fa-trash-can" v-if="!deleting"></i>
        <i class="fas fa-spinner fa-pulse" v-else></i>
      </a>
    </div>
    <div class="list-settings" v-show="fetchedParticipants.length">
      <div class="search-setting">
        <a class="search-button" @click="searchClicked()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </a>
        <div v-show="searching" class='search-string'>
          <input id='search-input' :value="searchString" @input="e => searchString = e.target.value">
          <a class="search-clear-button" @click="searchString = null">
            <i class="fa-solid fa-xmark"></i>
          </a>
        </div>
      </div>
      <div class="sort-setting" v-click-outside="() => sortSelecting = false">
        <a class="sort-button" :class="{'active': sortSelecting}" @click="sortSelecting = !sortSelecting">
          <i class="fa-solid fa-arrow-down-short-wide"></i>
        </a>
        <div class="sort-options" v-show="sortSelecting">
          <label @click="headerClick('name'); sortSelecting = false">Имя</label>
          <label @click="headerClick('points'); sortSelecting = false">Очки</label>
          <label @click="headerClick('tournaments'); sortSelecting = false">Турниры</label>
          <label @click="headerClick('game_win'); sortSelecting = false">Выиграно встреч</label>
          <label @click="headerClick('game_fail'); sortSelecting = false">Проиграно встреч</label>
          <label @click="headerClick('frame_win'); sortSelecting = false">Выиграно партий</label>
          <label @click="headerClick('frame_fail'); sortSelecting = false">Проиграно партий</label>
          <label @click="headerClick('efficiency'); sortSelecting = false">Эффективность</label>
        </div>
      </div>
    </div>
    <div class="participants-list" v-show="this.sortedParticipants.length">
      <div class="participant-string" v-for="participant in sortedParticipants" :key="participant.id">
        <div class="participant-header">
          <div class="index">
            <label>{{`#${participant.place}`}}</label>
          </div>
          <div class="name">
            <label>
              <router-link :to="`/players/${participant.player_id}/info`" class='player-link'>
                {{participant.name}}
              </router-link>
            </label>
          </div>
          <div class="points">
            <label>{{ 'Очки: ' + participant.points }}</label>
          </div>
        </div>
        <div class="participant-info-string">
          <div class="tournaments">
            <label>{{ 'Турниры: ' + participant.tournaments }}</label>
          </div>
          <div class="efficiency">
            <label>{{ 'Эффективность: ' + participant.efficiency.toFixed(2) + '%' }}</label>
          </div>
        </div>
        <div class="participant-info-string">
          <div class="games">
            <label>Встречи</label>
            <div class="results">
              <div class="result win">
                <label class="result-label">Выиграно:</label>
                <label class="result-value">{{ participant.game_win }}</label>
              </div>
              <div class="result fail">
                <label class="result-label">Проиграно:</label>
                <label class="result-value">{{ participant.game_fail }}</label>
              </div>
            </div>
          </div>
          <div class="frames">
            <label>Партии</label>
            <div class="results">
              <div class="result win">
                <label class="result-label">Выиграно:</label>
                <label class="result-value">{{ participant.frame_win }}</label>
              </div>
              <div class="result fail">
                <label class="result-label">Проиграно:</label>
                <label class="result-value">{{ participant.frame_fail }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'RatingParticipants',
  data: function() {
    return {
      role: null,
      username: null,
      id: null,
      name: '',
      owner: null,
      fetchedParticipants: [],
      loading: false,
      error: '',
      changingName: false,
      changeNameInProgress: false,
      participantsSort: 'place',
      previousParticipantsSort: 'place',
      rebuilding: false,
      clearing: false,
      deleting: false,
      sortSelecting: false,
      searching: false,
      searchString: null
    }
  },
  computed: {
    participants () { 
      return this.fetchedParticipants.map((player) => {
        let birth_str = "";
        if (player.birth_day != null) {
          let birth_day_str = (player.birth_day.toString().length == 1) ? '0' + player.birth_day : player.birth_day;
          birth_str+=birth_day_str + '.'
        }
        if (player.birth_month != null) {
          let birth_month_str = (player.birth_month.toString().length == 1) ? '0' + player.birth_month : player.birth_month;
          birth_str+=birth_month_str + '.';
        }
        if (player.birth_year != null) birth_str+=player.birth_year;
        player.birth = birth_str;
        if (player.city == 'null') player.city = '';
        if (!player.name) player.name = ' '
        if (isNaN(player.efficiency)) player.efficiency = 0;
        return player;
      });
    },
    filteredParticipants() {
      if (!this.searchString) {
        return this.participants;
      }
      return this.participants.filter(player => player.name && player.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1);
    },
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    sortedParticipants () {
      if (this.participantsSort == null) return this.filteredParticipants;
      let sorted = this.filteredParticipants;
      let ascending = false;
      if (this.participantsSort == this.previousParticipantsSort) ascending = true;
      sorted.sort(this.dynamicSort(this.participantsSort, ascending));
      return sorted
    },
    isMobile() {
      return screen.width <= 1000;
    }
  },
  methods: {
    loadRating() {
      this.error = '';
      this.loading = true;
      this.name = '';
      this.fetchedParticipants = [];
      axios
      .get('/api/rating/participants', { params: { id: this.$route.params.id } })
      .then(response => {
        this.id = response.data.id;
        this.name = response.data.name;
        this.owner = response.data.owner;
        this.fetchedParticipants = response.data.participants;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'failed to rebuild rating';
        }
      })
      .finally(() => this.loading = false)
    },
    changeName() {
      if (this.name == '') {
        this.error = 'Имя не может быть пустым';
        return
      }
      this.error = ''
      this.changeNameInProgress = true;
      axios
      .post('/api/rating/edit', {id: this.id, name: this.name}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (!response.data) {
          this.error = 'не удалось поменять имя';
        }
        this.changingName = false;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'не удалось поменять имя';
        }
      })
      .finally(() => {
        this.changeNameInProgress = false;
      })
    },
    dynamicSort(property, ascending) {
      return function (a,b) {
        if (ascending){
          return (a[property] > b[property]) ? 1 : -1;
        } else {
          return (a[property] < b[property]) ? 1 : -1;
        }
      }
    },
    headerClick(property) {
      if (this.previousParticipantsSort == this.participantsSort) this.previousParticipantsSort = null;
      else this.previousParticipantsSort = this.participantsSort;
      this.participantsSort = property;
    },
    rebuildRating() {
      this.error = '';
      this.rebuilding = true;
      this.fetchedParticipants = [];
      axios
      .post('/api/rating/rebuild', { id: this.$route.params.id }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.fetchedParticipants = response.data.participants;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'failed to rebuild rating';
        }
      })
      .finally(() => {
        this.rebuilding = false; 
      })
    },
    clearRating() {
      this.error = '';
      this.clearing = true;
      axios
      .post('/api/rating/clear', { id: this.$route.params.id }, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.fetchedParticipants = [];
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'failed to clear rating';
        }
      })
      .finally(() => {
        this.clearing = false; 
      })
    },
    deleteRating() {
      if (confirm('Вы уверены, что хотите удалить рейтинг?')) {
        this.error = '';
        this.deleting = true;
        axios
        .post('/api/rating/delete', { id: this.$route.params.id }, {headers: {'x-access-token': localStorage.token}})
        .then(response => {
          if (response) {
            this.$router.push({ path: '/ratings' })
          } else {
            this.error = 'failed to delete rating';
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          } else {
            this.error = 'failed to delete rating';
          }
        })
        .finally(() => {
          this.deleting = false; 
        })
      }
    },
    searchClicked() {
      this.searching = !this.searching;
      if (this.searching) {
        let input = document.getElementById('search-input');
        setTimeout(function() { input.focus() }, 1);
      }
    }
  },
  mounted() {
    this.role = localStorage.role;
    this.username = localStorage.username;
    this.loadRating();
  }
}
</script>

<style scoped>
  #name-div {
    display: flex;
    align-items: center;
  }
  h1 {
    font-size: 15px;
  }
  #name-div a {
    font-size: 15px;
    text-decoration: none;
    color: #445142;
    padding: 3px;
    margin: 5px;
    margin-top: 0;
    margin-bottom: 0;
    background: inherit;
    border-radius: 5px;
  }
  #name-div a:hover {
    color: #248d24;
  }
  #name-div i {
    margin: 5px;
  }
  .actions {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .actions a {
    font-size: 20px;
    text-decoration: none;
    color: #445142;
    padding: 3px;
    margin: 5px;
    margin-top: 0;
    margin-bottom: 0;
    align-self: flex-start;
    background: inherit;
    border-radius: 5px;
  }
  .actions a:hover {
    color: #248d24;
  }
  #clear-button {
    margin-left: auto;
  }
  #clear-button, #delete-button {
    color: #b50000;
  }
  #clear-button:hover, #delete-button:hover {
    color: #e77a7a;
  }
  #mobile-content {
    align-self: stretch;
    margin: 5px;
  }
  #mobile-content .actions a {
    font-size: 15px !important;
  }
  #mobile-content label {
    text-align: center;
    font-size: 12px;
  }
  .participants-list {
    display: flex;
    flex-direction: column;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px #ccc;
  }
  .participant-string {
    display: flex;
    flex-direction: column;
    margin: 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: -1px;
  }
  .participant-header, .participant-info-string {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
  }
  .index {
    font-weight: bold;
  }
  .name {
    font-weight: bold;
  }
  .points {
    font-weight: bold;
  }
  .tournaments, .efficiency {
    color: #2c41ac;
  }
  .games, .frames {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .games > label, .frames > label {
    margin: 5px;
    font-weight: bold;
    color: #063a0b;
  }
  .results {
    display: flex;
    justify-content: space-evenly;
  }
  .result {
    display: flex;
    flex-direction: column;
  }
  .result-label {
    color: #767676;
  }
  .result.win .result-value {
    color: #248d24;
  }
  .result.fail .result-value {
    color: #b50000;
  }
  .list-settings {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    align-items: center;
    margin-bottom: 5px;
  }
  .sort-setting {
    position: relative;
    display: flex;
    margin: 0 10px;
  }
  .sort-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: rgb(52 51 58);
    color: #f1f8ff;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    top: 20px;
    right: 0;
  }
  .sort-options label {
    margin: 5px 10px;
  }
  .search-setting {
    display: flex;
  }
  .search-button {
    margin: 5px;
  }
  .search-string {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
  }
  .search-string input {
    border: none;
    height: 100%;
    padding: 0;
    text-align: center;
    width: 200px;
  }
  .search-string input:focus {
    outline: none;
  }
  .search-clear-button {
    margin: 5px;
    align-self: center;
  }
</style>
