<template>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <div class='actions-container'>
    <div class='action-tabs' v-show="!loading">
      <div class='action-tabs-header' :class="{'collapsed': !actionPanel}">
        <button class='action' @click="actionPanelToggle('createTournament')" :class="{'active': (actionPanel == 'createTournament')}">
          Создать турнир
        </button>
        <div class="vl" v-show="selectedTournament"></div>
        <button class='action' @click="actionPanelToggle('deleteTournament')" v-show="selectedTournament" :class="{'active': (actionPanel == 'deleteTournament')}">
          Удалить турнир
        </button>
      </div>
      <div class='action-panels' v-show="actionPanel">
        <div v-show="actionPanel == 'createTournament'" class='action-panel'>
          <input type="text" placeholder="Название турнира" v-model="createTournamentName" v-on:keyup.enter="createTournament">
          <button @click="createTournament" :disabled="creatingTournament">
            <span v-show="!creatingTournament">Создать</span>
            <i class="fas fa-spinner fa-pulse" v-show="creatingTournament"></i>
          </button>
          <span v-if="createTournamentError != ''" class="error">{{createTournamentError}}</span>
        </div>
        <div v-show="actionPanel == 'deleteTournament' && selectedTournament" class='action-panel'>
          <input type="text" placeholder="Название турнира" v-model="deleteTournamentName" v-on:keyup.enter="deleteTournament">
          <button @click="deleteTournament" :disabled="deletingTournament" class="warning">
            <span v-show="!deletingTournament">Удалить</span>
            <i class="fas fa-spinner fa-pulse" v-show="deletingTournament"></i>
          </button>
          <span v-if="deleteTournamentError != ''" class="error">{{deleteTournamentError}}</span>
        </div>
      </div>
    </div>
  </div>
  <div id="content" v-show="!loading">
    <ul>
      <li v-for="tournament in tournaments" :key="tournament.id" :class="{'selected': (tournament == selectedTournament)}" @click="selectTournament(tournament)">
        <router-link :to="`/tournaments/${tournament.id}/info`">{{tournament.name}}</router-link>
        <div>
          <a @click="cloneTournament(tournament)" title="Клонировать">
            <i class="fa-solid fa-copy"></i>
          </a>
        </div>
      </li>
    </ul>
    <button v-show="tournaments.length" class="action more-button" @click="getNextTournaments" :disabled="loading" v-if="tournaments.length > 19">
      <span v-show="!loading">Ещё 20</span>
      <i class="fas fa-spinner fa-pulse" v-show="loading"></i>
    </button>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <span class="error" v-if="error!=''">{{error}}</span>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdminInfoPage',
  data: function() {
    return {
      username: '',
      role: '',
      tournaments: [],
      error: '',
      loading: false,
      actionPanel: '',
      creatingTournament: false,
      createTournamentName: '',
      createTournamentError: '',
      deletingTournament: false,
      deleteTournamentName: '',
      deleteTournamentError: '',
      selectedTournament: null
    }
  },
  methods: {
    getTournaments() {
      this.loading = true;
      this.error = '';
      this.tournaments = [];
      axios
      .get('/api/tournament/list/mine', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.tournaments = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить турниры'
        }
      })
      .finally(() => this.loading = false)
    },
    getNextTournaments() {
      if (this.tournaments.length == 0) return
      this.loading = true;
      this.error = '';
      let max_id = this.tournaments[this.tournaments.length - 1].id;
      axios
      .get('/api/tournament/list/mine', {headers: {'x-access-token': localStorage.token}, params: { maxid: max_id }})
      .then(response => {
        this.tournaments = this.tournaments.concat(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить турниры'
        }
      })
      .finally(() => this.loading = false)
    },
    createTournament() {
      if (this.createTournamentName == '') return
      this.creatingTournament = true;
      this.createTournamentError = '';
      axios
      .post('/api/tournament/create', {name: this.createTournamentName}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.getTournaments();
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.createTournamentError = error.response.data;
        } else {
          this.createTournamentError = 'Не получилось создать турнир'
        }
      })
      .finally(() => this.creatingTournament = false)
    },
    deleteTournament() {
      if (this.deleteTournamentName != this.selectedTournament.name) {
        this.deleteTournamentError = "Название турнира не совпадает";
        return
      }
      this.deletingTournament = true;
      this.deleteTournamentError = '';
      axios
      .post('/api/tournament/delete', {id: this.selectedTournament.id}, {headers: {'x-access-token': localStorage.token}})
      .then(() => {
        this.getTournaments();
        this.selectedTournament = null;
        this.actionPanel = null;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.deleteTournamentError = error.response.data;
        } else {
          this.deleteTournamentError = 'Не получилось удалить турнир'
        }
      })
      .finally(() => this.deletingTournament = false)
    },
    actionPanelToggle(panel) {
      if (this.actionPanel == panel) {
        this.actionPanel = null;
      } else {
        this.actionPanel = panel;
      }
    },
    selectTournament(tournament) {
      if (this.selectedTournament == tournament) {
        this.selectedTournament = null
      } else {
        this.selectedTournament = tournament;
      }
    },
    cloneTournament(tournament) {
      this.loading = true;
      this.error = '';
      axios
      .post('/api/tournament/clone', {id: tournament.id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.tournaments.push(response.data);
        this.$router.push({ path: `/tournaments/${response.data.id}/info` });
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось создать турнир'
        }
      })
      .finally(() => this.loading = false)
    },
  },
  mounted() {
    this.role = localStorage.role;
    this.username = localStorage.username;
    if (this.role != 'operator' && this.role != 'administrator') {
      this.$router.replace({ path: '/login' })
    }
    this.getTournaments();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
li.selected {
  background-color: rgb(93 113 172);
  color: #ffffff;
}
li a {
  text-decoration: none;
  color: #445142;
  margin: 3px;
}
li a:hover {
  color: #248d24
}
</style>
