<template>
  <h3>{{ tournament_name }}</h3>
  <div id="content">
    <div class='actions' v-if="isEditor && !loading" v-show="!loading">
      <a class='add-button' title="Добавить клуб" @click="create_video_dialog = true" v-show="!create_video_dialog"><i class="fa-solid fa-circle-plus"></i></a>
    </div>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <span class="error" v-show="error">{{error}}</span>
    <div class="video-list" v-if="videos.length" v-show="!loading && !create_video_dialog">
      <div v-for="video in videos" :key="video.youtube_link" class="video-container">
        <label>{{video.name}}</label>
        <iframe v-if="video.src" width="480" height="270" allow="fullscreen;"
          :src=video.src>
        </iframe>
      </div>
    </div>
    <span v-else v-show="!loading && !create_video_dialog">
      В турнире нет трасляций.
    </span>
    <div class="create-dialog" v-if="isEditor && games.length" v-show="!loading && create_video_dialog">
      <label>Встреча</label>
      <v-select v-model="new_video_game" :options="video_ready_games" label="name" :clearable="false" class="game-select"></v-select>
      <label>Cсылка на видео</label>
      <input :disabled="creating_video" v-model="new_video_link">
      <div v-if="!creating_video">
        <button @click="updateGameVideo()" v-show="new_video_game">{{new_video_link ? 'Добавить' : 'Удалить'}}</button>
        <button class="warning" @click="create_video_dialog = false">Отменить</button>
      </div>
      <div id="loader" v-else>
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: 'TournamentVideos',
  components: {
    'v-select': vSelect
  },
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      error: '',
      owner: null,
      team: null,
      teams: [],
      loading: false,
      create_video_dialog: false,
      creating_video: false,
      games: [],
      participants: [],
      tournament_name: null,
      new_video_link: null,
      new_video_game: null
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    participants_by_id() {
      return this.groupArrayBy(this.participants, 'id');
    },
    teams_by_id() {
      return this.groupArrayBy(this.teams, 'id');
    },
    videos() {
      let result = [];
      this.games.forEach(game => {
        if (game.youtube_link) {
          let video = {
            youtube_link: game.youtube_link
          }
          if (this.team) {
            video.name = this.teams_by_id[this.participants_by_id[game.player1][0].team_id][0].name + ' - ' + this.teams_by_id[this.participants_by_id[game.player2][0].team_id][0].name;
          } else {
            video.name = this.formatName(this.participants_by_id[game.player1][0].name) + ' - ' + this.formatName(this.participants_by_id[game.player2][0].name);
          }
          let url = new URL(game.youtube_link);
          if (url.host.includes('vk.com')) {
            video.type = 'vk'
          } else if (url.host.includes('youtu')) {
            video.type = 'youtube'
          } else if (url.host.includes('rutube')) {
            video.type = 'rutube'
          } else {
            return;
          }
          let urlParams = new URLSearchParams(url.search);
          if (video.type == 'youtube') {
            video.video_id = urlParams.get('v');
            if (!video.video_id) video.video_id = url.href.substring(url.href.lastIndexOf('/') + 1)
            video.src = `https://www.youtube.com/embed/${video.video_id}`;
          } else if (video.type == 'vk') {
            video.video_id = urlParams.get('id');
            video.owner_id = urlParams.get('oid');
            video.hash = urlParams.get('hash');
            video.src = game.youtube_link;
          } else if (video.type == 'rutube') {
            let match = url.href.match('/video/(.+)$');
            if (match) {
              video.video_id = match[1];
            }
            video.src = `https://rutube.ru/play/embed/${video.video_id}`
          }
          result.push(video);
        }
      });
      return result;
    },
    video_ready_games() {
      let result = [];
      this.games.forEach(game => {
        if (parseInt(game.player1,10) && parseInt(game.player2,10)) {
          let game_obj = {
            id: game.id
          }
          if (this.team) {
            game_obj.name = `${game.stage}: ${this.teams_by_id[this.participants_by_id[game.player1][0].team_id][0].name} - ${this.teams_by_id[this.participants_by_id[game.player2][0].team_id][0].name}`
          } else {
            game_obj.name = `${game.stage}: ${this.formatName(this.participants_by_id[game.player1][0].name)} - ${this.formatName(this.participants_by_id[game.player2][0].name)}`
          }
          result.push(game_obj);
        }
      })
      return result;
    }
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.error = '';
      this.games = [];
      this.participants = [];
      axios
      .get('/api/tournament/details', { params: { id: this.$route.params.id } })
      .then(response => {
        this.games = response.data.games;
        this.participants = response.data.participants;
        this.tournament_name = response.data.name;
        this.owner = response.data.owner;
        this.team = response.data.team;
        this.teams = response.data.teams;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.loading = false)
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    },
    updateGameVideo() {
      this.error = '';
      this.creating_video = true;
      if (!this.new_video_game) return;
      if (!this.new_video_link) this.new_video_link = null;
      axios
      .post('/api/tournament/add/video', { game_id: this.new_video_game.id, url: this.new_video_link }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          let game = null;
          for (let i=0; i<this.games.length; i++) {
            if (this.games[i].id == this.new_video_game.id) {
              game = this.games[i];
              break;
            }
          }
          game.youtube_link = this.new_video_link;
        } else {
          this.error = 'Не получилось добавить трансляцию'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(()=> {
        this.create_video_dialog = false;
        this.creating_video = false;
      })
    }
  },
  created() {
    this.getDetails();
  }
}
</script>

<style scoped>
.video-list {
  display: flex;
  flex-wrap: wrap;
}
.video-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  border-radius: 10px;
}
.video-container label {
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 15px;
  margin-bottom: 10px;
}
iframe {
  border: none;
}
.game-select {
  align-self: stretch;
}
</style>
