<template>
  <div class="tabs">
    <router-link :to="`/tournaments/${$route.params.id}/info`"
      :class="{ active: this.$route.path.endsWith('/info')}">
      <span v-if="!isMobile">Информация</span>
      <i v-else class="fa-solid fa-circle-info"></i>
    </router-link>
    <!-- <div class="vl"></div> -->
    <router-link :to="`/tournaments/${$route.params.id}/participants`"
      :class="{ active: this.$route.path.endsWith('/participants')}">
      <span v-if="!isMobile">Участники</span>
      <i v-else class="fa-solid fa-people-group"></i>
    </router-link>
    <!-- <div class="vl"></div> -->
    <router-link :to="`/tournaments/${$route.params.id}/chart`"
      :class="{ active: this.$route.path.endsWith('/chart')}">
      <span v-if="!isMobile">Сетка</span>
      <i v-else class="fa-solid fa-diagram-project"></i>
    </router-link>
    <!-- <div class="vl"></div> -->
    <router-link :to="`/tournaments/${$route.params.id}/statement`"
      :class="{ active: this.$route.path.endsWith('/statement')}">
      <span v-if="!isMobile">Ведомость</span>
      <i v-else class="fa-solid fa-rectangle-list"></i>
    </router-link>
    <!-- <div class="vl"></div> -->
    <router-link :to="`/tournaments/${$route.params.id}/videos`"
      :class="{ active: this.$route.path.endsWith('/videos')}">
      <span v-if="!isMobile">Трансляции</span>
      <i v-else class="fa-brands fa-youtube"></i>
    </router-link>
    <!-- <div class="vl" v-if="isEditor"></div> -->
    <router-link :to="`/tournaments/${$route.params.id}/plashka`"
      :class="{ active: this.$route.path.endsWith('/plashka')}" v-if="isEditor">
      <span v-if="!isMobile">Плашки</span>
      <i v-else class="fa-solid fa-photo-film"></i>
    </router-link>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SpecificTournamentsPage',
  data: function() {
    return {
      role: null,
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || this.role == 'operator'
    },
    isMobile() {
      return screen.width <= 1000;
    }
  },
  created() {
    this.role = localStorage.role;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
